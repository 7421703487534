import React, { useEffect, useState } from "react";
import "./Home.css";
import { Button } from "@material-ui/core";
import { useStateValue } from "../../StateProvider";
import {
  getFirestore,
  doc,
  collection,
  addDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import SpinnerSVG from "../../spinner.svg";

import Modal from "../../Modal";

function Home() {
  const [{ user }, dispatch] = useStateValue();
  const db = getFirestore();

  const [show, setShow] = useState(false);
  // const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [processing, setProcessing] = useState(true);

  useEffect(() => {
    if (user) {
      if (user?.emailVerified === true) {
        const name = user?.displayName.split(" ");
        const collectionRef = doc(collection(db, "users"), user?.uid);
        updateDoc(collectionRef, {
          firstName: name[0],
          lastName: name[1],
          uid: user?.uid,
        }).catch((error) => {
          setDoc(collectionRef, {
            firstName: name[0],
            lastName: name[1],
            uid: user?.uid,
          }).catch((error) => {
            // setModalType("Log back in");
            setModalTitle("Next Step");
            setModalMessage(
              "Seems like you have successfully verified your account. Please sign in again to continue."
            );
            console.log(error);
            setShow(true);
          });
        });
      } else {
        // setModalType("Verify your email");
        setModalTitle("Verify your email");
        setModalMessage(
          "Please verify your account through the email you provided when signing up. Once that process is complete, sign back into the website to get the full access."
        );
        setShow(true);
      }
    }
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "OGSA";
  }, []);

  const onLoad = () => {
    setProcessing(false);
  };

  return (
    <>
      {/* {processing && (
        <div className="loading_container_payment">
          <img src={SpinnerSVG} alt="Loading..." id="spinner"/>
        </div>
      )} */}
      <div className="home">
        {show && (
          <Modal
            setShowModal={setShow}
            modalTitle={modalTitle}
            modalMessage={modalMessage}
            className="modalInfo"
          />
        )}
        <div className="greeting_background">
          <img src="/Images/background.jpg" alt="" />
          <div className="greeting_section">
            <div className="greeting_wrapper">
              <h1 data-aos="fade-right" data-aos-delay="200" data-aos-easing="ease" data-aos-duration="650">Ontario Gujarati Students Association</h1>
              <p data-aos="fade-up" data-aos-easing="ease" data-aos-delay="600" data-aos-duration="700">For The Students, By The Students</p>
            </div>
            <div className="greeting_scrollDown">
              <div className="icon-scroll"></div>
            </div>
          </div>
        </div>
        {/* {!processing && ( */}
        <div className="about_section">
          <div className="about_wrapper">
          {/* <h1 data-aos="zoom-out" data-aos-easing="ease" data-aos-duration="500">About Us</h1> */}
            <h1>About Us</h1>
            {/* <div data-aos="fade-up" data-aos-easing="ease" data-aos-duration="400" data-aos-delay="300"> */}
            <div>
              <p>
                Ontario Gujarati Students Association (OGSA) was created by a few
                students looking for a way to connect with a wider group of Gujarati
                students across Ontario to spread the culture. OGSA was founded on
                the basis of creating social engagement opportunities for the
                students, by the students. As a province-wide group, our goal is to
                make everyone feel included in the celebrations, and help Gujarati
                students rekindle their cultural roots no matter how detached they
                may feel from them. Our vision is to host various events throughout
                the year to celebrate the festivities- whether that’s through a
                colorful Holi or a playful Navratri event!
              </p>
              <p>
                We appreciate all the support we’ve gotten from the community so
                early on in our journey and we hope that it increases with time as
                well. Our ongoing success would not be possible without our
                wonderful team and cheerful audience. Stay connected with us,
                whether you’re Gujarati or not, and we will strive to liven the
                cultural spirit within you. Come celebrate with us at the next
                event!
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(Home);
