import { Button } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { isCompositeComponent } from "react-dom/test-utils";
import { useNavigate, useParams } from "react-router-dom";

import "./EditEvent.css";

import {
  getFirestore,
  collection,
  onSnapshot,
  doc,
  getDocs,
  updateDoc,
  setDoc,
  serverTimestamp,
  deleteDoc,
} from "firebase/firestore";

import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { storage } from "../../firebase";
import { useStateValue } from "../../StateProvider";
import SpinnerSVG from "../../spinner.svg";
import RequiredInput from "../../Component/RequiredInput";
import { Alert } from "@mui/material";
import inputErrorMessage from "../../Component/InputErrorMessage";

function EditEvent() {
  const [{ user }, dispatch] = useStateValue();
  const [roles, setRole] = useState("");
  const eventId = useParams()?.eventId;
  const [event, setEvent] = useState({});

  const [totalTickets, setTotalTickets] = useState(0);
  const [totalRegularTickets, setTotalRegularTickets] = useState(0);
  const [totalEarlyBirdTickets, setTotalEarlyBirdTickets] = useState(0);

  const [earlyBirdTicket, setEarlyBirdTicket] = useState(0);
  const [regularTickets, setRegularTickets] = useState(0);

  const [earlyBirdPrice, setEarlyBirdPrice] = useState(0);
  const [regularPrice, setRegularPrice] = useState(0);

  const [regularWaitingList, setRegularWaitingList] = useState(0);
  const [earlyBirdWaitingList, setEarlyBirdWaitingList] = useState(0);

  const [eventName, setEventName] = useState("");
  const [eventAddress, setEventAddress] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [eventDescription, setEventDescription] = useState("");

  const [eventStatus, setEventStatus] = useState(""); // Current, previous, unpublished
  const [ticketsStatus, setTicketsStatus] = useState(true); // True or False
  const [ticketsStatusText, setTicketsStatusText] = useState(""); // Coming soon, Sold out, Available for all, Early Team Release

  const [eventDressCode, setEventDressCode] = useState("");

  const [eventImgUrl, setEventImgUrl] = useState(null);
  const [dbEventImageUrl, setDbEventImageUrl] = useState("");
  const [previewImageState, setPreviewImageState] = useState([]);
  const [eventImg, setEventImg] = useState();
  const [eventImgName, setEventImgName] = useState("");

  const [timelineIndex, setTimelineIndex] = useState(0);
  const [timelineActivity, setTimelineActivity] = useState({});

  const [sponsorIndex, setSponsorIndex] = useState(0);
  const [sponsor, setSponsor] = useState({});
  const [sponsorUpdate, setSponsorUpdate] = useState(false);
  const [numSponsorUpdate, setNumSponsorUpdate] = useState(0);

  const [processing, setProcessing] = useState(false);

  const [numberOfDbReadyInfo, setNumberOfDbReadyInfo] = useState(0);
  const [dbSubmit, setDbSubmit] = useState(false);

  const [tempCount, setTempCount] = useState(-1);

  const [addRegular, setAddRegular] = useState(0);

  const [addEarly, setAddEarly] = useState(0);

  // comingSoon and teamEarlyRelease constants
  const [comingSoon, setComingSoon] = useState(true);
  const [earlyTeamRelease, setEarlyTeamRelease] = useState(true);

  const [grossSales, setGrossSales] = useState(0);

  const db = getFirestore();

  const eventRef = doc(collection(db, "events"), eventId);

  const [updatedDB, setUpdatedDB] = useState(false);
  const [numberOfRequiredUpdates, setNumberOfRequiredUpdates] = useState(0);

  const history = useNavigate();

  useEffect(() => {
    if (user) {
      const userCollectionRef = doc(collection(db, "users"), user?.uid);
      const unsubscribe = onSnapshot(userCollectionRef, (doc) => {
        setRole(doc.data()?.role);
      });
      return () => unsubscribe();
    }
  }, [user]);

  const addingTimelineListener = (e) => {
    if (timelineActivity) {
      if (timelineIndex > 0) {
        let tempValue = Object.keys(timelineActivity).pop();
        // console.log("tempValue: ", tempValue);
        setTimelineActivity({
          ...timelineActivity,
          [parseInt(tempValue) + 1]: {},
        });
      } else {
        setTimelineActivity({
          ...timelineActivity,
          [parseInt(timelineIndex)]: {},
        });
      }
    }
  };

  const timelineChange = (e) => {
    var id = e?.target?.id.split(" ");

    setTimelineActivity({
      ...timelineActivity,
      [id[1]]: {
        ...timelineActivity[id[1]],
        [id[0]]: e?.target?.value,
      },
    });
  };

  const removeTimelineListener = (e) => {
    var id = e?.target?.id;
    // if (id !== "timeLine0") {
    setTimelineActivity((timelineActivity) => {
      const newTimelineActivity = { ...timelineActivity };
      delete newTimelineActivity[id.substring(8)];
      return newTimelineActivity;
    });
    // }
  };

  const addingSponsorListener = (e) => {
    if (sponsor) {
      if (sponsorIndex > 0) {
        let tempValue = Object.keys(sponsor).pop();
        // console.log("tempValue: ", tempValue);
        setSponsor({
          ...sponsor,
          [parseInt(tempValue) + 1]: {},
        });
      } else {
        setSponsor({
          ...sponsor,
          [parseInt(sponsorIndex)]: {},
        });
      }
    }
  };

  const sponsorChange = async (e) => {
    var id = e?.target?.id.split(" ");
    await setSponsor({
      ...sponsor,
      [id[1]]: {
        ...sponsor[id[1]],
        [id[0]]: e?.target?.value,
      },
    });
  };

  const sponsorsImg = async (e) => {
    var id = e?.target?.id.split(" ");
    if (e?.target?.value !== "") {
      await setSponsor({
        ...sponsor,
        [id[1]]: {
          ...sponsor[id[1]],
          [id[0]]: e?.target?.files[0],
          preview: URL.createObjectURL(e?.target?.files[0]),
        },
      });
      setSponsorUpdate(true);
      setNumSponsorUpdate((numSponsorUpdate) => numSponsorUpdate + 1);
    } else {
      if (numSponsorUpdate > 0) {
        setNumSponsorUpdate((numSponsorUpdate) => numSponsorUpdate - 1);
        await setSponsor({
          ...sponsor,
          [id[1]]: {
            ...sponsor[id[1]],
            [id[0]]: event?.sponsor[id[1]]?.logo,
          },
        });
      }
    }
  };

  const removeSponsorListener = (e) => {
    var id = e?.target?.id;
    // if (id !== "sponsor0") {
    setSponsor((sponsor) => {
      const newSponsor = { ...sponsor };
      delete newSponsor[id.substring(7)];
      return newSponsor;
    });
    // }
  };

  const imageChange = (e) => {
    setEventImg(e.target.files[0]);
    setPreviewImageState(URL.createObjectURL(e.target.files[0]));
  };

  useEffect(() => {
    if (eventId) {
      const unsubscribe = onSnapshot(eventRef, (event) => {
        setEvent(event.data());
      });
      return () => unsubscribe();
    }
  }, [eventId, db]);

  useEffect(() => {
    if (event) {
      // Setting the tickets status text
      if (
        event?.ticketsStatus === true &&
        event?.comingSoon === true &&
        event?.earlyTeamRelease === false
      ) {
        setTicketsStatusText("Coming Soon");
      } else if (
        event?.ticketsStatus === true &&
        event?.comingSoon === true &&
        event?.earlyTeamRelease === true
      ) {
        setTicketsStatusText("Early Team Release");
      } else if (event?.ticketsStatus === false) {
        setTicketsStatusText("Sold Out");
      } else {
        setTicketsStatusText("Available for all");
      }
      // console.log(event);
      setEventName(event?.eventName);
      setEventDescription(event?.eventDescription);
      setEventLocation(event?.eventLocation);
      setEventDate(event?.eventDate);
      setEventTime(event?.eventTime);
      setEventAddress(event?.eventAddress);
      setEventDressCode(event?.eventDressCode);
      setEventImgName(event?.eventImgName);
      // Coming Soon and Early Team Release Flags
      setComingSoon(event?.comingSoon);
      setEarlyTeamRelease(event?.earlyTeamRelease);
      // setEventImgUrl(event?.eventImgUrl);
      setDbEventImageUrl(event?.eventImgUrl);
      setTotalTickets(event?.totalTicket);

      setEarlyBirdPrice(event?.EarlyBirdTicketPrice);
      setEarlyBirdTicket(event?.EarlyBirdTicketsAvailable);

      setRegularPrice(event?.RegularTicketPrice);
      setRegularTickets(event?.RegularTicketsAvailable);

      setRegularWaitingList(event?.RegularTicketWaitingList);
      setEarlyBirdWaitingList(event?.EarlyBirdTicketWaitingList);

      // setEventImgUrl(event?.eventImgUrl);
      setEventStatus(event?.eventStatus);
      setTicketsStatus(event?.ticketsStatus);

      setTimelineActivity(event?.timelineActivity);
      setSponsor(event?.sponsor);

      setTotalRegularTickets(event?.totalRegular);
      setTotalEarlyBirdTickets(event?.totalEarlyBird);
      setGrossSales(event?.grossSales);
    }
  }, [event]);

  useEffect(() => {
    // console.log("Sponsor: ", sponsor);
    if (timelineActivity) {
      setTimelineIndex(Object.keys(timelineActivity)?.length);
    }
    if (sponsor) {
      setSponsorIndex(Object.keys(sponsor)?.length);
    }
  }, [timelineActivity, sponsor]);

  // console.log("eventImgUrl: ", eventImgUrl);
  //   console.log("sponsor: ", sponsor);

  const submitEvent = async (e) => {
    e.preventDefault();
    setProcessing(true);
    setTempCount(0);
    // Check to see if the file type is there...
    if (eventImg) {
      setNumberOfRequiredUpdates(
        (numberOfRequiredUpdates) => numberOfRequiredUpdates + 1
      );
      var eventImageRef = ref(
        storage,
        `/Event/${eventName}/eventImg/${eventImg?.name}`
      );

      const uploadTask = uploadBytesResumable(eventImageRef, eventImg);

      await uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.log("Upload error:", error);
        },
        async () => {
          await getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            // console.log("URL>>>>>>>>>>>>>", url)
            await setEventImgUrl(url);
          });
        }
      );
    }

    if (sponsorUpdate) {
      setNumberOfRequiredUpdates(
        (numberOfRequiredUpdates) => numberOfRequiredUpdates + 1
      );
      Object.keys(sponsor).forEach(async (key, index) => {
        var sponsorImgRef = ref(
          storage,
          `/Event/${eventName}/Sponsors/${sponsor[key]?.name}`
        );

        if (typeof sponsor[key]?.logo === "object") {
          // console.log("Changed anything....");
          let uploadSponsor = uploadBytesResumable(
            sponsorImgRef,
            sponsor[key]?.logo
          );

          uploadSponsor.on(
            "state_changed",
            (snapshot) => {},
            (error) => {
              console.log("Upload error:", error);
            },
            () => {
              getDownloadURL(uploadSponsor.snapshot.ref).then(async (url) => {
                await setSponsor((prevState) => ({
                  ...prevState,
                  [key]: {
                    ...prevState[key],
                    logoName: sponsor[key]?.logo?.name,
                    logo: url,
                  },
                }));
                setTempCount((tempCount) => tempCount + 1);
              });
            }
          );
        }
      });
    }

    if (!eventImg && !sponsorUpdate) {
      // console.warn("::::::UPDATE OTHER INFO::::::")
      setNumberOfRequiredUpdates(
        (numberOfRequiredUpdates) => numberOfRequiredUpdates + 1
      );
      setNumberOfDbReadyInfo((numberOfDbReadyInfo) => numberOfDbReadyInfo + 1);
    }
  };

  useEffect(() => {
    if (sponsorUpdate && numSponsorUpdate === tempCount) {
      // console.warn("::::::UPDATED Sponsor::::::")
      setNumberOfDbReadyInfo((numberOfDbReadyInfo) => numberOfDbReadyInfo + 1);
    }
  }, [sponsor, tempCount]);

  useEffect(() => {
    if (eventImgUrl) {
      // console.warn("::::::UPDATED EVENT IMAGE::::::")
      // console.log("EVENT IMAGE URL: ", eventImgUrl);
      setNumberOfDbReadyInfo((numberOfDbReadyInfo) => numberOfDbReadyInfo + 1);
    }
  }, [eventImgUrl]);

  useEffect(() => {
    if (
      numberOfRequiredUpdates === numberOfDbReadyInfo &&
      numberOfDbReadyInfo + numberOfDbReadyInfo > 0
    ) {
      setDbSubmit(true);
    }
  }, [numberOfRequiredUpdates, numberOfDbReadyInfo]);

  useEffect(() => {
    async function updateDatabase() {
      // If event Image and imageUrl not null and updatedDb is false
      // If sponsor update and dbSubmit is true
      // If db submit
      if (dbSubmit && !updatedDB) {
        // console.warn(":::::::::::::UPDATING EVENT HERE::::::::::::");
        // console.log(eventImg)
        var regular_temp_tickets = regularTickets + addRegular;
        var early_Bird_temp_Tickets = earlyBirdTicket + addEarly;

        setUpdatedDB(true);

        // let earlyBirdDic = {
        //   price: parseInt(earlyBirdPrice),
        //   ticketsAvailable: parseInt(early_Bird_temp_Tickets),
        //   waitingList: parseInt(earlyBirdWaitingList),
        // };

        // let regularDic = {
        //   price: parseInt(regularPrice),
        //   ticketsAvailable: parseInt(regular_temp_tickets),
        //   waitingList: parseInt(regularWaitingList),
        // };

        try {
          await updateDoc(eventRef, {
            eventImgName: eventImg?.name,
          });
        } catch (error) {
          await updateDoc(eventRef, {
            eventImgName: eventImgName,
          });
        }

        if (eventImgUrl !== null) {
          await updateDoc(eventRef, {
            eventImgUrl: eventImgUrl,
          });
        } else {
          await updateDoc(eventRef, {
            eventImgUrl: event?.eventImgUrl,
          });
        }

        await updateDoc(eventRef, {
          // EarlyBirdTicket: earlyBirdDic,
          // RegularTickets: regularDic,

          EarlyBirdTicketPrice: parseInt(earlyBirdPrice),
          EarlyBirdTicketsAvailable: parseInt(early_Bird_temp_Tickets),

          RegularTicketPrice: parseInt(regularPrice),
          RegularTicketsAvailable: parseInt(regular_temp_tickets),

          eventAddress: eventAddress,
          comingSoon: comingSoon,
          earlyTeamRelease: earlyTeamRelease,
          eventDate: eventDate,
          eventDescription: eventDescription,
          eventDressCode: eventDressCode,
          eventLocation: eventLocation,
          eventName: eventName,
          eventStatus: eventStatus,
          eventTime: eventTime,
          timelineActivity: timelineActivity,
          sponsor: sponsor,
          ticketsStatus: ticketsStatus,
          totalTicket: totalTickets + parseInt(addRegular) + parseInt(addEarly),
          totalEarlyBird: event?.totalEarlyBird + parseInt(addEarly),
          totalRegular: event?.totalRegular + parseInt(addRegular),
          grossSales: parseInt(grossSales),
        });

        setProcessing(false);

        history("/dashboard");
      }
    }

    updateDatabase();
  }, [dbSubmit, sponsor, eventImgUrl, eventRef]);

  const deleteEvent = async () => {
    setProcessing(true);
    await deleteDoc(eventRef);
    history("/dashboard");
  };

  const handleTicketStatusUpdate = (e) => {
    e.preventDefault();
    let selectedOption = e.target.value;
    if (selectedOption === "Available for all") {
      setTicketsStatusText("Available for all");
      setTicketsStatus(true);
      setComingSoon(false);
      setEarlyTeamRelease(false); // Setting this to false for now. Will need to look back on this if we want different price for the team even when released to all.
    } else if (selectedOption === "Coming Soon") {
      setTicketsStatusText("Coming Soon");
      setTicketsStatus(true);
      setComingSoon(true);
      setEarlyTeamRelease(false);
    } else if (selectedOption === "Early Team Release") {
      setTicketsStatusText("Early Team Release");
      setTicketsStatus(true);
      setComingSoon(true);
      setEarlyTeamRelease(true);
    } else {
      setTicketsStatusText("Sold Out");
      setTicketsStatus(false);
      setComingSoon(false);
      setEarlyTeamRelease(false);
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    document.title = "Edit Event - Dashboard";
  }, []);

  return (
    <>
      {processing && (
        <div className="loading_container_payment">
          <img src={SpinnerSVG} alt="Loading..." id="spinner" />
        </div>
      )}
      <div className="EditEvent">
        <h2 id="eventTitle">Update Event</h2>

        <Button onClick={(e) => history(-1)}>
          <span id="backIcon">
            <img src="/Images/Dashboard/backIcon.png" alt="" />
            <h3>Back</h3>
          </span>
        </Button>

        <hr />

        <form className="formsContainer">
          {roles === "Admin" && (
            <div
              className="eventContainer"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <h3>Status</h3>
              <div className="informationContainer">
                <div className="eventWrapper">
                  <span>
                    <h4>Event Status:</h4>
                    <select
                      value={eventStatus}
                      onChange={(e) => setEventStatus(e.target?.value)}
                      name="eventStatus"
                    >
                      <option value="Current">Current</option>
                      <option value="Previous">Previous</option>
                      <option value="Unpublished">Unpublished</option>
                    </select>
                  </span>
                  <span>
                    <h4>Ticket Status:</h4>
                    <select
                      value={ticketsStatusText}
                      onChange={handleTicketStatusUpdate}
                      name="ticketStatus"
                    >
                      <option value="Coming Soon">Coming Soon</option>
                      <option value="Early Team Release">
                        Early Team Release
                      </option>
                      <option value="Available for all">
                        Available for all
                      </option>
                      <option value="Sold Out">Sold Out</option>
                    </select>
                  </span>
                </div>
              </div>
            </div>
          )}

          <div className="eventContainer" data-aos="fade-left">
            <h3>Event Image</h3>
            <div className="informationContainer">
              {eventImg ? (
                <>
                  <img
                    src={previewImageState}
                    alt="Event Image"
                    id="db_event_image"
                  />
                  <p id="uploaded_text">Uploaded: {eventImg?.name}</p>
                </>
              ) : (
                eventImgName && (
                  <>
                    <img
                      src={dbEventImageUrl}
                      alt="Event Image"
                      id="db_event_image"
                    />
                    <p id="uploaded_text">Uploaded: {eventImgName}</p>
                  </>
                )
              )}
              <div className="eventWrapper">
                <div id="formInfoMedia">
                  <label className="custom-file-upload">
                    <input
                      type="file"
                      name="media"
                      accept="image/*"
                      onChange={imageChange}
                      required="required"
                    />
                    <p className="text-grey">Click to upload</p>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="eventContainer" data-aos="fade-right">
            <h3>Event Information</h3>
            <div className="informationContainer">
              <div className="eventWrapper">
                <span>
                  <h4>Name of the Event:</h4>
                  <RequiredInput
                    inputType="input"
                    type="text"
                    currValue={eventName}
                    placeholder="Enter event name..."
                    onChangeVariable={setEventName}
                    pattern="(^[A-Za-z0-9.? ,_-]*)$"
                    errorMessage={inputErrorMessage["eventName"]}
                    required
                  />
                </span>
                <span>
                  <h4>Dress Code:</h4>
                  {/* <input
                      type="text"
                      value={eventDressCode}
                      onChange={(e) => setEventDressCode(e.target?.value)}
                      /> */}
                  <RequiredInput
                    inputType="input"
                    type="text"
                    currValue={eventDressCode}
                    placeholder="Enter dress code for the event..."
                    onChangeVariable={setEventDressCode}
                    pattern="(^[A-Za-z0-9- ,_/!.]*)$"
                    errorMessage={inputErrorMessage["dressCode"]}
                    required
                  />
                </span>
              </div>

              <div className="eventWrapper">
                <span>
                  <h4>Location Name:</h4>
                  <RequiredInput
                    inputType="input"
                    type="text"
                    currValue={eventLocation}
                    placeholder="Please enter name of the location..."
                    onChangeVariable={setEventLocation}
                    pattern="(^[a-z- A-Z]*)$"
                    errorMessage={inputErrorMessage["locationName"]}
                    required
                  />
                </span>
                <span>
                  <h4>Event Address:</h4>
                  {/* <input
                      type="text"
                      value={eventAddress}
                      placeholder="Please enter address of the event..."
                      onChange={(e) => setEventAddress(e.target?.value)}
                      /> */}
                  <RequiredInput
                    inputType="input"
                    type="text"
                    currValue={eventAddress}
                    placeholder="Please enter address of the event..."
                    onChangeVariable={setEventAddress}
                    pattern="(^[A-Za-z0-9 ,-]*)$"
                    errorMessage={inputErrorMessage["address"]}
                    required
                  />
                </span>
              </div>

              <div className="eventWrapper">
                <span>
                  <h4>Date:</h4>
                  {/* <input
                      type="date"
                      value={eventDate}
                      onChange={(e) => setEventDate(e.target.value)}
                    /> */}
                  <RequiredInput
                    inputType="date"
                    type="date"
                    currValue={eventDate}
                    placeholder=""
                    onChangeVariable={setEventDate}
                    pattern=""
                    errorMessage={inputErrorMessage["date"]}
                    required
                  />
                </span>
                <span>
                  <h4>Time:</h4>
                  {/* <input
                      type="text"
                      value={eventTime}
                      placeholder="e.g. 10AM - 12PM"
                      onChange={(e) => setEventTime(e.target.value)}
                      /> */}
                  <RequiredInput
                    inputType="input"
                    type="text"
                    currValue={eventTime}
                    placeholder="e.g. 8PM - 12AM"
                    onChangeVariable={setEventTime}
                    pattern="(^[0-9:]*(AM|PM) - [0-9:]*(AM|PM)){1}$"
                    errorMessage={inputErrorMessage["time"]}
                    required
                  />
                </span>
              </div>

              <div className="eventWrapper">
                <span>
                  <h4>Event Description:</h4>
                  {/* <textarea
                      rows="6"
                      cols="50"
                      placeholder="Please enter the event description..."
                      spellCheck="true"
                      maxLength="900"
                      value={eventDescription}
                      onChange={(e) => setEventDescription(e.target?.value)}
                    /> */}
                  <RequiredInput
                    inputType="textarea"
                    type="text"
                    currValue={eventDescription}
                    placeholder="Please enter the event description..."
                    onChangeVariable={setEventDescription}
                    pattern=""
                    errorMessage="Event description is a required field."
                    required
                  />
                </span>
              </div>
            </div>
          </div>

          {roles === "Admin" && (
            <div className="eventContainer" data-aos="fade-down">
              <h3>Tickets Information</h3>
              <div className="informationContainer">
                <div
                  className="eventWrapper"
                  id="ticketInformationAndSponsorContainer"
                >
                  <div className="eventWrapper_left">
                    <span>
                      <h4>Regular Ticket:</h4>
                      <input
                        type="number"
                        value={totalRegularTickets}
                        min="0"
                        readOnly
                        placeholder="Number of regular tickets to sell..."
                        pattern="^[1-9][0-9]*$"
                      />
                    </span>
                    <span>
                      <h4>Regular Ticket Price ($):</h4>
                      <input
                        type="number"
                        value={regularPrice}
                        min="0"
                        placeholder="Number of regular tickets to sell..."
                        onChange={(e) => setRegularPrice(e.target?.value)}
                        pattern="^[1-9][0-9]*$"
                        required="required"
                      />
                    </span>
                  </div>
                  <div className="eventWrapper_right">
                    <span>
                      <h4>Early Bird Ticket:</h4>
                      <input
                        type="number"
                        value={totalEarlyBirdTickets}
                        placeholder="Number of early bird tickets to sell..."
                        min="0"
                        readOnly
                      />
                    </span>
                    <span>
                      <h4>Early Bird Ticket Price ($):</h4>
                      <input
                        type="number"
                        value={earlyBirdPrice}
                        min="0"
                        placeholder="Number of regular tickets to sell..."
                        onChange={(e) => setEarlyBirdPrice(e.target?.value)}
                      />
                    </span>
                  </div>
                </div>
                <div className="eventWrapper">
                  <span>
                    <h4>Add Regular Ticket:</h4>
                    {/* <input
                      type="number"
                      value={addRegular}
                      min="0"
                      placeholder="Number of regular tickets to add..."
                      onChange={(e) => setAddRegular(parseInt(e.target?.value))}
                      pattern="^[1-9][0-9]*$"
                      required="required"
                    /> */}
                    <RequiredInput
                      inputType="number"
                      type="number"
                      currValue={addRegular}
                      onChangeVariable={setAddRegular}
                      placeholder="e.g. 50"
                      pattern="^[1-9][0-9]*$"
                      errorMessage={inputErrorMessage["number"]}
                      required
                    />
                  </span>
                  <span>
                    <h4>Add Early Bird Ticket:</h4>
                    {/* <input
                      type="number"
                      value={addEarly}
                      min="0"
                      placeholder="Number of regular tickets to add..."
                      onChange={(e) => setAddEarly(parseInt(e.target?.value))}
                      pattern="^[1-9][0-9]*$"
                      required="required"
                    /> */}
                    <RequiredInput
                      inputType="number"
                      type="number"
                      currValue={addEarly}
                      onChangeVariable={setAddEarly}
                      placeholder="e.g. 20"
                      pattern="^[1-9][0-9]*$"
                      errorMessage={inputErrorMessage["number"]}
                      required
                    />
                  </span>
                </div>
              </div>
            </div>
          )}

          <div className="eventContainer" data-aos="fade-left">
            <div id="eventContainer_header" key="timeline_container_key">
              <h3>Timeline</h3>
              <Button onClick={addingTimelineListener}>
                <span id="additional_buttons">
                  <img src="/Images/Dashboard/add.png" id="addButton" alt="" />
                  <p>Add Activity</p>
                </span>
              </Button>
            </div>

            {timelineIndex > 0 &&
              Object.keys(timelineActivity).map((key, index) => (
                <li
                  className="timeline_eventWrapper"
                  key={key}
                  id={`timeLine${key}`}
                  data-aos="fade-left"
                >
                  <img
                    src="/Images/Dashboard/minus.png"
                    id={`timeLine${key}`}
                    className="deleteIcon"
                    onClick={removeTimelineListener}
                    alt=""
                  />
                  <span>
                    <h4>Activity:</h4>
                    <input
                      type="text"
                      value={timelineActivity[key]?.activity}
                      id={`activity ${key}`}
                      placeholder="eg. Doors open"
                      onChange={timelineChange}
                      pattern="(^[A-Za-z .()!]*)$"
                    />
                    <Alert severity="info" className="errorMessage">
                      {inputErrorMessage["activityName"]}
                    </Alert>
                  </span>
                  <span>
                    <h4>Time:</h4>
                    <input
                      type="text"
                      id={`time ${key}`}
                      value={timelineActivity[key]?.time}
                      placeholder="e.g. 10AM"
                      onChange={timelineChange}
                      pattern="(^[0-9:]*(AM|PM)){1}$"
                    />
                    <Alert severity="info" className="errorMessage">
                      {inputErrorMessage["singleTime"]}
                    </Alert>
                  </span>
                </li>
              ))}
          </div>

          <div className="eventContainer" data-aos="fade-right">
            <div id="eventContainer_header">
              <h3>Sponsors</h3>
              <Button onClick={addingSponsorListener}>
                <span id="additional_buttons">
                  <img src="/Images/Dashboard/add.png" id="addButton" alt="" />
                  <p>Add Sponsor</p>
                </span>
              </Button>
            </div>

            {sponsorIndex > 0 &&
              Object.keys(sponsor).map((key, index) => (
                <div key={key + 1} data-aos="fade-right">
                  <div
                    className="sponsor_eventWrapper"
                    key={key}
                    id={`sponsor${key}`}
                  >
                    <div
                      className="sponsor_info_fields"
                      key={`infoField ${key}`}
                      id="ticketInformationAndSponsorContainer"
                    >
                      <div>
                        <span>
                          <h4>Name:</h4>
                          <input
                            type="text"
                            value={sponsor[key]?.name}
                            id={`name ${key}`}
                            placeholder="Please enter name of the sponsor..."
                            onChange={sponsorChange}
                            pattern="(^[A-Za-z .]*)$"
                          />
                          <Alert severity="info" className="errorMessage">
                            {inputErrorMessage["sponsorName"]}
                          </Alert>
                        </span>
                        <span>
                          <h4>Field:</h4>
                          <input
                            type="text"
                            value={sponsor[key]?.field}
                            id={`field ${key}`}
                            placeholder="eg. Immigration advisor"
                            onChange={sponsorChange}
                            pattern="(^[A-Za-z .]*)$"
                          />
                          <Alert severity="info" className="errorMessage">
                            {inputErrorMessage["sponsorField"]}
                          </Alert>
                        </span>
                      </div>
                      <div>
                        <span>
                          <h4>Phone Number:</h4>
                          <input
                            type="text"
                            value={sponsor[key]?.phone}
                            id={`phone ${key}`}
                            placeholder="eg. ###-###-###"
                            onChange={sponsorChange}
                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          />
                          <Alert severity="info" className="errorMessage">
                            {inputErrorMessage["phoneNumber"]}
                          </Alert>
                        </span>
                        <span>
                          <h4>Other Info:</h4>
                          <input
                            type="text"
                            value={sponsor[key]?.otherInfo}
                            id={`otherInfo ${key}`}
                            placeholder="eg. Email, Website, etc..."
                            onChange={sponsorChange}
                            pattern="(^[A-Za-z0-9 ,-:/.@]*)$"
                          />
                          <Alert severity="info" className="errorMessage">
                            {inputErrorMessage["otherFields"]}
                          </Alert>
                        </span>
                      </div>
                      <img
                        src="/Images/Dashboard/minus.png"
                        className="deleteIcon"
                        id={`sponsor${key}`}
                        onClick={removeSponsorListener}
                        alt=""
                      />
                    </div>
                    <div id="formInfoMedia" key={`mediaContainer ${key}`}>
                      <label className="custom-file-upload">
                        <input
                          type="file"
                          name="media"
                          accept="image/*"
                          id={`logo ${key}`}
                          onChange={sponsorsImg}
                        />
                        <p className="text-grey">Click to upload</p>
                      </label>
                    </div>
                    {sponsor[key]?.logo?.name
                      ? sponsor[key]?.logo?.name !== "" && (
                          <>
                            <img
                              src={sponsor[key]?.preview}
                              alt="Event Image"
                              id="db_sponsor_logo"
                            />
                            <p id="uploaded_text">
                              Uploaded: {sponsor[key]?.logo?.name}
                            </p>
                          </>
                        )
                      : sponsor[key]?.logoName && (
                          <>
                            <img
                              src={sponsor[key]?.logo}
                              alt="Event Image"
                              id="db_sponsor_logo"
                            />
                            <p id="uploaded_text">
                              Uploaded: {sponsor[key]?.logoName}
                            </p>
                          </>
                        )}
                  </div>
                </div>
              ))}
          </div>

          <div className="overviewContainerButton">
            {roles === "Admin" && (
              <Button
                className="submitButton"
                onClick={deleteEvent}
                disabled={processing}
              >
                <span>Delete</span>
              </Button>
            )}

            <Button
              className="submitButton"
              onClick={submitEvent}
              disabled={processing}
              id="submitBtn"
            >
              <span>{processing ? <p>Processing...</p> : "Submit"}</span>
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default EditEvent;
