import React from "react";
import "./ConfirmationModel.css";
import { useState } from "react";
import { Button } from "@material-ui/core";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../StateProvider";

function ConfirmationModal(props) {
  const handleSubmit = (e) => {
    e.preventDefault();
    props.setConfirmed(true);
    props.setConfirmationModel(false);
  };
  return (
    <div className="modal_background">
      <div
        className="modal_container"
        data-aos="zoom-in"
        data-aos-duration="500"
      >
        {/* <div className="modal_header">
                        <img src="/Images/Login/close.png" onClick={() => closeModal(false)} />
                </div> */}
        <div className="modal_title">
          <h1>Confirmation</h1>
        </div>
        <div className="confirmationModal_body">
          <p>Please make sure you have filled in all the necessary details. </p>
          <p>Once submitted, the form will be sent for review.</p>
        </div>

        <div className="confirmationModal_footer">
          <Button onClick={() => props.setConfirmationModel(false)}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationModal;
