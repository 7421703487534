import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./AllEvents.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Mousewheel,
} from "swiper";
import {
  getFirestore,
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/scrollbar";
import "swiper/css/lazy";
import SpinnerSVG from "../../spinner.svg";

import { useNavigate } from "react-router-dom";

import "react-lazy-load-image-component/src/effects/blur.css";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay, Mousewheel]);

function AllEvents() {
  const db = getFirestore();
  const history = useNavigate();
  const cEvent = query(
    collection(db, "events"),
    where("eventStatus", "==", "Current")
  );
  const pEvent = query(
    collection(db, "events"),
    where("eventStatus", "==", "Previous")
  );
  const [currentEvents, setCurrentEvents] = useState([]);
  const [prevEvents, setPrevEvents] = useState([]);
  const [processing, setProcessing] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(0);

  useEffect(() => {
    const unsubscribe = onSnapshot(cEvent, (snapshot) => {
      setCurrentEvents(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    });

    const unsubscribe1 = onSnapshot(pEvent, (snapshot) => {
      setPrevEvents(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    });

    return () => {
      unsubscribe();
      unsubscribe1();
    };
  }, []);

  // useEffect(() => {
  //     console.log("Current Events:", currentEvents);
  //     console.log("Previous Events:", prevEvents);
  // }, [currentEvents, prevEvents]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "All Events - OGSA";
  }, []);

  const viewEvent = (e, event) => {
    e.preventDefault();
    history("/events/" + event?.id);
  };

  // useEffect(()=>{
  //   if (Object.keys(prevEvents).length > 0){
  //     setProcessing(false);
  //   }
  // },[currentEvents, prevEvents])

  const imageload = (e) => {
    setImageLoaded((imageLoaded) => imageLoaded + 1);
  };

  useEffect(() => {
    if (imageLoaded >= 3) {
      setProcessing(false);
    }
  }, [imageLoaded]);

  return (
    <>
      {processing && (
        <div className="loading_container_payment">
          {/* <img src="/Images/Event/spinner.svg" alt = "Loading..." /> */}
          <img src={SpinnerSVG} alt="Loading..." id="spinner" />
        </div>
      )}
      <div className="all_events">
        <div className="upcoming_events">
          <h1>Upcoming Events</h1>
          <div className="event_card">
            {currentEvents.length === 0 ? (
              <div className="event_card_image">
                <img
                  src="/Images/AllEvents/event_background1.jpg"
                  alt=""
                  onLoad={imageload}
                />
                <div className="event_card_info">
                  <div className="event_card_nothing">
                    <h1>No Upcoming Events</h1>
                  </div>
                </div>
              </div>
            ) : (
              // <div className="event_card_image">
              //   <img src={currentEvents[0]?.data.eventImgUrl} alt="" />
              //   <div className="event_card_info">
              //     <h1>{currentEvents[0]?.data.eventName}</h1>
              //     <p>Date: {currentEvents[0]?.data.eventDate}</p>
              //     <p>Time: {currentEvents[0]?.data.eventTime}</p>
              //     <p>Location: {currentEvents[0]?.data.eventLocation}</p>
              //     <Button
              //       onClick={(e) => {
              //         viewEvent(e, currentEvents[0]);
              //       }}
              //     >
              //       Buy Now
              //     </Button>
              //   </div>
              // </div>
              <div className="event_card_image">
                <Swiper
                  // install Swiper modules
                  modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                  slidesPerView={1}
                  spaceBetween={20}
                  autoplay={{
                    disableOnInteraction: true,
                  }}
                  lazy={true}
                  // autoplayDisableOnInteraction = {true}
                  grabCursor={true}
                  // mousewheel={true}
                  direction={"horizontal"}
                  // navigation = {true}
                  pagination={{ clickable: true }}
                  // scrollbar={{ draggable: true }}
                  // onSwiper={(swiper) => console.log(swiper)}
                  // onSlideChange={() => console.log('slide change')}
                >
                  {currentEvents?.map((event) => (
                    <SwiperSlide key={event?.id} className="slide">
                      <div className="past_event_card_image">
                        {/* <img src="Images/AllEvents/event_background1.jpg" alt=""/> */}
                        <img
                          src={event?.data.eventImgUrl}
                          alt={event?.data.eventName}
                          onLoad={imageload}
                        />
                        <div className="past_event_card_info">
                          <h1>{event?.data.eventName}</h1>
                          <div className="other_info">
                            <h1>{event?.data.eventName}</h1>
                            <p>Date: {event?.data.eventDate}</p>
                            <p>Time: {event?.data.eventTime}</p>
                            <p>Location: {event?.data.eventLocation}</p>
                            <Button
                              onClick={(e) => {
                                viewEvent(e, event);
                              }}
                            >
                              Buy Now
                            </Button>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )}
          </div>
        </div>

        <div className="past_events">
          <h1>Past Events</h1>
          {/* If there is no event... */}
          {prevEvents.length === 0 ? (
            <div className="past_event_card_container">
              {/* <img src="Images/AllEvents/event_background1.jpg" alt=""/> */}
              <div className="past_event_card_image">
                <img src="Images/AllEvents/event_background1.jpg" alt="" />
                <div className="past_event_card_infos">
                  <h1>Loading...</h1>
                </div>
              </div>
            </div>
          ) : (
            // If there is event...
            <div className="past_event_card_container">
              <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                breakpoints={{
                  1680: {
                    slidesPerView:
                      prevEvents.length > 3 ? 4 : prevEvents.length,
                    spaceBetween: 20,
                  },
                  1250: {
                    slidesPerView:
                      prevEvents.length >= 3 ? 3 : prevEvents.length,
                    spaceBetween: 20,
                  },
                  1020: {
                    slidesPerView:
                      prevEvents.length >= 3 ? 3 : prevEvents.length,
                    spaceBetween: 20,
                  },
                  600: {
                    slidesPerView:
                      prevEvents.length >= 3 ? 2 : prevEvents.length,
                    spaceBetween: 20,
                  },
                  200: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                }}
                autoplay={{
                  disableOnInteraction: true,
                }}
                lazy={true}
                // autoplayDisableOnInteraction = {true}
                grabCursor={true}
                // mousewheel={true}
                direction={"horizontal"}
                // navigation = {true}
                pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                // onSwiper={(swiper) => console.log(swiper)}
                // onSlideChange={() => console.log('slide change')}
              >
                {prevEvents?.map((event) => (
                  <SwiperSlide key={event?.id} className="slide">
                    <div className="past_event_card_image">
                      {/* <img src="Images/AllEvents/event_background1.jpg" alt=""/> */}
                      <img
                        src={event?.data.eventImgUrl}
                        alt={event?.data.eventName}
                        onLoad={imageload}
                      />
                      <div className="past_event_card_info">
                        <h1>{event?.data.eventName}</h1>
                        <div className="other_info">
                          <h1>{event?.data.eventName}</h1>
                          <p>Date: {event?.data.eventDate}</p>
                          <p>Time: {event?.data.eventTime}</p>
                          <p>Location: {event?.data.eventLocation}</p>
                          <Button
                            onClick={(e) => {
                              viewEvent(e, event);
                            }}
                          >
                            Learn More
                          </Button>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default React.memo(AllEvents);
