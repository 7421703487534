import React from 'react'
import "./Error.css"

export default function Error() {
    return (
        <div className='Error'>
            <h1>
                404 Error
            </h1>
            <p style={ {marginTop: 10} }>Page Not Found!</p>
        </div>
    )
}
