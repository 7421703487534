import React, { useState } from 'react';
import './QRScanCode.css';
import { QrReader } from 'react-qr-reader';
import { QRCode } from "react-qrcode-logo";

function QRScanCode({ setQrCodeModel, setSelectedPaymentRef, setModel }) {
  const [data, setData] = useState('Hold QR Code steady and clear to scan.');
  const handleClose = () => {
    setQrCodeModel(false);
  }

  return (
    <div className="buyingModel" id="scanQRCode">
      <div
        className="buyingModel_container"
        id="ScanEntryModel"
        data-aos="zoom-in"
        data-aos-duration="500"
      >

        <QrReader
          className="qrCodeSection"
          scanDelay={1000}
          constraints={{ facingMode: 'environment' }}
          onResult={(result, error) => {
            if (!!result) {
              setSelectedPaymentRef(result?.text);
              setQrCodeModel(false);
              setModel(true);
            }
            if (!!error) {
              // console.info(error);
            }
          }}
        />
        <img src="/Images/Login/close.png" onClick={handleClose} alt="" />
        <p className="qr_message">{data}</p>
      </div>
    </div>
  )
}



export default QRScanCode;