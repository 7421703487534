import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ".//Dashboard.css";
import {
  getFirestore,
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  doc,
  deleteDoc
} from "firebase/firestore";
import ProgressBar from "@ramonak/react-progress-bar";
import { useNavigate } from "react-router-dom";

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import { useStateValue } from "../../StateProvider";
import CurrentDashboard from "./CurrentDashboard";
import { ContactPhoneSharp } from "@material-ui/icons";
import MemoPastDashboard from "./PastDashboard";
import UnpublishedDashboard from "./UnpublishedDashboard";

/**
 * @DisplayEvent
 *  - Display the upcoming and past events
 *  - Add an event
 *  - Edit an event
 *  - View event as guest
 * @EventAdmin
 *  - View all events
 *  - Delete an event
 *  - Publish an event
 *  - Issue Refund
 *  - Gross Income
 *  - Number of tickets sold
 *  - Number of tickets left to sell
 *  - Name of all the member for the ticket
 *  - Purchaser name
 * @Gallery
 *  - Add an image to the gallery
 *  - Delete an image from the gallery
 * @TeamMembers
 *  - View team members
 */

const adminOptions = [
  'Open',
  'Edit',
  'Check-In',
  'View as guest',
  'Delete',
];

const execOptions = [
  'Open',
  'Check-In',
  'View as guest',
]

const eventOptions = [
  'Open',
  'Edit',
  'Check-In',
  'View as guest',
]

const ITEM_HEIGHT = 48;

function Dashboard() {
  const [{ user }, dispatch] = useStateValue();
  const [selectedEvent, setSelectedEvent] = useState("Current");
  const db = getFirestore();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [menuOptions, setMenuOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const [roles, setRole] = useState("");

  const history = useNavigate();
  const handleOpenEvent = (id) => {
    // console.log("Event Id: ", id);
    history(`/dashboard/overview/${id}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log(window.innerWidth, window.innerHeight);
    document.title = "Dashboard - OGSA";
  }, []);

  useEffect(() => {
    if (user) {
      const userCollectionRef = doc(collection(db, "users"), user?.uid);
      onSnapshot(userCollectionRef, (doc) => {
        if (doc.data()?.role === "Admin") {
          setMenuOptions(adminOptions);
        }
        else if (doc.data()?.role === "Exec Team") {
          setMenuOptions(execOptions);
        }
        else if (doc.data()?.role === "Events Team") {
          setMenuOptions(eventOptions);
        }
        setRole(doc.data()?.role);
      });
    }
  }, [user]);

  return (
    <div className="dashboard">
      <div className="dashboard_header">
        <div className="searchWrapper">
          <span>
            <img
              className="search_logo"
              src="Images/Dashboard/search.png"
              alt="search"
            />
            <input
              type="search"
              placeholder="Search for an event..."
              className="search_container"
              value={searchValue}
              onChange={(e) => setSearchValue(e?.target?.value)}
            ></input>
          </span>
        </div>
        <div className="dashboard_header_button">
          {roles !== "Exec Team" && (
            <Button onClick={(e) => history("/dashboard/add/event")}>
              Add Event
            </Button>
          )}
          {/* <Button>Members</Button> */}
        </div>
      </div>
      <div className="dashboard_body">
        <div className="dashboard_body_header">
          <Button
            onClick={(e) => {
              setSelectedEvent("Current");
            }}
            id={selectedEvent === "Current" ? "selected1" : ""}
          >
            Current Event
          </Button>
          <Button
            onClick={() => {
              setSelectedEvent("Past");
            }}
            id={selectedEvent === "Past" ? "selected2" : ""}
          >
            Past Event
          </Button>
          {roles === "Admin" && (
            <Button
              onClick={() => {
                setSelectedEvent("Unpublished");
              }}
              id={selectedEvent === "Unpublished" ? "selected3" : ""}
            >
              Unpublished
            </Button>
          )}
        </div>
        <hr id={selectedEvent} className="selected_tab" />
        <hr id="divider_line" />
        <div className="dashboard_body_content">
          {selectedEvent === "Current" && (
            <CurrentDashboard ITEM_HEIGHT={ITEM_HEIGHT} options={menuOptions} search={searchValue} />
          )}
          {selectedEvent === "Past" && (
            <MemoPastDashboard ITEM_HEIGHT={ITEM_HEIGHT} options={menuOptions} search={searchValue} />
          )}
          {selectedEvent === "Unpublished" && (
            <UnpublishedDashboard ITEM_HEIGHT={ITEM_HEIGHT} options={menuOptions} search={searchValue} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
