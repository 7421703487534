import { Button } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import "./CheckIn.css";

import {
  getFirestore,
  collection,
  onSnapshot,
  doc,
  getDocs,
  getDoc,
  updateDoc,
  setDoc,
  serverTimestamp,
  query,
  where,
  FieldValue,
} from "firebase/firestore";

import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";

import { storage } from "../../firebase";
import { AgGridReact } from 'ag-grid-react';
import CheckInModel from '../Dashboard/CheckInModel';

import QRScanCode from '../../QRScanCode';
import { useStateValue } from '../../StateProvider';

const getParams = (fileName) => {
  return {
    allColumns: true,
    fileName: fileName + " Check-In"
  };
};

function CheckIn() {

  /**
   * @param {eventId} id 
   * ? Collection: All Users, Unseen Users Checked In Users, Checked Out Users
   */
  const [{ user }, dispatch] = useStateValue();
  const [roles, setRole] = useState("");
  const db = getFirestore();
  const eventId = useParams()?.eventId;
  const [event, setEvent] = useState({});
  const [statusList, setStatusList] = useState([]);
  const [payments, setPayments] = useState([]);
  const [selectedPaymentRef, setSelectedPaymentRef] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [model, setModel] = useState(false);
  const [qrCodeModel, setQrCodeModel] = useState(false);
  const history = useNavigate();

  const eventRef = doc(db, "events", eventId);
  const paymentRef = collection(db, "events", eventId, "Payment Confirm");

  const gridRef = useRef();
  const [rowData, setRowData] = useState();

  const [qrData, setQrData] = useState("No QR Code");

  useEffect(() => {
    if (user) {
      const userCollectionRef = doc(collection(db, "users"), user?.uid);
      onSnapshot(userCollectionRef, (doc) => {
        setRole(doc.data()?.role);
      });
    }
  }, [user]);

  const ChildMessageRenderer = (props) => {
    // useEffect(() => {
    //   console.log(props);
    // }, [props]);

    const invokeParentMethod = async (firstName, lastName, paymentId, checkInStatus, phone, email, ticketType) => {
      // console.log("FIRST NAME: " + firstName + "\nLAST NAME: " + lastName + "\nPAYMENT ID: " + paymentId + "\nCHECK IN STATUS: " + checkInStatus);
      // console.log("Event Id: ", eventId); 
      let tempRef = doc(db, "events", eventId, "Payment Confirm", paymentId);

      const docSnap = await getDoc(tempRef);

      if (docSnap.exists) {
        let idx = -1;
        if (ticketType === "Early Ticket") {
          if (docSnap?.data()?.earlyUserInfo) {
            Object.keys(docSnap?.data()?.earlyUserInfo).forEach((key, index) => {
              if (docSnap?.data()?.earlyUserInfo[key]?.name === firstName && docSnap?.data()?.earlyUserInfo[key]?.last_name === lastName) {
                idx = key;
              }
            });
          }
        } else {
          if (docSnap?.data()?.regularUserInfo) {
            Object.keys(docSnap?.data()?.regularUserInfo).forEach((key, index) => {
              if (docSnap?.data()?.regularUserInfo[key]?.name === firstName && docSnap?.data()?.regularUserInfo[key]?.last_name === lastName) {
                idx = key;
              }
            });
          }
        }
        if (idx !== -1) {
          if (ticketType === "Early Ticket") {
            let dbChangeSeenStatus = checkInStatus === "Checked In" ? "Checked Out" : "Checked In";
            updateDoc(tempRef, {
              ['earlyUserInfo.' + idx]: {
                "name": firstName,
                "last_name": lastName,
                "phone": phone,
                "email": email,
                "seenStatus": dbChangeSeenStatus,
              },
            }).then(() => {
            }).catch((error) => {
              console.log("Error: ", error);
            });
          } else {
            let dbChangeSeenStatus = checkInStatus === "Checked In" ? "Checked Out" : "Checked In";
            updateDoc(tempRef, {
              ["regularUserInfo." + idx]: {
                "name": firstName,
                "last_name": lastName,
                "phone": phone,
                "email": email,
                "seenStatus": dbChangeSeenStatus,
              },
            }).then(() => {
            }).catch((error) => {
              console.log("Error: ", error);
            });
          }
        }
      }
    };

    return (
      <span>
        {(props?.data?.checkInStatus === "Unseen" || props.data?.checkInStatus === "Checked Out") ? (
          <Button
            onClick={() => { invokeParentMethod(props?.data?.firstName, props?.data?.lastName, props?.data?.paymentId, props?.data?.checkInStatus, props?.data?.phone, props?.data?.email, props?.data?.ticketType) }}
            className="checkInButton"
            id={props?.data?.paymentId}
          >
            Check In
          </Button>
        ) : (
          <Button
            onClick={() => { invokeParentMethod(props?.data?.firstName, props?.data?.lastName, props?.data?.paymentId, props?.data?.checkInStatus, props?.data?.phone, props?.data?.email, props?.data?.ticketType) }}
            className="checkOutButton"
            id={props?.data?.paymentId}
          >
            Check Out
          </Button>
        )}
      </span>
    );
  };

  const [columnDefs] = useState([
    {
      field: "paymentId",
      headerName: "Payment Id",
      sortable: true,
      hide: true,
    },
    {
      field: "phone",
      headerName: "Phone",
      sortable: true,
      hide: true,
    },
    {
      field: "firstName",
      headerName: "First Name",
      sortable: true,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      sortable: true,
    },
    {
      field: "ticketType",
      headerName: "Ticket Type",
      sortable: true,
    },
    {
      field: "email",
      headerName: "Email Address",
      sortable: true,
      // wrapText: true,
    },
    {
      field: "checkInStatus",
      headerName: "Status",
      sortable: true,
      filter: true,
      // wrapText: true,

    },
    {
      field: "action",
      headerName: "Check-In Action",
      sortable: false,
      cellRenderer: ChildMessageRenderer,
    }
  ]);

  useEffect(() => {
    if (eventRef) {
      const unsubscribe = onSnapshot(eventRef,
        (snapshot) => {
          setEvent(snapshot.data());
        }
      );
      return () => {
        unsubscribe();
      };
    }
  }, []);

  useEffect(() => {
    async function getPaymentRef() {
      if (paymentRef) {
        const unsubscribe = onSnapshot(paymentRef, (payments) => {
          setPayments(
            payments.docs.map((payment) => ({
              id: payment.id,
              data: payment.data(),
            }))
          );
        });
        return () => {
          unsubscribe();
        };
      }
    }

    getPaymentRef();

  }, []);

  useEffect(() => {

    async function statusList() {
      if (payments) {
        setStatusList([]);
        Object.keys(payments).map((key, index) => {
          let id = payments[key].id;
          if (Object.keys(payments[key]?.data?.regularUserInfo).length > 0) {
            const regularDataDic = Object.values(payments[key]?.data?.regularUserInfo);
            if (regularDataDic && Object.keys(regularDataDic)?.length > 0) {
              Object.keys(regularDataDic).forEach((key, index) => {
                let regularUserInfoDic = regularDataDic[key];
                setStatusList((prevState) => [
                  ...prevState,
                  {
                    paymentId: id,
                    firstName: regularUserInfoDic.name,
                    lastName: regularUserInfoDic.last_name,
                    email: regularUserInfoDic.email,
                    ticketType: "Regular Ticket",
                    checkInStatus: regularUserInfoDic.seenStatus,
                    phone: regularUserInfoDic.phone,
                  },
                ]);
              });
            }
          }
          if (Object.keys(payments[key]?.data?.earlyUserInfo).length > 0) {
            const earlyDataDic = Object.values(payments[key]?.data?.earlyUserInfo);
            if (earlyDataDic && Object.keys(earlyDataDic)?.length > 0) {
              Object.keys(earlyDataDic).forEach((key, index) => {
                let earlyUserInfoDic = earlyDataDic[key];
                setStatusList((prevState) => [
                  ...prevState,
                  {
                    paymentId: id,
                    firstName: earlyUserInfoDic.name,
                    lastName: earlyUserInfoDic.last_name,
                    email: earlyUserInfoDic.email,
                    ticketType: "Early Ticket",
                    checkInStatus: earlyUserInfoDic.seenStatus,
                    phone: earlyUserInfoDic.phone,
                  },
                ]);
              });
            }
          }
        });
      }
    }

    statusList();

  }, [payments]);


  useEffect(() => {
    if (statusList && statusList.length > 0) {
      setRowData(statusList);
    }
  }, [statusList]);

  const unseenStatus = useCallback((params) => {
    document.getElementById("btn btn-statusFilter-all").classList.remove("active");
    document.getElementById("btn btn-statusFilter-unseen").classList.add("active");
    document.getElementById("btn btn-statusFilter-checkedOut").classList.remove("active");
    document.getElementById("btn btn-statusFilter-checkedIn").classList.remove("active");
    var unseenStatus = gridRef.current.api.getFilterInstance(
      'checkInStatus'
    );

    unseenStatus.setModel({
      type: 'contains',
      filter: 'Unseen',
    });

    gridRef.current.api.onFilterChanged();
  }, []);

  const allStatus = useCallback((params) => {
    document.getElementById("btn btn-statusFilter-all").classList.add("active");
    document.getElementById("btn btn-statusFilter-unseen").classList.remove("active");
    document.getElementById("btn btn-statusFilter-checkedOut").classList.remove("active");
    document.getElementById("btn btn-statusFilter-checkedIn").classList.remove("active");
    var allStatus = gridRef.current.api.getFilterInstance(
      'checkInStatus'
    );

    allStatus.setModel({
      type: 'contains',
      filter: '',
    });

    gridRef.current.api.onFilterChanged();
  }, []);

  const checkedInStatus = useCallback((params) => {
    document.getElementById("btn btn-statusFilter-all").classList.remove("active");
    document.getElementById("btn btn-statusFilter-unseen").classList.remove("active");
    document.getElementById("btn btn-statusFilter-checkedOut").classList.remove("active");
    document.getElementById("btn btn-statusFilter-checkedIn").classList.add("active");
    var checkedInStatus = gridRef.current.api.getFilterInstance(
      'checkInStatus'
    );

    checkedInStatus.setModel({
      type: 'contains',
      filter: 'Checked In',
    });

    gridRef.current.api.onFilterChanged();
  }, []);

  const checkedOutStatus = useCallback((params) => {
    document.getElementById("btn btn-statusFilter-all").classList.remove("active");
    document.getElementById("btn btn-statusFilter-unseen").classList.remove("active");
    document.getElementById("btn btn-statusFilter-checkedOut").classList.add("active");
    document.getElementById("btn btn-statusFilter-checkedIn").classList.remove("active");
    var checkedOutStatus = gridRef.current.api.getFilterInstance(
      'checkInStatus'
    );

    checkedOutStatus.setModel({
      type: 'contains',
      filter: 'Checked Out',
    });

    gridRef.current.api.onFilterChanged();
  }, []);

  const onGridReady = useCallback((params) => {
    if (window.innerWidth >= 1170) {
      sizeToFit();
    }
  }, []);

  const sizeToFit = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const autoSizeAll = useCallback(() => {
    const allColumnIds = [];
    gridRef.current.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById("filter-text-box-checkIn").value
    );

  }, []);

  const onCellClicked = (params) => {
    if (params.colDef.field !== "action") {
      setSelectedPaymentRef(params?.data?.paymentId);
      setSelectedUser(params?.data?.firstName + " " + params?.data?.lastName);
      setModel(true);
    }
  };

  function onBtnExport() {
    gridRef.current.api.exportDataAsCsv(getParams(event?.eventName));
  }


  useEffect(() => {
    if (!model) {
      setSelectedUser("");
    }
  }, [model]);

  useEffect(() => {
    // window.scroll(0, 0);
    document.title = "Check In - Dashboard";
  }, []);

  useEffect(() => {
    if (event?.eventName) {
      document.title = `${event?.eventName} - Check In`
    }
  }, [event]);

  return (
    <>
      {model && (
        <CheckInModel
          paymentId={selectedPaymentRef}
          setModel={setModel}
          eventId={eventId}
          fullName={selectedUser}
        />
      )}
      {qrCodeModel && (
        <QRScanCode
          setQrCodeModel={setQrCodeModel}
          setSelectedPaymentRef={setSelectedPaymentRef}
          setModel={setModel}
        />
      )}
      <div className="CheckIn">
        {/* <h2 id="eventTitle">Check-In - {event?.eventName}</h2> */}
        <Button onClick={(e) => history(-1)}>
          <span id="backIcon">
            <img src="/Images/Dashboard/backIcon.png" alt="" />
            <h3>Back</h3>
          </span>
        </Button>
        <hr />
        <div className="overviewEvent_body_body" id="checkInData" data-aos="fade-up" data-aos-duration="600" data-aos-easing="ease">
          <div className="filterContainer">
            <div className="statusFilterButton">
              <Button onClick={allStatus} className="btn btn-statusFilter-all active" id="btn btn-statusFilter-all">All</Button>
              <Button onClick={unseenStatus} className="btn btn-statusFilter-unseen" id="btn btn-statusFilter-unseen">Unseen</Button>
              <Button onClick={checkedInStatus} className="btn btn-statusFilter-checkedIn" id="btn btn-statusFilter-checkedIn">Checked-In</Button>
              <Button onClick={checkedOutStatus} className="btn btn-statusFilter-checkedOut" id="btn btn-statusFilter-checkedOut">Checked-Out</Button>
            </div>
            <div className="search">
              <span className="searchWrapper" id="searchContainer">
                <img
                  className="search_logo"
                  id="checkInSearchLogo"
                  src="/Images/Dashboard/search.png" alt="search" />
                <input
                  type="search"
                  id="filter-text-box-checkIn"
                  placeholder="Name, Email, Payment Confirmation..."
                  onInput={onFilterTextBoxChanged}
                  className="search_container"
                ></input>
              </span>
              <span>
                {roles === "Admin" && (
                  <Button className="btnQrCodeAndExportCSV" onClick={onBtnExport} id="exportButton">Export As CSV</Button>)
                }
                <Button onClick={() => { setQrCodeModel(true) }} className="btnQrCodeAndExportCSV">Scan QR Code</Button>
              </span>
            </div>
          </div>
          <div className="ag-theme-alpine">
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              rowSelection="multiple"
              onGridReady={onGridReady}
              suppressAggFuncInHeader={true}
              animateRows={true}
              rowHeight={50}
              cacheQuickFilter={true}
              onCellClicked={onCellClicked}
              suppressScrollOnNewData={true}
              suppressExcelExport={true}
            ></AgGridReact>
          </div>
        </div>
      </div >
    </>
  )
}

export default CheckIn;