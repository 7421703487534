import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./Contact.css";
import emailjs from "emailjs-com";
import { init } from "emailjs-com";
import RequiredInput from "../../Component/RequiredInput";
import { Alert } from "@mui/material";
import inputErrorMessage from "../../Component/InputErrorMessage";
import SpinnerSVG from "../../spinner.svg";
import { useNavigate } from "react-router-dom";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    init("user_XFvwYuodBRSuuvb05H3bS");
    window.scrollTo(0, 0);
    document.title = "Contact Us - OGSA";
  }, []);


  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents default refresh by the browser
    setProcessing(true);
    var templateParams = {
      to_name: "ogsa2021@gmail.com",
      firstname: name,
      email: email,
      phone: phone,
      message: message,
      Subject: subject,
      from_name: name,
      reply_to: email,
    };

    if (name !== "" && email !== "" && phone !== "" && message !== "" && subject !== "") {
      emailjs.send('service_adm8lli', 'template_dmd5sg7', templateParams)
        .then((response) => {
          setError("Message sent, we will get back to you shortly!");
          document.getElementById("message_status")?.classList.add("success");
        },
          (error) => {
            setError("An error occurred, please try again.");
          });
    } else {
      alert("Please fill all the necessary information before submitting.");
    }

    document.getElementById("name_contact").setAttribute("focused", "false");
    document.getElementById("email_contact").setAttribute("focused", "false");
    document.getElementById("phone_contact").setAttribute("focused", "false");
    document.getElementById("subject_contact").setAttribute("focused", "false");
    document.getElementById("message_contact").setAttribute("focused", "false");
    setName("");
    setEmail("");
    setPhone("");
    setSubject("");
    setMessage("");
  };

  useEffect(() => {
    if (error) {
      if (error === "Message sent, we will get back to you shortly!") {
        setProcessing(false);
        document.getElementById("message_status")?.classList.add("success");
      }
      setTimeout(() => {
        setError(null);
      }, 7000);
    }
  }, [error]);

  return (
    <>
      {processing && (
        <div className="loading_container_payment">
          <img src={SpinnerSVG} alt="Loading..." id="spinner" />
        </div>
      )}
      <div className="contact">
        <div className="contact-header">
          <img
            src="https://images.unsplash.com/photo-1514064019862-23e2a332a6a6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1314&q=80"
            alt=""
          />
          <div className="contact-header-text">
            <h1>Contact Us</h1>
          </div>
        </div>
        <div
          className="contact_container"
          data-aos="fade-up"
          data-aos-easing="ease-out-back"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="950"
        >
          <h1>Feel free to reach out!</h1>
          <hr id="line_break"></hr>
          <p>
            Have any questions? Submit your question here and we will get
            back to you as soon as possible.
          </p>
          <form onSubmit={handleSubmit}>
            <div className="contact_form">
              <div className="contact_form_line">
                <span>
                  <label>Name</label>
                  <RequiredInput
                    id="name_contact"
                    inputType="input"
                    type="text"
                    currValue={name}
                    placeholder="Enter your name"
                    onChangeVariable={setName}
                    pattern="^[A-Z][a-z][A-Za-z ]*$"
                    errorMessage={inputErrorMessage["name"]}
                    required
                  />
                  {/* <input type="text" pattern="^[A-Z][a-z][A-Za-z ]*$" placeholder="" onChange={e => setName(e.target.value)} required={"required"} value={name} /> */}
                </span>
                <span>
                  <label>Email Address</label>
                  {/* <input type="email" placeholder="" pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$" onChange={e => setEmail(e.target.value)} required={"required"} value={email} /> */}
                  <RequiredInput
                    id="email_contact"
                    inputType="input"
                    type="email"
                    currValue={email}
                    placeholder="Enter your email address"
                    onChangeVariable={setEmail}
                    pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
                    errorMessage={inputErrorMessage["email"]}
                    required
                  />
                </span>
              </div>
              <div className="contact_form_line">
                <span>
                  <label>Phone</label>
                  {/* <input type="text" placeholder="" pattern="(\([0-9]{3})\) [0-9]{3}-[0-9]{4}" onChange={e => setPhone(e.target.value)} required={"required"} value={phone} /> */}
                  <RequiredInput
                    id="phone_contact"
                    inputType="input"
                    type="text"
                    currValue={phone}
                    placeholder="e.g. 123-456-7890"
                    onChangeVariable={setPhone}
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    errorMessage={inputErrorMessage["phoneNumber"]}
                    required
                  />
                </span>
                <span>
                  <label>Subject</label>
                  {/* <input type="text" pattern="^[A-Z][a-z][A-Za-z ]*$" placeholder="" onChange={e => setSubject(e.target.value)} required={"required"} value={subject} /> */}
                  <RequiredInput
                    id="subject_contact"
                    inputType="input"
                    type="text"
                    currValue={subject}
                    placeholder="e.g. Tickets"
                    onChangeVariable={setSubject}
                    pattern="^[A-Z][a-z][A-Za-z ]*$"
                    errorMessage={inputErrorMessage["name"]}
                    required
                  />
                </span>
              </div>
              <span>
                <label>Message</label>
                {/* <textarea
                  rows="5"
                  cols="50"
                  placeholder=""
                  spellCheck="true"
                  maxLength="400"
                  onChange={e => setMessage(e.target.value)}
                  value={message}
                ></textarea> */}
                <RequiredInput
                  id="message_contact"
                  inputType="textarea"
                  type="text"
                  currValue={message}
                  placeholder="e.g. When are the tickets releasing for the next event?"
                  onChangeVariable={setMessage}
                  pattern="^[A-Za-z0-9 ]*$"
                  errorMessage={inputErrorMessage["alphanumeric"]}
                  required
                />
              </span>
              <div>
                {error && (
                  <div className="error_container" id="message_status">
                    {/* <img src="/Images/Event/warning.png" /> */}
                    <span>{error}</span>
                  </div>
                )}
              </div>
              <Button type="Submit">Submit</Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Contact;
