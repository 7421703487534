import "./App.css";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "./Pages/Home/Home";
import Footer from "./Pages/Footer/Footer";
import Header from "./Pages/Header/Header";
import Login from "./Pages/Login/Login";
import SignUp from "./Pages/Register/SignUp";
import { useStateValue } from "./StateProvider";
import { auth } from "./firebase";
import AllEvents from "./Pages/Event/AllEvents";
import Gallery from "./Pages/Test/Gallery";
import Shop from "./Pages/Test/Shop";
import Team from "./Pages/Team/Team";
import Contact from "./Pages/Contact Us/Contact";
import Aos from "aos";
import "aos/dist/aos.css";
import Event from "./Pages/Event/View Event/Event";
import Dashboard from "./Pages/Dashboard/Dashboard";
import EditEvent from "./Pages/Dashboard/EditEvent";
import { loadStripe } from "@stripe/stripe-js";
import Error from "./Pages/Error/Error";

import { Elements } from "@stripe/react-stripe-js";
import Order from "./Pages/Orders/Order";
import AddingEvent from "./Pages/Dashboard/AddingEvent";
import OverviewEvent from "./Pages/Dashboard/OverviewEvent";
import CheckIn from "./Pages/Dashboard/CheckIn";
import { getFirestore, collection, onSnapshot, doc } from "firebase/firestore";
import SpinnerSVG from "./spinner.svg";

// "SET PORT=127.0.0.1 && react-scripts start"
// HOST=127.0.0.1 PORT=3000 npm run start

function App() {
  const db = getFirestore();
  const [{ user, role }, dispatch] = useStateValue();
  const [currRole, setCurrRole] = useState("");

  const promise = loadStripe(
    "pk_live_51Jmj8oBEvCTxWMTNNVuCWyxCKFXUvPllcx2Ype1YMtsIvKAUF22EAHbEyTgQDZufCjJKcYecqUOwHCld4nHznl6800iIReEkMG"
    // "pk_test_51I4wsSDNosFzfdDhPhLjLFjXz5SIle0vmQnk6FuafjIviiwcFcG1NYRxckUp3sR7WDObPcCOpgWzaXKbwOGXmxzR002bBy7KP6"
  );

  useEffect(() => {
    async function updateRole(authUser) {
      const userCollectionRef = doc(collection(db, "users"), authUser?.uid);
      onSnapshot(userCollectionRef, (doc) => {
        if (doc.data()?.role !== "") {
          dispatch({
            type: "SET_USER",
            user: authUser,
            role: doc.data()?.role,
          });
          setCurrRole(doc.data()?.role);
        } else {
          dispatch({
            type: "SET_USER",
            user: authUser,
            role: "Regular",
          });
          setCurrRole("Regular");
        }
      });
    }

    auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        // the user just logged in / the user was logged in
        await updateRole(authUser);
      } else {
        // the user is logged out
        dispatch({
          type: "SET_USER",
          user: null,
          role: "Logged Out",
        });
        setCurrRole("Logged Out");
      }
    });
  }, []);

  useEffect(() => {
    Aos.init({ once: true, duration: 500 });
  }, []);

  if (currRole === "") {
    return (
      <div className="App">
        <div className="loading_container_payment">
          {/* <img src="/Images/Event/spinner.svg" alt = "Loading..." /> */}
          <img src={SpinnerSVG} alt="Loading..." id="spinner" />
        </div>
      </div>
    );
  } else {
    /**
     * @TODO
     * - Add a safety check on all the pages we don't want anyone without proper access
     */
    return (
      <div className="App">
        <Router>
          <Routes>
            <Route path="/sign_up" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            {user ? (
              <>
                <Route
                  path="/orders"
                  element={
                    <div>
                      <Header activePage={"Orders"} />
                      <Order />
                      <Footer />
                    </div>
                  }
                />
                {(role === "Admin" ||
                  role === "Events Team" ||
                  role === "Exec Team") && (
                  <>
                    <Route
                      path="/check-in/event/:eventId"
                      element={
                        <div>
                          <Header activePage={"Dashboard"} />
                          <CheckIn />
                          <Footer />
                        </div>
                      }
                    />

                    {role !== "Exec Team" && (
                      <>
                        <Route
                          path="/dashboard/add/event"
                          element={
                            <div>
                              <Header activePage={"Dashboard"} />
                              <AddingEvent />
                              <Footer />
                            </div>
                          }
                        />

                        <Route
                          path="/edit/event/:eventId"
                          element={
                            <div>
                              <Header activePage={"Dashboard"} />
                              <EditEvent />
                              <Footer />
                            </div>
                          }
                        />
                      </>
                    )}

                    <Route
                      path="/dashboard/overview/:eventId"
                      element={
                        <div>
                          <Header activePage={"Dashboard"} />
                          <OverviewEvent />
                          <Footer />
                        </div>
                      }
                    />

                    <Route
                      path="/dashboard"
                      element={
                        <div>
                          <Header activePage={"Dashboard"} />
                          <Dashboard />
                          <Footer />
                        </div>
                      }
                    />
                  </>
                )}
              </>
            ) : (
              <Route
                path="/"
                element={
                  <div>
                    <Header activePage={"Home"} />
                    <Home />
                    <Footer />
                  </div>
                }
              />
            )}

            <Route
              path="/events/:id"
              element={
                <div>
                  <Header activePage={"All Events"} />
                  <Elements stripe={promise}>
                    <Event />
                  </Elements>
                  <Footer />
                </div>
              }
            />

            <Route
              path="/events"
              element={
                <div>
                  <Header activePage={"All Events"} />
                  <AllEvents />
                  <Footer />
                </div>
              }
            />
            <Route
              path="/team"
              element={
                <div>
                  <Header activePage={"Team"} />
                  <Team />
                  <Footer />
                </div>
              }
            />
            <Route
              path="/contact"
              element={
                <div>
                  <Header activePage={"Contact"} />
                  <Contact />
                  <Footer />
                </div>
              }
            />
            <Route
              path="/"
              element={
                <div>
                  <Header activePage={"Home"} />
                  <Home />
                  <Footer />
                </div>
              }
            />
            <Route
              path="*"
              element={
                <div>
                  <Header />
                  <Error />
                </div>
              }
            />
          </Routes>
        </Router>
      </div>
    );
  }
}

export default App;
