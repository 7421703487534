import React, { useEffect, useState } from 'react';
import { Alert } from '@mui/material';

function BuyingModelInput({ inputType = "input", id, defaultValue, type, placeholder = "", pattern, errorMessage, required = false, ticketType = "", onChangeValue = false }) {
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        if (ticketType === "earlyBird") {
            let string = id.replaceAll(" ", "\\ ")
            if (defaultValue && document.querySelector(`#${string}:nth-child(2)`).checkValidity() === false) {
                setFocused(true);
            }
        } else {
            if (defaultValue && document.getElementById(id).checkValidity() === false) {
                setFocused(true);
            }
        }
    }, [defaultValue]);

    const handleFocus = (e) => {
        if (e?.target?.value !== "") {
            setFocused(true);
        }
    }

    return (
        <>
            {!onChangeValue ? (
                <>
                    {inputType === "input" && (
                        <input
                            id={id}
                            type={type}
                            defaultValue={defaultValue}
                            pattern={pattern}
                            required={required}
                            onBlur={handleFocus}
                            placeholder={placeholder}
                            focused={focused.toString()}
                        />
                    )}
                    <Alert severity="error" className="buyingModelError">
                        {errorMessage}
                    </Alert>
                </>
            ) : (
                <>
                    {inputType === "input" && (
                        <input
                            id={id}
                            type={type}
                            onChange={(e) => onChangeValue(e.target?.value)}
                            pattern={pattern}
                            required={required}
                            onBlur={handleFocus}
                            placeholder={placeholder}
                            focused={focused.toString()}
                        />
                    )}
                    <Alert severity="error" className="buyingModelError">
                        {errorMessage}
                    </Alert>
                </>
            )}
        </>
    )
}

export default BuyingModelInput;