import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./Event.css";
import {
  getFirestore,
  collection,
  onSnapshot,
  query,
  where,
  doc,
} from "firebase/firestore";
import { Button } from "@material-ui/core";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Mousewheel,
} from "swiper";
// import "swiper/swiper.scss";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/scrollbar";
import { Chrono } from "react-chrono";
import BuyingModel from "../Buying Event/BuyingModel";
import { useStateValue } from "../../../StateProvider";
import Modal from "../../../Modal";
import Map from "../../../Map";
// import data from "./data.js";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay, Mousewheel]);

function Event() {
  const [{ user }, dispatch] = useStateValue();
  const eventId = useParams();
  const db = getFirestore();
  const eventRef = doc(collection(db, "events"), eventId.id);
  const [event, setEvent] = useState([]);

  // const [sponsorsName, setSponsorsName] = useState([]);
  // const [sponsorsLogo, setSponsorsLogo] = useState([]);
  // const [sponsorsField, setSponsorsField] = useState([]);
  // const [sponsorsOtherInfo, setSponsorsOtherInfo] = useState([]);
  // const [sponsorsPhoneNumber, setSponsorsPhoneNumber] = useState([]);

  const [sponsors, setSponsors] = useState({});
  const [sponsorsSize, setSponsorsSize] = useState(0);

  const [locationName, setLocationName] = useState("");
  const [address, setAddress] = useState("");

  // const [timelineActivityTime, setTimelineActivityTime] = useState([]);
  // const [timelineActivityName, setTimelineActivityName] = useState([]);
  // const [timelineActivityUrl, setTimelineActivityUrl] = useState([]);

  const [timeline, setTimeline] = useState({});
  const [timelineSize, setTimelineSize] = useState(0);

  const [data, setData] = useState([]);
  const [modelOpen, setModelOpen] = useState(false);

  const [show, setShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    const unsubscribe = onSnapshot(eventRef, (event) => {
      setEvent(event.data());
    });
    return () => {
      unsubscribe();
    };
  }, []);

  // useEffect(() => {
  //     console.log(event.eventName);
  // },[event]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Event - OGSA";
  }, []);

  useEffect(() => {
    if (event.eventName) {
      document.title = `${event?.eventName} - OGSA`;
    }
  }, [event]);

  useEffect(() => {
    // console.log(event);
    // setSponsorsName(event?.sponsorsName);
    // setSponsorsLogo(event?.sponsorsImgUrl);
    // setSponsorsField(event?.sponsorsField);
    // setSponsorsOtherInfo(event?.sponsorsOtherInfo);
    // setSponsorsPhoneNumber(event?.sponsorsPhoneNumber);

    setSponsors(event?.sponsor);

    setTimeline(event?.timelineActivity);

    // setTimelineActivityTime(event?.timelineActivityTime);
    // setTimelineActivityName(event?.timelineActivityName);

    setAddress(event?.eventAddress);
    setLocationName(event?.eventLocation);
  }, [event]);

  useEffect(() => {
    if (sponsors) {
      setSponsorsSize(Object.keys(sponsors).length);
    }
  }, [sponsors]);

  useEffect(() => {
    // console.log(sponsorsName?.length);

    // timelineActivityTime?.map((value, index) => {
    //     data?.push(
    //         {
    //             title: value,
    //             cardTitle: timelineActivityName?.[index],
    //         }
    //     );
    // });
    if (timeline) {
      function timelineHelper() {
        setData([]);

        const data = [];

        Object.keys(timeline).map((value, index) => {
          data.push({
            title: timeline[value]?.time,
            cardTitle: timeline[value]?.activity,
          });
        });

        return data;
      }
      setData(timelineHelper());
    }
  }, [timeline]);

  const handleBuyingModel = () => {
    if (user) {
      setModelOpen(true);
    } else {
      setShow(true);
      setModalTitle("Please Login!");
      setModalMessage(
        "You need to be logged in to buy tickets for the events!"
      );
    }
  };

  return (
    <div className="event">
      {modelOpen && <BuyingModel modelOpen={setModelOpen} eventId={eventId} />}
      {show && (
        <Modal
          setShowModal={setShow}
          modalTitle={modalTitle}
          modalMessage={modalMessage}
        />
      )}
      {/* <div className="event_container"> */}
      <div
        className="event_header"
        data-aos="fade-down"
        data-aos-duration="650"
      >
        <img src={event?.eventImgUrl} alt="Event Background Image" />
        <div className="event_header_info">
          {/* <div className="event_card_nothing">
                            <h1>No Upcoming Events</h1>
                        </div> */}
          <div className="event_header_info_wrapper">
            <div className="event_header_info_wrapper_left">
              <h1>{event?.eventName}</h1>
              <h3>
                {event?.eventDate} @ {event?.eventTime}
              </h3>
            </div>
            {event?.eventStatus === "Current" ||
            event?.AdminTest === "Let's go" ? (
              <div className="event_header_info_wrapper_right">
                <Button onClick={handleBuyingModel}>Buy Tickets</Button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div className="eventSection" data-aos="fade-up">
        <h1>Join Us</h1>
        <hr className="event_subTitle_underline" />
        <div className="event_subTitle_wrapper">
          <p>{event?.eventDescription}</p>
        </div>
      </div>

      <div className="eventSection" data-aos="fade-up">
        <h1>Dress Code</h1>
        <hr className="event_subTitle_underline" />
        <div className="event_subTitle_wrapper">
          <p>{event?.eventDressCode}</p>
        </div>
      </div>

      {/* https://github.com/prabhuignoto/react-chrono#-installation 
                        Used the above repo for the timeline function */}

      {data.length > 0 && (
        <>
          <div className="eventSection" data-aos="fade-up">
            <h1>Timeline</h1>
            <hr className="event_subTitle_underline" />
            <div className="event_subTitle_wrapper" id="timeline">
              <Chrono
                items={data}
                mode="VERTICAL_ALTERNATING"
                allowDynamicUpdate
                borderLessCards
                cardPositionHorizontal="CENTER"
                hideControls
                cardWidth={220}
                cardHeight={80}
                theme={{
                  primary: "#f7b32b",
                  secondary: "",
                  cardBgColor: "#7e5920",
                  cardForeColor: "#f5f5f5",
                  titleColor: "#f7b32b",
                }}
              >
                {/* <div className="chrono-icons">
                                  <img src = "/Images/Event/dancer.png" id = "timeline_images"/>
                                  <img src = "/Images/Event/disco-ball.png" id = "timeline_images"/>
                              </div> */}
              </Chrono>
            </div>
          </div>
        </>
      )}

      {(sponsors?.length > 0 || sponsorsSize !== 0) && (
        <>
          <div className="eventSection" data-aos="fade-up">
            <h1>Sponsors</h1>
            <hr className="event_subTitle_underline" />
            {sponsorsSize === 0 || sponsorsSize === undefined ? (
              <div
                className="past_event_card_container"
                id="sponsors_card_container"
              >
                {/* <img src="Images/AllEvents/event_background1.jpg" alt=""/> */}
                <div className="past_event_card_image">
                  <img src="/Images/AllEvents/event_background1.jpg" alt="" />
                  <div className="past_event_card_infos">
                    <h1>Loading...</h1>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="past_event_card_container"
                id="sponsors_card_container"
              >
                <Swiper
                  // install Swiper modules
                  modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                  breakpoints={{
                    1680: {
                      slidesPerView: sponsorsSize > 3 ? 3 : sponsorsSize,
                      spaceBetween: 20,
                    },
                    1000: {
                      slidesPerView: sponsorsSize > 3 ? 3 : sponsorsSize,
                      spaceBetween: 20,
                    },
                    650: {
                      slidesPerView: sponsorsSize > 3 ? 2 : sponsorsSize,
                      spaceBetween: 20,
                    },
                    200: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                  }}
                  autoplay={{
                    disableOnInteraction: true,
                  }}
                  // autoplayDisableOnInteraction={true}
                  grabCursor={true}
                  // mousewheel={true}
                  direction={"horizontal"}
                  // navigation = {true}
                  pagination={{ clickable: true }}
                  // scrollbar={{ draggable: true }}
                  // onSwiper={(swiper) => console.log(swiper)}
                  // onSlideChange={() => console.log('slide change')}
                >
                  {Object.keys(sponsors).map((val, index) => (
                    <SwiperSlide key={index} className="slide">
                      <div
                        className="past_event_card_image"
                        id="sponsors_image"
                      >
                        <img src={sponsors[val]?.logo} alt="Sponsors Image" />
                        <div
                          className="past_event_card_info"
                          id="sponsors_card"
                        >
                          <h1>{sponsors[val]?.name}</h1>
                          <div className="other_info" id="other_sponsors_card">
                            <h1 id="sponsor_name">{sponsors[val]?.name}</h1>
                            {sponsors[val]?.field !== "" && (
                              <p>Occupation: {sponsors[val]?.field}</p>
                            )}
                            {sponsors[val]?.otherInfo !== "" && (
                              <p>{sponsors[val]?.otherInfo}</p>
                            )}
                            {sponsors[val]?.phone !== "" && (
                              <p>Phone: {sponsors[val]?.phone}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )}
          </div>
        </>
      )}

      {event?.eventLocation && (
        <>
          <div className="eventSection" data-aos="fade-up">
            <h1>Location</h1>
            <hr className="event_subTitle_underline" />
            <div className="location_container">
              {/* @43.8072882,-79.2922001 */}
              <iframe
                width="100%"
                height="500px"
                loading="lazy"
                style={{ border: "none" }}
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBleRL9HNiKTpvqus-3ZnPJB0uw_ALLAi0&q=${event?.eventLocation}`}
                className="mapContainer"
              ></iframe>
              {/* <Map
                address={event?.eventAddress}
                locationName={event?.eventLocation}
              /> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Event;
