import React, { useEffect, useState } from "react";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../StateProvider";
import { auth } from "../../firebase";

function Header({ activePage }) {
  const history = useNavigate();
  const [{ user, role }, dispatch] = useStateValue();

  useEffect(() => {
    if (activePage === "Home") {
      document.getElementById("home_li")?.classList.add("active");

      document.getElementById("events_li")?.classList.remove("active");
      document.getElementById("gallery_li")?.classList.remove("active");
      document.getElementById("team_li")?.classList.remove("active");
      document.getElementById("contact_li")?.classList.remove("active");
      document.getElementById("dashboard_li")?.classList.remove("active");
      document.getElementById("order_li")?.classList.remove("active");
    } else if (activePage === "Dashboard") {
      document.getElementById("dashboard_li")?.classList.add("active");

      document.getElementById("home_li")?.classList.remove("active");
      document.getElementById("events_li")?.classList.remove("active");
      document.getElementById("gallery_li")?.classList.remove("active");
      document.getElementById("team_li")?.classList.remove("active");
      document.getElementById("contact_li")?.classList.remove("active");
      document.getElementById("order_li")?.classList.remove("active");
    } else if (activePage === "All Events") {
      document.getElementById("events_li")?.classList.add("active");

      document.getElementById("home_li")?.classList.remove("active");
      document.getElementById("gallery_li")?.classList.remove("active");
      document.getElementById("team_li")?.classList.remove("active");
      document.getElementById("contact_li")?.classList.remove("active");
      document.getElementById("dashboard_li")?.classList.remove("active");
      document.getElementById("order_li")?.classList.remove("active");
    } else if (activePage === "Gallery") {
      document.getElementById("gallery_li")?.classList.add("active");

      document.getElementById("events_li")?.classList.remove("active");
      document.getElementById("home_li")?.classList.remove("active");
      document.getElementById("team_li")?.classList.remove("active");
      document.getElementById("contact_li")?.classList.remove("active");

      document.getElementById("dashboard_li")?.classList.remove("active");
      document.getElementById("order_li")?.classList.remove("active");
    } else if (activePage === "Team") {
      document.getElementById("team_li")?.classList.add("active");

      document.getElementById("events_li")?.classList.remove("active");
      document.getElementById("home_li")?.classList.remove("active");
      document.getElementById("gallery_li")?.classList.remove("active");
      document.getElementById("contact_li")?.classList.remove("active");

      document.getElementById("dashboard_li")?.classList.remove("active");
      document.getElementById("order_li")?.classList.remove("active");
    } else if (activePage === "Contact") {
      document.getElementById("contact_li")?.classList.add("active");

      document.getElementById("events_li")?.classList.remove("active");
      document.getElementById("home_li")?.classList.remove("active");
      document.getElementById("gallery_li")?.classList.remove("active");
      document.getElementById("team_li")?.classList.remove("active");

      document.getElementById("dashboard_li")?.classList.remove("active");
      document.getElementById("order_li")?.classList.remove("active");
    } else if (activePage === "Orders") {
      document.getElementById("order_li")?.classList.add("active");

      document.getElementById("contact_li")?.classList.remove("active");
      document.getElementById("events_li")?.classList.remove("active");
      document.getElementById("home_li")?.classList.remove("active");
      document.getElementById("gallery_li")?.classList.remove("active");
      document.getElementById("team_li")?.classList.remove("active");
      document.getElementById("dashboard_li")?.classList.remove("active");
    }
  }, [activePage]);

  const handleLogout = () => {
    if (user) {
      auth.signOut();
      history("/login");
    }
  };

  const handleDifferentPages = (e) => {
    const checkbox = document.querySelector("#menu-btn");

    var value = e.target.id;
    if (value === "home") {
      try {
        checkbox.checked = false;
      } catch (err) {
        // console.log(err);
      }
      history("/");
    } else if (value === "dashboard") {
      try {
        checkbox.checked = false;
      } catch (err) {
        // console.log(err);
      }
      history("/dashboard");
    } else if (value === "events") {
      try {
        checkbox.checked = false;
      } catch (err) {
        // console.log(err);
      }
      history("/events");
    } else if (value === "team") {
      try {
        checkbox.checked = false;
      } catch (err) {
        // console.log(err);
      }
      history("/team");
    } else if (value === "contact_us") {
      try {
        checkbox.checked = false;
      } catch (err) {
        // console.log(err);
      }
      history("/contact");
    } else if (value === "login") {
      try {
        checkbox.checked = false;
      } catch (err) {
        // console.log(err);
      }
      history("/login");
    } else if (value === "order") {
      try {
        checkbox.checked = false;
      } catch (err) {
        // console.log(err);
      }
      history("/orders");
    }
  };

  return (
    <header className="header" id="header">
      <div className="header__logoContainer">
        <img
          onClick={handleDifferentPages}
          src="/Images/logo.png"
          width="85"
          alt=""
          className="logo"
          id="home"
        />
      </div>
      <input className="menu-btn" type="checkbox" id="menu-btn" />
      <label className="menu-icon" htmlFor="menu-btn">
        <span className="navicon"></span>
      </label>
      <ul className="menu">
        <li id="home_li">
          <a onClick={handleDifferentPages} id="home">
            Home
            <hr className="active_line" />
          </a>
        </li>
        {(role === "Admin" ||
          role === "Events Team" ||
          role === "Exec Team") && (
          <li id="dashboard_li">
            <a onClick={handleDifferentPages} id="dashboard">
              Dashboard
              <hr className="active_line" />
            </a>
          </li>
        )}
        <li id="events_li">
          <a onClick={handleDifferentPages} id="events">
            Events
            <hr className="active_line" />
          </a>
        </li>
        {user && (
          <li id="order_li">
            <a onClick={handleDifferentPages} id="order">
              Orders
              <hr className="active_line" />
            </a>
          </li>
        )}

        <li id="team_li">
          <a onClick={handleDifferentPages} id="team">
            Team
            <hr className="active_line" />
          </a>
        </li>
        <li id="contact_li">
          <a onClick={handleDifferentPages} id="contact_us">
            Contact Us
            <hr className="active_line" />
          </a>
        </li>
      </ul>
      <ul className="menu" id="secondSection">
        {user ? (
          <li>
            <a onClick={handleLogout} id="login">
              Logout
            </a>
          </li>
        ) : (
          <li>
            <a onClick={handleDifferentPages} id="login">
              Login
            </a>
          </li>
        )}
      </ul>
    </header>
  );
}

export default Header;
