import * as React from "react";
import "./ScanEntryModel.css";
import { QRCode } from "react-qrcode-logo";

function ScanEntryModel({ scanId, modelOpen }) {
  const handleClose = () => {
    modelOpen(false);
  };
  return (
    <div className="buyingModel" id="scanEntryWrapper">
      <div
        className="buyingModel_container"
        id="ScanEntryModel"
        data-aos="zoom-in"
        data-aos-duration="500"
      >
        <img src="/Images/Login/close.png" onClick={handleClose} />

        <QRCode
          value={scanId}
          eyeRadius={5} // 5 pixel radius for all corners of all positional eyes
          logoImage="Images/logo.png"
          logoWidth={800} // 250
          logoHeight={600} // 200
          logoOpacity={0.7}
          qrStyle={({ width: 45 }, "dots")}
          // fgColor={"#4c402c"}
          // bgColor={"#3a3a3a"}
          quietZone={100}
          size={800}
          ecLevel={"H"}
          enableCORS={true}
          className="qrCode"
        />
      </div>
    </div>
  );
}

export default ScanEntryModel;
