import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./OverviewEvent.css";
import {
  getFirestore,
  collection,
  onSnapshot,
  query,
  where,
  doc,
} from "firebase/firestore";
import { Button } from "@material-ui/core";
import OverviewModel from "./OverviewModel";
import SpinnerSVG from "../../spinner.svg";

// npm install --save ag-grid-community ag-grid-react

import { AgGridReact } from "ag-grid-react";

import SwiperCore, {
  A11y,
  Autoplay,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/scrollbar";
import { useStateValue } from "../../StateProvider";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay, Mousewheel]);

const getParams = (fileName) => {
  return {
    allColumns: true,
    fileName: fileName + " Overview"
  };
};

// import "ag-grid-community/dist/styles/ag-grid.css";
// import "ag-grid-community/dist/styles/ag-theme-alpine.css";
// import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";

function OverviewEvent() {
  const [{ user }, dispatch] = useStateValue();
  const [roles, setRole] = useState("");

  const eventId = useParams();
  const db = getFirestore();
  const eventRef = doc(collection(db, "events"), eventId?.eventId);

  const history = useNavigate();

  const [event, setEvent] = useState();

  const [paymentsList, setPaymentsList] = useState([]);

  const gridRef = useRef();

  const [rowData, setRowData] = useState();

  const [model, setModel] = useState(false);

  const [selectedPayment, setSelectedPayment] = useState("");
  const [processing, setProcessing] = useState(true);
  // const row_data = [
  //   {
  //     id: 1,
  //     firstName: "Vaibhav",
  //     lastName: "Patel",
  //     email: "vaibhav.patel2305@gmail.com",
  //     ticketType: "Regular Ticket",
  //   },
  //   {
  //     id: 2,
  //     firstName: "Keyur",
  //     lastName: "Patel",
  //     email: "vaibhav.patel2305@gmail.com",
  //     ticketType: "Regular Ticket",
  //   },
  // ];

  const [columnDefs] = useState([
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      width: 150,
      // checkboxSelection: true,
      wrapText: true,
    },
    {
      field: "paymentId",
      headerName: "Payment Id",
      sortable: true,
      hide: true,
      width: 300,
    },
    {
      field: "firstName",
      headerName: "First Name",
      sortable: true,
      width: 300,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      sortable: true,
      width: 300,
    },
    {
      field: "email",
      headerName: "Email Address",
      sortable: true,
      wrapText: true,
      width: 340,
    },
    {
      field: "ticketType",
      headerName: "Ticket Type",
      sortable: true,
      width: 300,
    },
  ]);

  useEffect(() => {
    if (user) {
      const userCollectionRef = doc(collection(db, "users"), user?.uid);
      const unsubscribe = onSnapshot(userCollectionRef, (doc) => {
        setRole(doc.data()?.role);
      });
      return () => {
        unsubscribe();
      }
    }
  }, [user]);

  const paymentsRef = collection(
    db,
    "events",
    eventId?.eventId,
    "Payment Confirm"
  );

  useEffect(() => {
    const unsubscribe = onSnapshot(eventRef, (event) => {
      setEvent(event.data());
    });

    const unsubscribe1 = onSnapshot(paymentsRef, (payments) => {
      setPaymentsList(
        payments.docs.map((payment) => ({
          id: payment.id,
          data: payment.data(),
        }))
      );
    });

    return () => {
      unsubscribe();
      unsubscribe1();
    };
  }, []);


  useEffect(() => {
    let tempData = [];
    if (paymentsList) {
      paymentsList.map((value, index) => {
        if (Object.keys(value?.data?.regularUserInfo).length > 0) {
          Object.keys(value?.data?.regularUserInfo).map((key) => {
            tempData.push({
              id: index + 1,
              paymentId: value?.id,
              firstName: value?.data?.regularUserInfo[key]?.name,
              lastName: value?.data?.regularUserInfo[key]?.last_name,
              email: value?.data?.regularUserInfo[key]?.email,
              ticketType: "Individual Ticket",
            });
          });
        }
        if (Object.keys(value?.data?.earlyUserInfo).length > 0) {
          Object.keys(value?.data?.earlyUserInfo).map((key) => {
            tempData.push({
              id: index + 1,
              paymentId: value?.id,
              firstName: value?.data?.earlyUserInfo[key]?.name,
              lastName: value?.data?.earlyUserInfo[key]?.last_name,
              email: value?.data?.earlyUserInfo[key]?.email,
              ticketType: "Early Bird Ticket",
            });
          });
        }
      });
    }

    setRowData(tempData);
  }, [paymentsList]);

  const onGridReady = useCallback((params) => {
    if (window.innerWidth >= 1170) {
      sizeToFit();
    }
  }, []);

  const sizeToFit = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const autoSizeAll = useCallback(() => {
    const allColumnIds = [];
    gridRef.current.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById("filter-text-box")?.value
    );
  }, []);

  const onCellClicked = (params) => {
    // console.log(params?.data?.paymentId);
    setSelectedPayment(params?.data?.paymentId);
    setModel(true);
  };

  function onBtnExport() {
    gridRef.current.api.exportDataAsCsv(getParams(event?.eventName));
  }

  useEffect(() => {
    // console.log("Event: ", event);
    // console.log("Payment List: ", paymentsList);
    if (event && paymentsList) {
      setProcessing(false);
    }

  }, [event, paymentsList])

  useEffect(() => {
    window.scroll(0, 0);
    document.title = `Overview Event - Dashboard`;
  }, []);

  useEffect(() => {
    if (event?.eventName) {
      document.title = `${event?.eventName} - Overview`
    }
  }, [event]);

  return (
    <>
      {processing && (
        <div className="loading_container_payment">
          {/* <img src="/Images/Event/spinner.svg" alt = "Loading..." /> */}
          <img src={SpinnerSVG} alt="Loading..." id="spinner" />
        </div>
      )}
      <div className="OverviewEvent">
        {model && (
          <OverviewModel
            paymentId={selectedPayment}
            setModel={setModel}
            eventId={eventId?.eventId}
          />
        )}
        <div className="overview_wrapper">
          <h2 id="eventTitle">{event?.eventName}</h2>

          <div className="overviewButtonContainer">
            <Button on onClick={(e) => history("/dashboard")}>
              <span id="backIcon">
                <img src="/Images/Dashboard/backIcon.png" alt="" />
                <h3>Dashboard</h3>
              </span>
            </Button>
            <div>
              <Button onClick={(e) => history(`/check-in/event/${eventId?.eventId}`)}>
                Check In
              </Button>
              {roles !== "Exec Team" && (
                <Button onClick={(e) => history(`/edit/event/${eventId?.eventId}`)}>
                  Edit Event
                </Button>
              )}
            </div>
          </div>

          <hr />
          {!processing && (
            <>
              <div className="overviewEvent_body">
                <div className="overviewEvent_container" data-aos="fade-left" data-duration="700">
                  {roles === "Admin" && (
                    <Swiper
                      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                      breakpoints={{
                        1680: {
                          slidesPerView: 2,
                        },
                        650: {
                          slidesPerView: 2,
                        },

                      }}
                      grabCursor={true}
                      direction={"horizontal"}
                      pagination={{ clickable: true }}
                      className="overviewEvent_body_header"
                    >
                      <SwiperSlide className="slide">
                        <CardElement
                          totalSold
                          totalTicket={event?.totalTicket}
                          RegularTickets={event?.RegularTicketsAvailable}
                          RegularTicketWaitingList={event?.RegularTicketWaitingList}
                          EarlyBirdTicket={event?.EarlyBirdTicketsAvailable}
                          EarlyBirdTicketWaitingList={event?.EarlyBirdTicketWaitingList}
                        />
                      </SwiperSlide>
                      <SwiperSlide className="slide">
                        <CardElement overallSale grossSales={event?.grossSales} />
                      </SwiperSlide>
                    </Swiper>
                  )}

                  {roles !== "Admin" && (
                    <Swiper
                      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                      breakpoints={{
                        1680: {
                          slidesPerView: 1,
                        },
                        650: {
                          slidesPerView: 1,
                        },

                      }}
                      grabCursor={true}
                      direction={"horizontal"}
                      pagination={{ clickable: true }}
                      className="overviewEvent_body_header"
                    >
                      <SwiperSlide className="slide">
                        <CardElement
                          totalSold
                          totalTicket={event?.totalTicket}
                          RegularTickets={event?.RegularTicketsAvailable}
                          RegularTicketWaitingList={event?.RegularTicketWaitingList}
                          EarlyBirdTicket={event?.EarlyBirdTicketsAvailable}
                          EarlyBirdTicketWaitingList={event?.EarlyBirdTicketWaitingList}
                        />
                      </SwiperSlide>
                    </Swiper>
                  )}
                </div>

                <div className="overviewEvent_container" data-aos="fade-left" data-aos-duration="700" data-aos-easing="ease">
                  <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    breakpoints={{
                      1680: {
                        slidesPerView: 2,
                      },
                      650: {
                        slidesPerView: 2,
                      },

                    }}
                    grabCursor={true}
                    // mousewheel = {true}
                    direction={"horizontal"}
                    pagination={{ clickable: true }}
                    className="overviewEvent_body_header"
                  >
                    <SwiperSlide className="slide">
                      <CardElement
                        regularTicketCard
                        RegularTickets={event?.RegularTicketsAvailable}
                        RegularTicketWaitingList={event?.RegularTicketWaitingList}
                        totalRegular={event?.totalRegular}
                      />
                    </SwiperSlide>
                    <SwiperSlide className="slide">
                      <CardElement
                        earlyBirdTicketCard
                        EarlyBirdTicket={event?.EarlyBirdTicketsAvailable}
                        EarlyBirdTicketWaitingList={event?.EarlyBirdTicketWaitingList}
                        totalEarlyBird={event?.totalEarlyBird}
                      />
                    </SwiperSlide>
                  </Swiper>
                </div>

                <div className="overviewEvent_body_body" data-aos="fade-down" data-aos-duration="500">
                  {/* <div className="searchWrapper">
                    <span>
                      <img
                        className="search_logo"
                        src="/Images/Dashboard/search.png"
                        alt="search"
                      />
                      <input
                        type="text"
                        id="filter-text-box"
                        placeholder="Name, Email, Payment Confirmation..."
                        onInput={onFilterTextBoxChanged}
                        className="search_container"
                      ></input>
                    </span>
                    {roles === "Admin" && (
                      <Button onClick={() => { onBtnExport() }}>Export CSV</Button>
                    )}
                  </div> */}
                  <div className="search">
                    <span className="searchWrapper" id="searchContainer">
                      <img
                        className="search_logo"
                        id="checkInSearchLogo"
                        src="/Images/Dashboard/search.png" alt="search" />
                      <input
                        type="search"
                        id="filter-text-box"
                        placeholder="Name, Email, Payment Confirmation..."
                        onInput={onFilterTextBoxChanged}
                        className="search_container"
                      ></input>
                    </span>
                    <span>
                      {roles === "Admin" && (
                        <Button className="exportButton" onClick={() => { onBtnExport() }}>Export As CSV</Button>
                      )}
                    </span>
                  </div>
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      ref={gridRef}
                      rowData={rowData}
                      columnDefs={columnDefs}
                      onGridReady={onGridReady}
                      suppressAggFuncInHeader={true}
                      animateRows={true}
                      groupDefaultExpanded={1}
                      cacheQuickFilter={true}
                      onCellClicked={onCellClicked}
                      suppressScrollOnNewData={true}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

/**
 * @Title CardElement
 *
 * @Description Card element for the event overview page.
 *
 * @param
 * For each ticket:
 *    - Waiting list
 *    - Bought tickets
 * Attending
 * Gross Sales
 * Image src
 */
function CardElement(props) {
  // console.log("Props: ", props);
  return (
    <div className="cardElement">
      {props?.totalSold && (
        <>
          <img src="/Images/Dashboard/done.png" id="card_icon" />
          <p id="number_attendees_other">
            {props?.totalTicket -
              Math.abs(
                props?.RegularTickets +
                props?.EarlyBirdTicket
              )}
          </p>
          <p id="attendees_tag">Attending</p>
        </>
      )}

      {props?.overallSale && (
        <>
          <img src="/Images/Dashboard/money.png" id="card_icon" />
          <p id="number_attendees_other">${props?.grossSales?.toFixed(2)}</p>
          <p id="attendees_tag">Gross Sales</p>
        </>
      )}

      {props?.regularTicketCard && (
        <>
          <h3>Regular Tickets</h3>
          <p id="number_attendees">
            {props?.totalRegular -
              props?.RegularTickets}
          </p>
          <p id="attendees_tag">Attendees</p>
          <p id="second_line">
            <span id="number_emphasize">
              {props?.RegularTickets}
            </span>{" "}
            still available
          </p>
          <p id="second_line">
            <span id="number_emphasize">
              {props?.RegularTicketWaitingList}
            </span>{" "}
            in process
          </p>
        </>
      )}

      {props?.earlyBirdTicketCard && (
        <>
          <h3>Early Bird Tickets</h3>
          <p id="number_attendees">
            {props?.totalEarlyBird -
              props?.EarlyBirdTicket}
          </p>
          <p id="attendees_tag">Attendees</p>
          <p id="second_line">
            <span id="number_emphasize">
              {props?.EarlyBirdTicket}
            </span>{" "}
            still available
          </p>
          <p id="second_line">
            <span id="number_emphasize">
              {props?.EarlyBirdTicketWaitingList}
            </span>{" "}
            in process
          </p>
        </>
      )}
    </div>
  );
}

export default OverviewEvent;
