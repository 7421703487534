import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { isCompositeComponent } from "react-dom/test-utils";
import { useNavigate } from "react-router-dom";
import "./AddingEvent.css";
import RequiredInput from "../../Component/RequiredInput";
import {
  getFirestore,
  collection,
  onSnapshot,
  doc,
  getDocs,
  updateDoc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";

import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { storage } from "../../firebase";
import { Alert, AlertTitle } from "@mui/material";
import SpinnerSVG from "../../spinner.svg";
import inputErrorMessage from "../../Component/InputErrorMessage";
import ConfirmationModal from "../../Component/ConfirmationModal";

/**
 * @params
 *  - EarlyBirdTicket
 *  - RegularTickets
 *
 *  - EventName
 *  - EventAddress
 *  - EventLocation
 *  - EventDate
 *  - EventTime
 *  - EventDescription
 *  - Event Status
 *
 *  - TicketsStatus
 *
 *  - EventDressCode
 *  - EventImgUrl
 *
 *  - SponsorsField
 *  - SponsorsImgUrl
 *  - SponsorsName
 *  - SponsorsOtherInfo
 *  - SponsorsPhoneNumber
 *
 *  - TimelineActivity
 *  - TimelineActivityTime
 *
 *  - TotalTicket
 */

function AddingEvent() {
  const [earlyBirdTicket, setEarlyBirdTicket] = useState(0);
  const [regularTickets, setRegularTickets] = useState(0);

  const [earlyBirdPrice, setEarlyBirdPrice] = useState(0);
  const [regularPrice, setRegularPrice] = useState(0);

  const [eventName, setEventName] = useState("");
  const [eventAddress, setEventAddress] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [previewImageState, setPreviewImageState] = useState([]);

  const [eventStatus, setEventStatus] = useState("Unpublished"); // Current, previous, unpublished
  const [ticketsStatus, setTicketsStatus] = useState(true); // True or False

  const [comingSoon, setComingSoon] = useState(true); // Setting it to true since this will be only changed by admin upon unpublished
  const [earlyTeamRelease, setEarlyTeamRelease] = useState(false); // Setting it to false since this will be only changed by admin upon unpublished

  const [eventDressCode, setEventDressCode] = useState("");

  const [eventImgUrl, setEventImgUrl] = useState("");
  const [eventImg, setEventImg] = useState();

  const [timelineIndex, setTimelineIndex] = useState(1);
  const [timelineActivity, setTimelineActivity] = useState({});

  const [sponsorIndex, setSponsorIndex] = useState(1);
  const [sponsor, setSponsor] = useState({});

  const [processing, setProcessing] = useState(false);
  const [dbSubmit, setDbSubmit] = useState(false);

  const [tempCount, setTempCount] = useState(-1);
  const [confirmationModel, setConfirmationModel] = useState(false);
  const [confirmed, setConfirmed] = useState(false);


  const db = getFirestore();

  const history = useNavigate();

  const addingTimelineListener = (e) => {
    setTimelineIndex((timelineIndex) => timelineIndex + 1);
  };

  const addingSponsorListener = (e) => {
    setSponsorIndex((sponsorIndex) => sponsorIndex + 1);
  };

  const removeTimelineListener = (e) => {
    var id = e?.target?.id;
    // console.log(id);

    // if (id !== "timeLine0") {
    delete timelineActivity[id.substring(8)];
    const element = document.getElementById(e?.target?.id);
    element.remove();
    // }
  };

  const timelineChange = (e) => {
    var id = e?.target?.id.split(" ");

    setTimelineActivity({
      ...timelineActivity,
      [id[1]]: {
        ...timelineActivity[id[1]],
        [id[0]]: e?.target?.value,
      },
    });
  };

  const sponsorChange = (e) => {
    // (###) ###-####
    var id = e?.target?.id.split(" ");
    if (e?.target?.value !== "") {
      setSponsor({
        ...sponsor,
        [id[1]]: {
          ...sponsor[id[1]],
          [id[0]]: e?.target?.value,
        },
      });
    }
  };

  const sponsorsImg = (e) => {
    var id = e?.target?.id.split(" ");
    if (e?.target?.value !== "") {
      setSponsor({
        ...sponsor,
        [id[1]]: {
          ...sponsor[id[1]],
          [id[0]]: e?.target?.files[0],
          "preview": URL.createObjectURL(e?.target?.files[0]),
        },
      });
    }
  };

  const removeSponsorListener = (e) => {
    var id = e?.target?.id;
    // console.log(id);

    // if (id !== "sponsor0") {
    delete sponsor[id.substring(7)];
    const element = document.getElementById(e?.target?.id);
    element.remove();
    // }
  };

  const imageChange = (e) => {
    // console.log(e);
    document.getElementById("image-warning").classList.remove("error");
    setEventImg(e.target.files[0]);
    setPreviewImageState(URL.createObjectURL(e.target.files[0]));

  };

  // useEffect(() => {
  //   console.log(timelineActivity);
  // }, [timelineActivity]);

  useEffect(() => {

    if (sponsorIndex > 0) {
      if (tempCount === Object.keys(sponsor)?.length) {
        setDbSubmit(true);
      }
    }
  }, [sponsor, tempCount]);

  useEffect(() => {
    window.scroll(0, 0);
    document.title = "Adding Event - Dashboard";
  }, []);

  const confirmHandler = (e) => {
    e.preventDefault();

    if (eventImg && !confirmed) {
      setConfirmationModel(true);
    }

    if (!eventImg && !confirmed) {
      document.getElementById("image-warning").classList.add("error");
      window.scroll(0, 0);
    }
  };

  useEffect(() => {
    if (confirmed) {
      submitEvent();
    }
  }, [confirmed]);

  const submitEvent = async () => {
    setTempCount(0);
    if (eventImg && confirmed) {
      setProcessing(true);
      let eventImgRef = ref(
        storage,
        `/Event/${eventName}/eventImg/${eventImg?.name}`
      );
      const uploadTask = uploadBytesResumable(eventImgRef, eventImg);

      await uploadTask.on(
        "state_changed",
        (snapshot) => { },
        (error) => {
          console.log("Upload error:", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            await setEventImgUrl(url);
          });
        }
      );

      if (sponsorIndex > 0) {
        Object.keys(sponsor).forEach(async (key, index) => {
          let sponsorImgRef = ref(
            storage,
            `/Event/${eventName}/Sponsors/${sponsor[key]?.name}`
          );
          let uploadSponsor = uploadBytesResumable(
            sponsorImgRef,
            sponsor[key]?.logo
          );
          await uploadSponsor.on(
            "state_changed",
            (snapshot) => { },
            (error) => {
              console.log("Upload error: ", error);
            },
            () => {
              getDownloadURL(uploadSponsor.snapshot.ref).then(async (url) => {
                await setSponsor((prevState) => ({
                  ...prevState,
                  [key]: {
                    ...prevState[key],
                    logoName: sponsor[key]?.logo?.name,
                    logo: url,
                  },
                }));
                setTempCount((tempCount) => tempCount + 1);
              });
            }
          );
        });
      }
    }
  };

  useEffect(() => {
    async function dbUpdate() {
      if (dbSubmit && eventImgUrl !== "") {
        // console.log("Submitting the sponsor as: ", sponsor);
        const collectionRef = doc(collection(db, "events"));

        // let earlyBirdDic = {
        //   price: parseInt(earlyBirdPrice),
        //   ticketsAvailable: parseInt(earlyBirdTicket),
        //   waitingList: parseInt(0),
        // };

        // let regularDic = {
        //   price: parseInt(regularPrice),
        //   ticketsAvailable: parseInt(regularTickets),
        //   waitingList: 0,
        // };

        await setDoc(collectionRef, {
          EarlyBirdTicketPrice: parseInt(earlyBirdPrice),
          EarlyBirdTicketsAvailable: parseInt(earlyBirdTicket),
          EarlyBirdTicketWaitingList: parseInt(0),

          // RegularTickets: regularDic,

          RegularTicketPrice: parseInt(regularPrice),
          RegularTicketsAvailable: parseInt(regularTickets),
          RegularTicketWaitingList: parseInt(0),

          eventAddress: eventAddress,
          comingSoon: comingSoon,
          earlyTeamRelease: earlyTeamRelease,
          eventDate: eventDate,
          eventDescription: eventDescription,
          eventDressCode: eventDressCode,
          eventImgUrl: eventImgUrl,
          eventImgName: eventImg?.name,
          eventLocation: eventLocation,
          eventName: eventName,
          eventStatus: eventStatus,
          eventTime: eventTime,
          timelineActivity: timelineActivity,
          sponsor: sponsor,
          ticketsStatus: ticketsStatus,
          totalTicket: parseInt(earlyBirdTicket) + parseInt(regularTickets),
          totalEarlyBird: parseInt(earlyBirdTicket),
          totalRegular: parseInt(regularTickets),
          grossSales: 0,
        });
        history("/dashboard");
      }
    }
    dbUpdate();
  }, [dbSubmit, sponsor, eventImgUrl]);


  const formValidation = () => {
    /** Required Form Information
     *  - Event Image
     *  - Name of the event
     *  - Dress Code
     *  - Location Name
     *  - Event Address
     *  - Event Description
     *  - Number of regular ticket
     *  - Value for the regular ticket
     *  - Date of the event
     *  - Time for the event
     * 
     *  - If timeline activity is filled and not blank:
     *    - Activity
     *  - if sponsor is filled and not blank:
     *    - Name
     *    - Sponsor Image
     * 
     * Optional Information
     *  - Timeline
     *  - Sponsor
     *  
    */
    // console.log("Form Validation Initiated");
    let result = true

    if (!eventImg || eventName === "") {
      document.getElementById("image-warning").style.display = "flex";
    }

    // if (eventImg && eventName !== "" && eventLocation !== "" && eventAddress !== "" && eventDescription !== "" && regularTickets !== 0 && eventDate !== "" && eventTime !== "") {
    //   result = true;
    // }

    return result
  }


  return (
    <>
      {processing && (
        <div className="loading_container_payment">
          <img src={SpinnerSVG} alt="Loading..." id="spinner" />
        </div>
      )}

      {confirmationModel && (
        <ConfirmationModal setConfirmationModel={setConfirmationModel} setConfirmed={setConfirmed} />
      )}

      <div className="AddingEvent">
        <Button onClick={(e) => history("/dashboard")}>
          <span id="backIcon">
            <img src="/Images/Dashboard/backIcon.png" alt="" />
            <h3>Dashboard</h3>
          </span>
        </Button>

        <hr />

        <form className="formsContainer" onSubmit={confirmHandler}>
          <div className="eventContainer" data-aos="fade-left">
            <h3>Event Image</h3>
            <div className="informationContainer">
              <div className="eventWrapper">
                <div id="formInfoMedia">
                  <label className="custom-file-upload">
                    <input
                      type="file"
                      name="media"
                      accept="image/*"
                      onChange={imageChange}
                    />
                    <p className="text-grey">Click to upload</p>
                  </label>
                </div>
              </div>
              <Alert severity="error" className="image-warning" id="image-warning">Please provide a event image!</Alert>
              {eventImg &&
                <>
                  <img src={previewImageState} alt="Event Image" id="db_event_image" />
                  <p id="uploaded_text">Uploaded: {eventImg?.name}</p>
                </>
              }
            </div>
          </div>
          <div className="eventContainer" data-aos="fade-right">
            <h3>Event Information</h3>
            <div className="informationContainer">
              <div className="eventWrapper">
                <span>
                  <h4>Name of the Event:</h4>
                  <RequiredInput
                    inputType="input"
                    type="text"
                    currValue={eventName}
                    placeholder="Enter event name..."
                    onChangeVariable={setEventName}
                    pattern="(^[A-Za-z0-9.? ,_-]*)$"
                    errorMessage={inputErrorMessage["eventName"]}
                    required
                  />
                  {/* <Alert severity="error">Event name can only contain alphanumeric values.</Alert> */}
                </span>
                <span>
                  <h4>Dress Code:</h4>
                  <RequiredInput
                    inputType="input"
                    type="text"
                    currValue={eventDressCode}
                    placeholder="Enter dress code for the event..."
                    onChangeVariable={setEventDressCode}
                    pattern="(^[A-Za-z0-9- ,_!.]*)$"
                    errorMessage={inputErrorMessage["dressCode"]}
                    required
                  />
                </span>
              </div>
              <div className="eventWrapper">
                <span>
                  <h4>Location Name:</h4>
                  <RequiredInput
                    inputType="input"
                    type="text"
                    currValue={eventLocation}
                    placeholder="Please enter name of the location..."
                    onChangeVariable={setEventLocation}
                    pattern="(^[a-z- A-Z]*)$"
                    errorMessage={inputErrorMessage["locationName"]}
                    required
                  />
                </span>

                <span>
                  <h4>Event Address:</h4>
                  <RequiredInput
                    inputType="input"
                    type="text"
                    currValue={eventAddress}
                    placeholder="Please enter address of the event..."
                    onChangeVariable={setEventAddress}
                    pattern="(^[A-Za-z0-9 ,-]*)$"
                    errorMessage={inputErrorMessage["address"]}
                    required
                  />
                </span>
              </div>
              <div className="eventWrapper">
                <span>
                  <h4>Date:</h4>
                  <RequiredInput
                    inputType="date"
                    type="date"
                    currValue={eventDate}
                    placeholder=""
                    onChangeVariable={setEventDate}
                    pattern=""
                    errorMessage={inputErrorMessage["date"]}
                    required
                  />
                </span>
                <span>
                  <h4>Time:</h4>
                  <RequiredInput
                    inputType="input"
                    type="text"
                    currValue={eventTime}
                    placeholder="e.g. 8PM - 12AM"
                    onChangeVariable={setEventTime}
                    pattern="(^[0-9:]*(AM|PM) - [0-9:]*(AM|PM)){1}$"
                    errorMessage={inputErrorMessage["time"]}
                    required
                  />
                </span>
              </div>
              <div className="eventWrapper">
                <span>
                  <h4>Event Description:</h4>
                  <RequiredInput
                    inputType="textarea"
                    type="text"
                    currValue={eventDescription}
                    placeholder="Please enter the event description..."
                    onChangeVariable={setEventDescription}
                    pattern=""
                    errorMessage="Event description is a required field."
                    required
                  />
                </span>
              </div>
            </div>
          </div>

          {/* <div className="eventWrapper">
              <span>
                <h4>Date:</h4>
                <input
                  type="date"
                  value={eventDate}
                  onChange={(e) => setEventDate(e.target.value)}
                />
              </span>
              <span>
                <h4>Time:</h4>
                <input
                  type="text"
                  value={eventTime}
                  placeholder="e.g. 10AM - 12PM"
                  onChange={(e) => setEventTime(e.target.value)}
                />
              </span>
            </div> */}
          <div className="eventContainer" data-aos="fade-left">
            <h3>Ticket Information</h3>
            <div className="informationContainer">
              <div className="eventWrapper">
                <span>
                  <h4>Regular Ticket:</h4>
                  {/* <input
                    type="number"
                    value={regularTickets}
                    min="0"
                    placeholder="Number of regular tickets to sell..."
                    onChange={(e) => setRegularTickets(e.target?.value)}
                    pattern="^[1-9][0-9]*$"
                    required = "required"
                    /> */}
                  <RequiredInput
                    inputType="number"
                    type="number"
                    currValue={regularTickets}
                    onChangeVariable={setRegularTickets}
                    placeholder="e.g. 200"
                    pattern="^[1-9][0-9]*$"
                    errorMessage={inputErrorMessage["number"]}
                    required
                  />
                </span>
                <span>
                  <h4>Regular Ticket Price ($):</h4>
                  {/* <input
                    type="number"
                    value={regularPrice}
                    min="0"
                    placeholder="Number of regular tickets to sell..."
                    onChange={(e) => setRegularPrice(e.target?.value)}
                    pattern="^[1-9][0-9]*$"
                    required="required"
                  /> */}
                  <RequiredInput
                    inputType="number"
                    type="number"
                    currValue={regularPrice}
                    onChangeVariable={setRegularPrice}
                    placeholder="e.g. 25"
                    pattern="^[1-9][0-9]*$"
                    errorMessage={inputErrorMessage["number"]}
                    required
                  />
                </span>
              </div>
              <div className="eventWrapper">
                <span>
                  <h4>Early Bird Ticket:</h4>
                  {/* <input
                    type="number"
                    value={earlyBirdTicket}
                    placeholder="Number of early bird tickets to sell..."
                    min="0"
                    onChange={(e) => setEarlyBirdTicket(e.target?.value)}
                  /> */}
                  <RequiredInput
                    inputType="number"
                    type="number"
                    currValue={earlyBirdTicket}
                    onChangeVariable={setEarlyBirdTicket}
                    placeholder="e.g. 100"
                    pattern="^[1-9][0-9]*$"
                    errorMessage={inputErrorMessage["number"]}
                    required
                  />
                </span>
                <span>
                  <h4>Early Bird Ticket Price ($):</h4>
                  {/* <input
                    type="number"
                    value={earlyBirdPrice}
                    min="0"
                    placeholder="Number of regular tickets to sell..."
                    onChange={(e) => setEarlyBirdPrice(e.target?.value)}
                  /> */}
                  <RequiredInput
                    inputType="number"
                    type="number"
                    currValue={earlyBirdPrice}
                    onChangeVariable={setEarlyBirdPrice}
                    placeholder="e.g. 15"
                    pattern="^[1-9][0-9]*$"
                    errorMessage={inputErrorMessage["number"]}
                    required
                  />
                </span>
              </div>
            </div>
          </div>

          <div className="eventContainer">
            <div id="eventContainer_header" key="timeline_container_key" data-aos="fade-right">
              <h3>Timeline</h3>
              <Button onClick={addingTimelineListener}>
                <span id="additional_buttons">
                  <img src="/Images/Dashboard/add.png" id="addButton" alt="" />
                  <p>Add Activity</p>
                </span>
              </Button>
            </div>

            {Array.from({ length: timelineIndex }, (_, i) => (
              <div key={i} id={`timeLine${i}`}>
                <div className="timeline_eventWrapper" data-aos="fade-right">
                  <span>
                    <h4>Activity:</h4>
                    {/* <input
                      type="text"
                      // value={eventTime}
                      id={`activity ${i}`}
                      placeholder="eg. Doors open, Open dance floor, Dinner..."
                      onChange={timelineChange}
                      pattern="^[A-Z][a-z][A-Za-z ]*$"
                    />
                    <Alert severity="info" className="errorMessage">Activity name must only contain alphanumeric value.</Alert> */}
                    <input
                      type="text"
                      id={`activity ${i}`}
                      placeholder="eg. Doors open"
                      onChange={timelineChange}
                      pattern="(^[A-Za-z .()!]*)$"
                    />
                    <Alert severity="info" className="errorMessage">{inputErrorMessage["activityName"]}</Alert>
                  </span>
                  <span>
                    <h4>Time:</h4>
                    {/* <input
                      type="time"
                      id={`time ${i}`}
                      // value={eventTime}
                      placeholder="e.g. 10AM"
                      onChange={timelineChange}
                      pattern="(^[0-9]*(AM|PM)){1}$"
                    /> */}
                    <input
                      type="text"
                      id={`time ${i}`}
                      placeholder="e.g. 10AM"
                      onChange={timelineChange}
                      pattern="(^[0-9:]*(AM|PM)){1}$"
                    />
                    <Alert severity="info" className="errorMessage">{inputErrorMessage["singleTime"]}</Alert>
                  </span>
                  <img
                    src="/Images/Dashboard/minus.png"
                    id={`timeLine${i}`}
                    className="deleteIcon"
                    onClick={removeTimelineListener}
                    alt=""
                  />
                </div>

              </div>
            ))}
          </div>
          <div className="eventContainer" data-aos="fade-left">
            <div id="eventContainer_header">
              <h3>Sponsors</h3>
              <Button onClick={addingSponsorListener}>
                <span id="additional_buttons">
                  <img src="/Images/Dashboard/add.png" id="addButton" />
                  <p>Add Sponsor</p>
                </span>
              </Button>
            </div>
            {Array.from({ length: sponsorIndex }, (_, i) => (
              <div key={i + 1}>
                <div className="sponsor_eventWrapper" key={i} id={`sponsor${i}`} data-aos="fade-left">
                  <div className="sponsor_info_fields" key={`infoField ${i}`} id="ticketInformationAndSponsorContainer">
                    <div>
                      <span>
                        <h4>Name:</h4>
                        {/* <input
                          type="text"
                          id={`name ${i}`}
                          placeholder="Please enter name of the sponsor..."
                          onChange={sponsorChange}
                          pattern="^[A-Z][a-z][A-Za-z ]*$"
                        />
                        <Alert severity="info" className="errorMessage">Sponsor name must only contain letters.</Alert> */}
                        <input
                          type="text"
                          id={`name ${i}`}
                          placeholder="Please enter name of the sponsor..."
                          onChange={sponsorChange}
                          pattern="(^[A-Za-z .]*)$"
                        />
                        <Alert severity="info" className="errorMessage">{inputErrorMessage["sponsorName"]}</Alert>
                      </span>
                      <span>
                        <h4>Field:</h4>
                        {/* <input
                          type="text"
                          id={`field ${i}`}
                          placeholder="eg. Immigration advisor"
                          onChange={sponsorChange}
                          pattern="^[A-Z][a-z][A-Za-z ]*$"
                        />
                        <Alert severity="info" className="errorMessage">Field name must only contain letters.</Alert> */}
                        <input
                          type="text"
                          id={`field ${i}`}
                          placeholder="eg. Immigration advisor"
                          onChange={sponsorChange}
                          pattern="(^[A-Za-z .]*)$"
                        />
                        <Alert severity="info" className="errorMessage">{inputErrorMessage["sponsorField"]}</Alert>
                      </span>
                    </div>
                    <div>
                      <span>
                        <h4>Phone Number:</h4>
                        {/* <input
                          type="tel"
                          id={`phone ${i}`}
                          placeholder="eg. (###) ###-####"
                          onChange={sponsorChange}
                          pattern="(\([0-9]{3})\) [0-9]{3}-[0-9]{4}"
                        />
                        <Alert severity="info" className="errorMessage">Please provide phone number in a proper format. Eg. (###) ###-####</Alert> */}
                        <input
                          type="text"
                          id={`phone ${i}`}
                          placeholder="eg. 416-111-1212"
                          onChange={sponsorChange}
                          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        />
                        <Alert severity="info" className="errorMessage">{inputErrorMessage["phoneNumber"]}</Alert>
                      </span>
                      <span>
                        <h4>Other Info:</h4>
                        {/* <input
                          type="text"
                          id={`otherInfo ${i}`}
                          placeholder="eg. Email, Website, etc..."
                          onChange={sponsorChange}
                          pattern="(^[A-Za-z0-9 ,-@/:.]*)$"
                        /> */}
                        <input
                          type="text"
                          id={`otherInfo ${i}`}
                          placeholder="eg. Email, Website, etc..."
                          onChange={sponsorChange}
                          pattern="(^[A-Za-z0-9 ,-:/.@]*)$"
                        />
                        <Alert severity="info" className="errorMessage">{inputErrorMessage["otherFields"]}</Alert>
                      </span>
                    </div>
                    <img
                      src="/Images/Dashboard/minus.png"
                      className="deleteIcon"
                      id={`sponsor${i}`}
                      onClick={removeSponsorListener}
                      alt=""
                    />
                  </div>

                  <div id="formInfoMedia" key={`mediaContainer ${i}`}>
                    <label className="custom-file-upload">
                      <input
                        type="file"
                        name="media"
                        accept="image/*"
                        id={`logo ${i}`}
                        onChange={sponsorsImg}
                      />
                      <p className="text-grey">Click to upload</p>
                    </label>
                  </div>
                  {sponsor[i]?.logo && (
                    <>
                      <img src={sponsor[i]?.preview} alt="Event Image" id="db_sponsor_logo" />
                      <p id="uploaded_text">Uploaded: {sponsor[i]?.logo?.name}</p>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>

          <Button
            className="submitButton"
            // onClick={submitEvent}
            disabled={processing}
            type="submit"
          >
            <span>{processing ? <p>Processing...</p> : "Submit"}</span>
          </Button>
        </form >
      </div >
    </>
  );
}

export default AddingEvent;
