import React, { useEffect, useState } from "react";
import "./OverviewModel.css";
import {
  getFirestore,
  collection,
  onSnapshot,
  query,
  where,
  doc,
} from "firebase/firestore";
import { Button } from "@material-ui/core";
import { useStateValue } from "../../StateProvider";

function OverviewModel({ paymentId, setModel, eventId }) {
  const db = getFirestore();
  const [{ user }, dispatch] = useStateValue();
  const [roles, setRole] = useState("");

  const [paymentDetails, setPaymentDetails] = useState();
  const [selectedTab, setSelectedTab] = useState("Group Details");
  const [regularUserInfoSize, setRegularUserInfoSize] = useState(0);
  const [earlyUserInfoSize, setEarlyUserInfoSize] = useState(0);

  useEffect(() => {
    if (user) {
      const userCollectionRef = doc(collection(db, "users"), user?.uid);
      onSnapshot(userCollectionRef, (doc) => {
        setRole(doc.data()?.role);
      });
    }
  }, [user]);


  useEffect(() => {
    const paymentRef = doc(
      collection(db, "events", eventId, "Payment Confirm"),
      paymentId
    );
    onSnapshot(paymentRef, (snapshot) => {
      setPaymentDetails(snapshot.data());
    });
  }, [paymentId]);

  useEffect(() => {
    if (paymentDetails) {
      // console.log("Payment Details: ", paymentDetails);
      if (paymentDetails?.regularUserInfo) {
        setRegularUserInfoSize(Object.keys(paymentDetails?.regularUserInfo)?.length);
      }
      if (paymentDetails?.earlyUserInfo) {
        setEarlyUserInfoSize(Object.keys(paymentDetails?.earlyUserInfo)?.length);
      }

    }
  }, [paymentDetails]);

  const handleClose = () => {
    setModel(false);
  };

  const expandItem = (id) => {
    document.getElementById(id).classList.toggle("active");
  };

  const viewReceipt = (orderRef) => {
    window.open(orderRef);
  };

  return (
    <div className="OverviewModel">
      <div
        className="buyingModel_container"
        id="overviewModel_container"
        data-aos="zoom-in"
        data-aos-duration="500"
      >
        <div className="buyingModal_header">
          <img src="/Images/Login/close.png" onClick={handleClose} alt="" />
        </div>

        <div className="buyingModel_body">
          <div id="overview_billingName">
            <h3>
              {paymentDetails?.billing_name}
              {" - "}
            </h3>
            <p>
              Group of{" "}
              {getNumberOfGroups(
                paymentDetails?.regularUserInfo,
                paymentDetails?.earlyUserInfo
              )}
            </p>
          </div>
          <div id="date_andConfirmation">
            <div className="date_container">
              <img src="/Images/Dashboard/date.png" alt="" />
              <p>
                {getDateToTime(paymentDetails?.boughtTime?.toDate()?.toString())}
              </p>
            </div>

            <div className="confirmation_container">
              <img src="/Images/Dashboard/personSearch.png" alt="" />
              <p>Confirmation: {paymentDetails?.paymentID}</p>
            </div>


          </div>
          <div className="dashboard_body_header">
            <Button
              onClick={() => {
                setSelectedTab("Group Details");
              }}
              id={selectedTab === "Group Details" ? "selected1" : ""}
            >
              Group Details
            </Button>
            <Button
              onClick={() => {
                setSelectedTab("Payment");
              }}
              id={selectedTab === "Payment" ? "selected2" : ""}
            >
              Payment
            </Button>
          </div>
          <hr id={selectedTab} className="selected_tab" />
          <hr id="divider_line" />

          {selectedTab === "Group Details" && (
            <div className="groupDetailContainer">
              <div className="groupDetail_header">
                <div className="groupDetail_Card">
                  <img src="/Images/Dashboard/done.png" alt="" />
                  <h1>
                    {getNumberOfGroups(
                      paymentDetails?.regularUserInfo,
                      paymentDetails?.earlyUserInfo
                    )}
                  </h1>
                  <p>Attending</p>
                </div>
                <div className="groupDetail_Card">
                  <img src="/Images/Dashboard/money.png" alt="" />
                  <h1>{`$${paymentDetails?.totalTicketAmount?.toFixed(2)}`}</h1>
                  <p>Gross Sales</p>
                </div>
              </div>
              <hr id="divider_line" />
              <div className="groupDetail_body">
                <div className="groupDetail_body_header">
                  {/* {roles !== "Exec Team" && (
                    <Button>Edit</Button>
                  )} */}
                </div>
                <div className="groupDetail_body_wrapper">
                  {regularUserInfoSize > 0 && (
                    <div className="groupDetail_Card_attending">
                      {Object.keys(paymentDetails?.regularUserInfo)?.map(
                        (key) => {
                          return (
                            <div className="groupDetail_Card_attending_regularitem" id={`regularItemContainer${key}`} key={key}>
                              <img src="/Images/Dashboard/arrow.png" alt="" onClick={() => { expandItem(`regularItemContainer${key}`) }} />
                              <div className="card_attending_item_info" onClick={() => { expandItem(`regularItemContainer${key}`) }}>
                                <h3>{`${paymentDetails?.regularUserInfo[key].name} ${paymentDetails?.regularUserInfo[key].last_name}`}</h3>
                                <p>
                                  Individual Tickets
                                </p>
                                <div className="groupDetail_Card_attending_fullInfo">
                                  <hr />
                                  <h3>Email</h3>
                                  <p>{paymentDetails?.regularUserInfo[key].email}</p>
                                  <hr />
                                  <h3>Phone Number</h3>
                                  <p>{paymentDetails?.regularUserInfo[key].phone}</p>
                                  <hr />
                                  <h3>Terms and Conditions</h3>
                                  <p>Accepted</p>
                                  <hr />
                                  {paymentDetails?.regularUserInfo[key].vaccineProofUrl && (
                                    <>
                                      <h3>Proof of Vaccine</h3>
                                      <a target="_blank" href={paymentDetails?.regularUserInfo[key].vaccineProofUrl}>{`${paymentDetails?.regularUserInfo[key].name} ${paymentDetails?.regularUserInfo[key].last_name}`}</a>
                                      <hr />
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                  {earlyUserInfoSize > 0 && (
                    <div className="groupDetail_Card_attending">
                      {Object.keys(paymentDetails?.earlyUserInfo)?.map(
                        (key) => {
                          return (
                            <div className="groupDetail_Card_attending_earlyitem" id={`earlyItemContainer${key}`} key={key} >
                              <img src="/Images/Dashboard/arrow.png" alt="" onClick={() => { expandItem(`earlyItemContainer${key}`) }} />
                              <div className="card_attending_item_info" onClick={() => { expandItem(`earlyItemContainer${key}`) }}>
                                <h3>{`${paymentDetails?.earlyUserInfo[key].name} ${paymentDetails?.earlyUserInfo[key].last_name}`}</h3>
                                <p>
                                  Early Bird Tickets
                                </p>
                                <div className="groupDetail_Card_attending_fullInfo">
                                  <hr />
                                  {paymentDetails?.earlyUserInfo[key].email && (
                                    <>
                                      <h3>Email</h3>
                                      <p>{paymentDetails?.earlyUserInfo[key].email}</p>
                                      <hr />
                                    </>
                                  )}
                                  {paymentDetails?.earlyUserInfo[key].phone && (
                                    <>
                                      <h3>Phone Number</h3>
                                      <p>{paymentDetails?.earlyUserInfo[key].phone}</p>
                                      <hr />
                                    </>
                                  )}
                                  <h3>Terms and Conditions</h3>
                                  <p>Accepted</p>
                                  <hr />
                                </div>
                              </div>

                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {selectedTab === "Payment" && (
            <div className="paymentContainer">
              <div className="groupDetailContainer">
                <Button onClick={() => { viewReceipt(paymentDetails?.receipt_url) }}>View Receipt</Button>


                {paymentDetails?.regularTicketsBought > 0 && (
                  <div className="ticket_selection_container" id="ticket_review">
                    <img src="/Images/Event/tickets.png" alt="" />
                    <div className="ticket_name">
                      <h3>Individual Tickets</h3>
                      <h4>
                        {paymentDetails?.regularTicketsBought} x ${paymentDetails?.regularTicketAmount.toFixed(2)}
                      </h4>
                    </div>
                    <h3>${(paymentDetails?.regularTicketsBought * paymentDetails?.regularTicketAmount).toFixed(2)}</h3>
                  </div>
                )}

                {paymentDetails?.earlyBirdTicketsBought > 0 && (
                  <div className="ticket_selection_container" id="ticket_review">
                    <img src="/Images/Event/tickets.png" alt="" />
                    <div className="ticket_name">
                      <h3>Early Bird Tickets</h3>
                      <h4>
                        {paymentDetails?.earlyBirdTicketsBought} x ${paymentDetails?.earlyBirdTicketAmount.toFixed(2)}
                      </h4>
                    </div>
                    <h3>${(paymentDetails?.earlyBirdTicketsBought * paymentDetails?.earlyBirdTicketAmount).toFixed(2)}</h3>
                  </div>
                )}

                <hr />

                <div className="ticket_selection_container" id="ticket_review">
                  <div className="ticket_name">
                    <h3>Tax & Service Fees</h3>
                  </div>
                  <h3>$0.00</h3>
                </div>

                <hr />

                <div className="ticket_selection_container" id="ticket_review">
                  <div className="ticket_name">
                    <h3>Total</h3>
                  </div>
                  <h3>${paymentDetails?.totalTicketAmount.toFixed(2)}</h3>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function getDateToTime(time) {
  let returnDate = "";
  if (time) {
    // console.log("Time Passed: ", time);

    const fullDate = time.split(" ").slice(0, 4).join(" ");

    const hour = parseInt(
      time.split(" ").slice(4, 5).join(" ").split(":").slice(0, 1).join(" ")
    );

    const minutes = time
      .split(" ")
      .slice(4, 5)
      .join(" ")
      .split(":")
      .slice(1, 2);

    if (hour < 12) {
      if (hour === 0) {
        returnDate = `${fullDate} ${12}:${minutes} AM`;
      } else if (hour < 10) {
        returnDate = `${fullDate} 0${hour}:${minutes} AM`;
      } else {
        returnDate = `${fullDate} ${hour}:${minutes} AM`;
      }
    } else {
      if (hour === 12) {
        returnDate = `${fullDate} ${12}:${minutes} PM`;
      } else if (hour < 22) {
        returnDate = `${fullDate} 0${Math.abs(12 - hour)}:${minutes} PM`;
      } else {
        returnDate = `${fullDate} ${Math.abs(12 - hour)}:${minutes} PM`;
      }
    }
  }

  return returnDate;
}

function getNumberOfGroups(regularUserInfo, earlyUserInfo) {
  let returnNumber = 0;
  // console.log("Regular User Info: ", regularUserInfo);
  // console.log("Early User Info: ", earlyUserInfo);

  if (regularUserInfo) {
    returnNumber += Object.keys(regularUserInfo)?.length;
  }

  if (earlyUserInfo) {
    returnNumber += Object.keys(earlyUserInfo)?.length;
  }

  return returnNumber;
}

export default OverviewModel;
