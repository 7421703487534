import { Alert } from '@mui/material';
import React, { useState } from 'react';

function RequiredInput({ id = null, inputType, currValue, type, placeholder, onChangeVariable = null, pattern, errorMessage, required = false }) {
    const [focused, setFocused] = useState(false);

    const handleFocus = (e) => {
        if (currValue !== "") {
            setFocused(true);
        }
    }

    return (
        <>
            {inputType === "input" && (
                <input
                    id={id}
                    type={type}
                    value={currValue}
                    placeholder={placeholder}
                    onChange={(e) => onChangeVariable(e.target?.value)}
                    // pattern="^[A-Z][a-z][A-Za-z][a-z\sA-za-z]*$"
                    onBlur={handleFocus}
                    focused={focused.toString()}
                    pattern={pattern}
                    required={required} />
            )}
            {inputType === "textarea" && (
                <textarea
                    id={id}
                    rows="6"
                    cols="50"
                    placeholder={placeholder}
                    spellCheck="true"
                    maxLength="900"
                    onBlur={handleFocus}
                    focused={focused.toString()}
                    value={currValue}
                    onChange={(e) => onChangeVariable(e.target?.value)}
                    required={required}
                />
            )}
            {inputType === "date" && (
                <input
                    type={type}
                    value={currValue}
                    onBlur={handleFocus}
                    min="2020-01-01"
                    max="2030-12-31"
                    focused={focused.toString()}
                    onChange={(e) => onChangeVariable(e.target.value)}
                    required={required}
                />
            )}
            {inputType === "number" && (
                <input
                    type={type}
                    value={currValue}
                    min="0"
                    onChange={(e) => onChangeVariable(parseInt(e.target?.value))}
                    onBlur={handleFocus}
                    focused={focused.toString()}
                    placeholder={placeholder}
                    pattern={pattern}
                    required={required}
                />
            )}

            <Alert severity="error" className="errorMessage" id="orderError">{errorMessage}</Alert>
        </>
    )
}

export default RequiredInput;

