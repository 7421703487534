import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { GoogleAuthProvider } from "firebase/auth";
// import { getFirestore } from "firebase/firestore";
const { initializeFirestore } = require("firebase/firestore");
const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBleRL9HNiKTpvqus-3ZnPJB0uw_ALLAi0",
  authDomain: "ontario-gsa.firebaseapp.com",
  projectId: "ontario-gsa",
  storageBucket: "ontario-gsa.appspot.com",
  messagingSenderId: "911949236730",
  appId: "1:911949236730:web:cdca02cfd61bee9c117c43",
  measurementId: "G-R80WG75JGG",
};

const app = initializeApp(firebaseConfig);
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LcMFCYhAAAAAGIbQDzqlhQ279QD18QMeZvNK-Ci'),
  isTokenAutoRefreshEnabled: true
});
const analytics = getAnalytics(app);
const auth = getAuth(app);
// const db = getFirestore();
const db = initializeFirestore(app, { experimentalForceLongPolling: true });
const storage = getStorage(app);
const provider = new GoogleAuthProvider();

export { auth, storage, analytics, provider };
export default db;
