import React, { useEffect, useState } from "react";
import "./CheckInModel.css";
import {
  getFirestore,
  collection,
  onSnapshot,
  query,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";
import { Button } from "@material-ui/core";
import IncorrectQRCode from "../../Component/incorrectQRCode.svg";

function CheckInModel({ paymentId, setModel, eventId, fullName }) {
  const db = getFirestore();

  const [paymentDetails, setPaymentDetails] = useState();
  const [selectedTab, setSelectedTab] = useState("Check In");

  const [regularUserInfoSize, setRegularUserInfoSize] = useState(0);
  const [earlyUserInfoSize, setEarlyUserInfoSize] = useState(0);

  const [checkedOutCount, setCheckedOutCount] = useState(0);
  const [checkedInCount, setCheckedInCount] = useState(0);
  const [unseenCount, setUnseenCount] = useState(0);

  const [incorrectPaymentIntent, setIncorrectPaymentIntent] = useState(false);

  useEffect(() => {
    const paymentRef = doc(
      collection(db, "events", eventId, "Payment Confirm"),
      paymentId
    );
    onSnapshot(paymentRef, (snapshot) => {
      setPaymentDetails(snapshot.data());
    });
  }, [paymentId]);

  useEffect(() => {
    // console.log("Payment Details: ", paymentDetails);
    if (paymentDetails) {
      setIncorrectPaymentIntent(false);
      setCheckedOutCount(0);
      setCheckedInCount(0);
      setUnseenCount(0);
      if (paymentDetails?.regularUserInfo) {
        setRegularUserInfoSize(
          Object.keys(paymentDetails?.regularUserInfo)?.length
        );
      }
      if (paymentDetails?.earlyUserInfo) {
        setEarlyUserInfoSize(
          Object.keys(paymentDetails?.earlyUserInfo)?.length
        );
      }

      // Highlight unseen, checked in or checked out button
      if (paymentDetails?.regularUserInfo) {
        for (let userId in paymentDetails?.regularUserInfo) {
          if (
            paymentDetails?.regularUserInfo[userId]?.seenStatus === "Unseen"
          ) {
            setUnseenCount((unseenCount) => unseenCount + 1);
          }
          if (
            paymentDetails?.regularUserInfo[userId]?.seenStatus === "Checked In"
          ) {
            setCheckedInCount((checkedInCount) => checkedInCount + 1);
          }
          if (
            paymentDetails?.regularUserInfo[userId]?.seenStatus ===
            "Checked Out"
          ) {
            setCheckedOutCount((checkedOutCount) => checkedOutCount + 1);
          }
        }
      }
      if (paymentDetails?.earlyUserInfo) {
        for (let key in paymentDetails?.earlyUserInfo) {
          // console.log("Seen Status: ", paymentDetails?.earlyUserInfo[key]?.seenStatus);
          if (paymentDetails?.earlyUserInfo[key]?.seenStatus === "Unseen") {
            setUnseenCount((unseenCount) => unseenCount + 1);
          }
          if (paymentDetails?.earlyUserInfo[key]?.seenStatus === "Checked In") {
            setCheckedInCount((checkedInCount) => checkedInCount + 1);
          }
          if (
            paymentDetails?.earlyUserInfo[key]?.seenStatus === "Checked Out"
          ) {
            setCheckedOutCount((checkedOutCount) => checkedOutCount + 1);
          }
        }
      }
    } else {
      // If the payment intent is not assigned to this event or is not correct.
      setIncorrectPaymentIntent(true);
    }
  }, [paymentDetails]);

  const handleClose = () => {
    setModel(false);
  };

  const expandItem = (id) => {
    document.getElementById(id).classList.toggle("active");
  };

  const viewReceipt = (orderRef) => {
    window.open(orderRef);
  };

  const handleIndividualCheckIn = (user, key, ticketType, statusChange) => {
    let tempRef = doc(db, "events", eventId, "Payment Confirm", paymentId);

    if (ticketType === "Early Bird") {
      updateDoc(tempRef, {
        ["earlyUserInfo." + key]: {
          name: user?.name,
          last_name: user?.last_name,
          phone: user?.phone,
          email: user?.email,
          seenStatus: statusChange,
        },
      })
        .then(() => {})
        .catch((error) => {
          console.log("Error: ", error);
        });
    } else {
      updateDoc(tempRef, {
        ["regularUserInfo." + key]: {
          name: user?.name,
          last_name: user?.last_name,
          phone: user?.phone,
          email: user?.email,
          seenStatus: statusChange,
        },
      })
        .then(() => {})
        .catch((error) => {
          console.log("Error: ", error);
        });
    }
  };

  const handleGroupCheckIn = (statusChange) => {
    let tempRef = doc(db, "events", eventId, "Payment Confirm", paymentId);
    // console.log("Status Change: ", statusChange);
    // console.log("Payment Details: ", paymentDetails);

    if (paymentDetails?.regularUserInfo) {
      for (
        let i = 0;
        i < Object.keys(paymentDetails?.regularUserInfo)?.length;
        i++
      ) {
        updateDoc(tempRef, {
          ["regularUserInfo." + i]: {
            name: paymentDetails?.regularUserInfo[i]?.name,
            last_name: paymentDetails?.regularUserInfo[i]?.last_name,
            phone: paymentDetails?.regularUserInfo[i]?.phone,
            email: paymentDetails?.regularUserInfo[i]?.email,
            seenStatus: statusChange,
          },
        })
          .then(() => {})
          .catch((error) => {
            console.log("Error: ", error);
          });
      }
    }

    if (paymentDetails?.earlyUserInfo) {
      for (
        let i = 0;
        i < Object.keys(paymentDetails?.earlyUserInfo)?.length;
        i++
      ) {
        updateDoc(tempRef, {
          ["earlyUserInfo." + i]: {
            name: paymentDetails?.earlyUserInfo[i]?.name,
            last_name: paymentDetails?.earlyUserInfo[i]?.last_name,
            phone: paymentDetails?.earlyUserInfo[i]?.phone,
            email: paymentDetails?.earlyUserInfo[i]?.email,
            seenStatus: statusChange,
          },
        })
          .then(() => {})
          .catch((error) => {
            console.log("Error: ", error);
          });
      }
    }
  };

  useEffect(() => {
    try {
      if (checkedInCount > 0) {
        document.getElementById("checkedInButton").classList.add("active");
        document.getElementById("checkedOutButton").classList.remove("active");
        document.getElementById("unseenButton").classList.remove("active");
      } else if (checkedInCount === 0 && checkedOutCount > 0) {
        document.getElementById("checkedOutButton").classList.add("active");
        document.getElementById("checkedInButton").classList.remove("active");
        document.getElementById("unseenButton").classList.remove("active");
      } else {
        document.getElementById("unseenButton").classList.add("active");
        document.getElementById("checkedOutButton").classList.remove("active");
        document.getElementById("checkedInButton").classList.remove("active");
      }
    } catch (e) {}
  }, [checkedInCount, checkedOutCount, unseenCount, selectedTab]);

  return (
    <div className="CheckInModel">
      {!incorrectPaymentIntent ? (
        <div
          className="buyingModel_container"
          id="checkIn_container"
          data-aos="zoom-in"
          data-aos-duration="500"
        >
          <div className="buyingModal_header">
            <img src="/Images/Login/close.png" onClick={handleClose} alt="" />
          </div>

          <div className="buyingModel_body">
            <div id="overview_billingName">
              <h3>
                {fullName === "" ? paymentDetails?.billing_name : fullName}
                {" - "}
              </h3>
              <p>
                Group of{" "}
                {getNumberOfGroups(
                  paymentDetails?.regularUserInfo,
                  paymentDetails?.earlyUserInfo
                )}
              </p>
            </div>
            <div id="date_andConfirmation">
              <div className="date_container">
                <img src="/Images/Dashboard/date.png" alt="" />
                <p>
                  {getDateToTime(
                    paymentDetails?.boughtTime?.toDate()?.toString()
                  )}
                </p>
              </div>

              <div className="confirmation_container">
                <img src="/Images/Dashboard/personSearch.png" alt="" />
                <p>Confirmation: {paymentDetails?.paymentID}</p>
              </div>
            </div>
            <div className="dashboard_body_header">
              <Button
                onClick={() => {
                  setSelectedTab("Check In");
                }}
                id={selectedTab === "Check In" ? "selected1" : ""}
              >
                Check In
              </Button>
              <Button
                onClick={() => {
                  setSelectedTab("Payment CheckIn");
                }}
                id={selectedTab === "Payment CheckIn" ? "selected2" : ""}
              >
                Payment
              </Button>
            </div>
            <hr id={selectedTab} className="selected_tab" />
            <hr id="divider_line" />

            {/* Display all group action to set Unseen, Checked In, Checked Out */}
            {selectedTab === "Check In" && (
              <div className="groupDetailContainer">
                {/* <h4 id="group_action_title">Group Action</h4> */}
                <div className="groupDetail_header" id="groupCheckIn_header">
                  <div
                    className="groupDetail_Card"
                    id="groupCheckIn_UnseenCard"
                  >
                    <Button
                      id="unseenButton"
                      className="unseenButton"
                      onClick={() => {
                        handleGroupCheckIn("Unseen");
                      }}
                    >
                      <img
                        src="/Images/Dashboard/personSearch.png"
                        alt=""
                        id="person_icon"
                      />
                      <p>Unseen</p>
                    </Button>
                  </div>
                  <div
                    className="groupDetail_Card"
                    id="groupCheckIn_CheckedInCard"
                  >
                    <Button
                      id="checkedInButton"
                      className="checkedInButton"
                      onClick={() => {
                        handleGroupCheckIn("Checked In");
                      }}
                    >
                      <img
                        src="/Images/Dashboard/done.png"
                        alt=""
                        id="person_icon"
                      />
                      <p>Checked In</p>
                    </Button>
                  </div>
                  <div
                    className="groupDetail_Card"
                    id="groupCheckIn_CheckedOutCard"
                  >
                    <Button
                      id="checkedOutButton"
                      className="checkedOutButton"
                      onClick={() => {
                        handleGroupCheckIn("Checked Out");
                      }}
                    >
                      <img
                        src="/Images/Dashboard/personCross.png"
                        alt=""
                        id="person_icon"
                      />
                      <p>Checked Out</p>
                    </Button>
                  </div>
                </div>
                <hr id="divider_line" />
                <div className="groupDetail_body">
                  <div className="groupDetail_body_wrapper">
                    {regularUserInfoSize > 0 && (
                      <div className="groupDetail_Card_attending">
                        {Object.keys(paymentDetails?.regularUserInfo)?.map(
                          (key) => {
                            return (
                              <div
                                className="checkIn_Card_attending_regularitem"
                                id={`regularItemContainer${key}`}
                                key={key}
                              >
                                <img
                                  src="/Images/Dashboard/arrow.png"
                                  alt=""
                                  onClick={() => {
                                    expandItem(`regularItemContainer${key}`);
                                  }}
                                />
                                <div
                                  className="card_attending_item_info"
                                  onClick={() => {
                                    expandItem(`regularItemContainer${key}`);
                                  }}
                                  id="checkIn_Card_attending_info"
                                >
                                  <h3>{`${paymentDetails?.regularUserInfo[key].name} ${paymentDetails?.regularUserInfo[key].last_name}`}</h3>
                                  <p>Individual Tickets</p>
                                  <div className="groupDetail_Card_attending_fullInfo">
                                    <hr />
                                    {paymentDetails?.regularUserInfo[key]
                                      .seenStatus && (
                                      <>
                                        <h3>Status</h3>
                                        <p>
                                          {
                                            paymentDetails?.regularUserInfo[key]
                                              .seenStatus
                                          }
                                        </p>
                                        <hr />
                                      </>
                                    )}
                                    {paymentDetails?.regularUserInfo[key]
                                      .email && (
                                      <>
                                        <h3>Email</h3>
                                        <p>
                                          {
                                            paymentDetails?.regularUserInfo[key]
                                              .email
                                          }
                                        </p>
                                        <hr />
                                      </>
                                    )}
                                    {paymentDetails?.regularUserInfo[key]
                                      .phone && (
                                      <>
                                        <h3>Phone Number</h3>
                                        <p>
                                          {
                                            paymentDetails?.regularUserInfo[key]
                                              .phone
                                          }
                                        </p>
                                        <hr />
                                      </>
                                    )}
                                    <h3>Terms and Conditions</h3>
                                    <p>Accepted</p>
                                    <hr />
                                  </div>
                                </div>
                                <div
                                  className="checkIn_cardActionContainer"
                                  id={`regularItemContainer${key}`}
                                >
                                  <div
                                    className="groupDetail_Card"
                                    id="groupCheckIn_UnseenBtn"
                                  >
                                    <Button
                                      className="individualCheckInButton"
                                      id={
                                        paymentDetails?.regularUserInfo[key]
                                          ?.seenStatus === "Unseen"
                                          ? "BtnUnseenStatus active"
                                          : "BtnUnseenStatus"
                                      }
                                      onClick={() => {
                                        handleIndividualCheckIn(
                                          paymentDetails?.regularUserInfo[key],
                                          key,
                                          "Individual",
                                          "Unseen"
                                        );
                                      }}
                                    >
                                      <img
                                        src="/Images/Dashboard/personSearch.png"
                                        alt=""
                                        id="BtnPerson"
                                      />
                                    </Button>
                                  </div>
                                  <div
                                    className="groupDetail_Card"
                                    id="groupCheckIn_CheckedInBtn"
                                  >
                                    <Button
                                      className="individualCheckInButton"
                                      id={
                                        paymentDetails?.regularUserInfo[key]
                                          ?.seenStatus === "Checked In"
                                          ? "BtnCheckedInStatus active"
                                          : "BtnCheckedInStatus"
                                      }
                                      onClick={() => {
                                        handleIndividualCheckIn(
                                          paymentDetails?.regularUserInfo[key],
                                          key,
                                          "Individual",
                                          "Checked In"
                                        );
                                      }}
                                    >
                                      <img
                                        src="/Images/Dashboard/done.png"
                                        alt=""
                                        id="BtnPerson"
                                      />
                                    </Button>
                                  </div>
                                  <div
                                    className="groupDetail_Card"
                                    id="groupCheckIn_CheckedOutBtn"
                                  >
                                    <Button
                                      className="individualCheckInButton"
                                      id={
                                        paymentDetails?.regularUserInfo[key]
                                          ?.seenStatus === "Checked Out"
                                          ? "BtnCheckedOutStatus active"
                                          : "BtnCheckedOutStatus"
                                      }
                                      onClick={() => {
                                        handleIndividualCheckIn(
                                          paymentDetails?.regularUserInfo[key],
                                          key,
                                          "Individual",
                                          "Checked Out"
                                        );
                                      }}
                                    >
                                      <img
                                        src="/Images/Dashboard/personCross.png"
                                        alt=""
                                        id="BtnPerson"
                                      />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}
                    {earlyUserInfoSize > 0 && (
                      <div className="groupDetail_Card_attending">
                        {Object.keys(paymentDetails?.earlyUserInfo)?.map(
                          (key) => {
                            return (
                              <div
                                className="checkIn_Card_attending_earlyitem"
                                id={`earlyItemContainer${key}`}
                                key={key}
                              >
                                <img
                                  src="/Images/Dashboard/arrow.png"
                                  alt=""
                                  onClick={() => {
                                    expandItem(`earlyItemContainer${key}`);
                                  }}
                                />
                                <div
                                  className="card_attending_item_info"
                                  onClick={() => {
                                    expandItem(`earlyItemContainer${key}`);
                                  }}
                                  id="checkIn_Card_attending_info"
                                >
                                  <h3>{`${paymentDetails?.earlyUserInfo[key].name} ${paymentDetails?.earlyUserInfo[key].last_name}`}</h3>
                                  <p>Early Bird Tickets</p>
                                  <div className="groupDetail_Card_attending_fullInfo">
                                    <hr />
                                    {paymentDetails?.earlyUserInfo[key]
                                      .seenStatus && (
                                      <>
                                        <h3>Status</h3>
                                        <p>
                                          {
                                            paymentDetails?.earlyUserInfo[key]
                                              .seenStatus
                                          }
                                        </p>
                                        <hr />
                                      </>
                                    )}
                                    {paymentDetails?.earlyUserInfo[key]
                                      .email && (
                                      <>
                                        <h3>Email</h3>
                                        <p>
                                          {
                                            paymentDetails?.earlyUserInfo[key]
                                              .email
                                          }
                                        </p>
                                        <hr />
                                      </>
                                    )}
                                    {paymentDetails?.earlyUserInfo[key]
                                      .phone && (
                                      <>
                                        <h3>Phone Number</h3>
                                        <p>
                                          {
                                            paymentDetails?.earlyUserInfo[key]
                                              .phone
                                          }
                                        </p>
                                        <hr />
                                      </>
                                    )}
                                    <h3>Terms and Conditions</h3>
                                    <p>Accepted</p>
                                    <hr />
                                  </div>
                                </div>
                                <div
                                  className="checkIn_cardActionContainer"
                                  id={`regularItemContainer${key}`}
                                >
                                  <div
                                    className="groupDetail_Card"
                                    id="groupCheckIn_UnseenBtn"
                                  >
                                    <Button
                                      id={
                                        paymentDetails?.earlyUserInfo[key]
                                          ?.seenStatus === "Unseen"
                                          ? "BtnUnseenStatus active"
                                          : "BtnUnseenStatus"
                                      }
                                      onClick={() => {
                                        handleIndividualCheckIn(
                                          paymentDetails?.earlyUserInfo[key],
                                          key,
                                          "Early Bird",
                                          "Unseen"
                                        );
                                      }}
                                    >
                                      <img
                                        src="/Images/Dashboard/personSearch.png"
                                        alt=""
                                        id="BtnPerson"
                                      />
                                    </Button>
                                  </div>
                                  <div
                                    className="groupDetail_Card"
                                    id="groupCheckIn_CheckedInBtn"
                                  >
                                    <Button
                                      id={
                                        paymentDetails?.earlyUserInfo[key]
                                          ?.seenStatus === "Checked In"
                                          ? "BtnCheckedInStatus active"
                                          : "BtnCheckedInStatus"
                                      }
                                      onClick={() => {
                                        handleIndividualCheckIn(
                                          paymentDetails?.earlyUserInfo[key],
                                          key,
                                          "Early Bird",
                                          "Checked In"
                                        );
                                      }}
                                    >
                                      <img
                                        src="/Images/Dashboard/done.png"
                                        alt=""
                                        id="BtnPerson"
                                      />
                                    </Button>
                                  </div>
                                  <div
                                    className="groupDetail_Card"
                                    id="groupCheckIn_CheckedOutBtn"
                                  >
                                    <Button
                                      id={
                                        paymentDetails?.earlyUserInfo[key]
                                          ?.seenStatus === "Checked Out"
                                          ? "BtnCheckedOutStatus active"
                                          : "BtnCheckedOutStatus"
                                      }
                                      onClick={() => {
                                        handleIndividualCheckIn(
                                          paymentDetails?.earlyUserInfo[key],
                                          key,
                                          "Early Bird",
                                          "Checked Out"
                                        );
                                      }}
                                    >
                                      <img
                                        src="/Images/Dashboard/personCross.png"
                                        alt=""
                                        id="BtnPerson"
                                      />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {selectedTab === "Payment CheckIn" && (
              <div className="paymentContainer">
                <div className="groupDetailContainer">
                  <Button
                    onClick={() => {
                      viewReceipt(paymentDetails?.receipt_url);
                    }}
                  >
                    View Receipt
                  </Button>

                  {paymentDetails?.regularTicketsBought > 0 && (
                    <div
                      className="ticket_selection_container"
                      id="ticket_review"
                    >
                      <img src="/Images/Event/tickets.png" alt="" />
                      <div className="ticket_name">
                        <h3>Individual Tickets</h3>
                        <h4>
                          {paymentDetails?.regularTicketsBought} x $
                          {paymentDetails?.regularTicketAmount.toFixed(2)}
                        </h4>
                      </div>
                      <h3>
                        $
                        {(
                          paymentDetails?.regularTicketsBought *
                          paymentDetails?.regularTicketAmount
                        ).toFixed(2)}
                      </h3>
                    </div>
                  )}

                  {paymentDetails?.earlyBirdTicketsBought > 0 && (
                    <div
                      className="ticket_selection_container"
                      id="ticket_review"
                    >
                      <img src="/Images/Event/tickets.png" alt="" />
                      <div className="ticket_name">
                        <h3>Early Bird Tickets</h3>
                        <h4>
                          {paymentDetails?.earlyBirdTicketsBought} x $
                          {paymentDetails?.earlyBirdTicketAmount.toFixed(2)}
                        </h4>
                      </div>
                      <h3>
                        $
                        {(
                          paymentDetails?.earlyBirdTicketsBought *
                          paymentDetails?.earlyBirdTicketAmount
                        ).toFixed(2)}
                      </h3>
                    </div>
                  )}

                  <hr />

                  <div
                    className="ticket_selection_container"
                    id="ticket_review"
                  >
                    <div className="ticket_name">
                      <h3>Tax & Service Fees</h3>
                    </div>
                    <h3>$0.00</h3>
                  </div>

                  <hr />

                  <div
                    className="ticket_selection_container"
                    id="ticket_review"
                  >
                    <div className="ticket_name">
                      <h3>Total</h3>
                    </div>
                    <h3>${paymentDetails?.totalTicketAmount.toFixed(2)}</h3>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className="buyingModel_container"
          id="checkIn_no_payment_container"
          data-aos="zoom-in"
          data-aos-duration="500"
        >
          <div className="buyingModal_header">
            <img src="/Images/Login/close.png" onClick={handleClose} alt="" />
          </div>
          <div className="buyingModel_body" id="checkIn_no_payment_container">
            <div id="overview_billingName">
              <h3>No results found!</h3>
            </div>
            <p>
              No tickets associated to this QR code. Please make sure you are
              scanning for the correct event.
            </p>
            <div className="no_results_container">
              <img src={IncorrectQRCode} alt="" id="incorrect_qr_code" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function getDateToTime(time) {
  let returnDate = "";
  if (time) {
    // console.log("Time Passed: ", time);

    const fullDate = time.split(" ").slice(0, 4).join(" ");

    const hour = parseInt(
      time.split(" ").slice(4, 5).join(" ").split(":").slice(0, 1).join(" ")
    );

    const minutes = time
      .split(" ")
      .slice(4, 5)
      .join(" ")
      .split(":")
      .slice(1, 2);

    if (hour < 12) {
      if (hour === 0) {
        returnDate = `${fullDate} ${12}:${minutes} AM`;
      } else if (hour < 10) {
        returnDate = `${fullDate} 0${hour}:${minutes} AM`;
      } else {
        returnDate = `${fullDate} ${hour}:${minutes} AM`;
      }
    } else {
      if (hour === 12) {
        returnDate = `${fullDate} ${12}:${minutes} PM`;
      } else if (hour < 22) {
        returnDate = `${fullDate} 0${Math.abs(12 - hour)}:${minutes} PM`;
      } else {
        returnDate = `${fullDate} ${Math.abs(12 - hour)}:${minutes} PM`;
      }
    }
  }

  return returnDate;
}

function getNumberOfGroups(regularUserInfo, earlyUserInfo) {
  let returnNumber = 0;
  // console.log("Regular User Info: ", regularUserInfo);
  // console.log("Early User Info: ", earlyUserInfo);

  if (regularUserInfo) {
    returnNumber += Object.keys(regularUserInfo)?.length;
  }

  if (earlyUserInfo) {
    returnNumber += Object.keys(earlyUserInfo)?.length;
  }

  return returnNumber;
}

export default CheckInModel;
