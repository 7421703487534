import React from "react";
import { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import SwiperCore, {
  A11y,
  Autoplay,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper.scss";
// import "swiper/modules/navigation/navigation.scss";
// import "swiper/modules/pagination/pagination.scss";
// import "swiper/modules/scrollbar/scrollbar.scss";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/scrollbar";
import "./Team.css";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay, Mousewheel]);

function Team() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Team - OGSA";
  }, []);

  return (
    <div className="team">
      <h2 id="team_start">Co-Founders</h2>
      <div className="team_container">
        <div className="team_wrapper">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            breakpoints={{
              1680: {
                slidesPerView: 4,
              },
              1000: {
                slidesPerView: 4,
              },

              650: {
                slidesPerView: 2,
              },
              200: {
                slidesPerView: 1,
              },
            }}
            autoplay={{
              delay: 3900,
              disableOnInteraction: true,
            }}
            grabCursor={true}
            // mousewheel = {true}
            direction={"horizontal"}
            spaceBetween={40}
            pagination={{ clickable: true }}
          >
            <SwiperSlide className="slide">
              <div className="slide_container">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Vaibhav Patel.jpeg"
                  alt="Vaibhav Patel"
                  id="image_center_bottom"
                />
                <h3>Vaibhav Patel</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="slide_container">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Devarsh Patwa.jpeg"
                  alt=""
                />
                <h3>Devarsh Patwa</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="slide_container">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Devang.jpeg"
                  alt=""
                />
                {/* <div className="person_name_container">
                                </div> */}
                <h3>Devang Shah</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="slide_container">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Mahrshi.jpg"
                  alt=""
                  id="image_center_bottom"
                />
                <h3>Mahrshi Patel</h3>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <h2>Events</h2>
      <div className="team_container">
        <div className="team_wrapper">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            breakpoints={{
              1680: {
                slidesPerView: 4,
              },
              1000: {
                slidesPerView: 4,
              },
              650: {
                slidesPerView: 2,
              },
              200: {
                slidesPerView: 1,
              },
            }}
            autoplay={{
              delay: 4000,
              disableOnInteraction: true,
            }}
            grabCursor={true}
            // mousewheel = {true}
            direction={"horizontal"}
            spaceBetween={40}
            pagination={{ clickable: true }}
          >
            <SwiperSlide className="slide">
              <div className="slide_container">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Tanvi Parikh - Events Team Exec.jpg"
                  alt=""
                />
                <h3>Tanvi Parikh</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="slide_container">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Akshat Parikh - Events Team.jpg"
                  alt=""
                />
                {/* <div className="person_name_container">
                                </div> */}
                <h3>Akshat Parikh</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="slide_container">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Diya Patel - Events team.jpg"
                  alt=""
                />
                <h3>Diya Patel</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="slide_container">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Alok Mehta - Events Team.jpg"
                  alt=""
                  id="image_top_center"
                />
                <h3>Alok Mehta</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="slide_container">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Prachi Patel - Events team.jpg"
                  alt=""
                />
                <h3>Prachi Patel</h3>
              </div>
            </SwiperSlide>

          </Swiper>
        </div>
      </div>

      <h2>Sponsorship</h2>
      <div className="team_container">
        <div className="team_wrapper">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            breakpoints={{
              1680: {
                slidesPerView: 3,
              },
              1000: {
                slidesPerView: 3,
              },
              650: {
                slidesPerView: 2,
              },
              200: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
            }}
            autoplay={{
              delay: 4500,
              disableOnInteraction: true,
            }}
            grabCursor={true}
            // mousewheel = {true}
            spaceBetween={40}
            direction={"horizontal"}
            pagination={{ clickable: true }}
          >
            <SwiperSlide className="slide">
              <div className="slide_container">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Keya Patel - Sponsorship Team.jpg"
                  alt=""
                />
                {/* <div className="person_name_container">
                                </div> */}
                <h3>Keya Patel</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="slide_container">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Nina Patel - Sponsorship Team_.jpg"
                  alt=""
                />
                {/* <div className="person_name_container">
                                    
                                </div> */}
                <h3>Nina Patel</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="slide_container">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Preet Parikh - Sponsorship team_.jpg"
                  alt=""
                />
                {/* <div className="person_name_container">
                                </div> */}
                <h3>Preet Parikh</h3>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      {/* <h2>Marketing</h2>
            <div className="team_container">
                <div className="team_wrapper">
                    <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                            breakpoints={{
                                1680: {
                                    slidesPerView: 4,
                                },
                                1000: {
                                    slidesPerView: 3,
                                   
                                },
                                650:{
                                    
                                    slidesPerView:2,
                                },
                                200:{
                                    slidesPerView:1,
                                    spaceBetween: 30,
                                }
                            }}
                            autoplay = {true}
                            grabCursor = {true}
                            // mousewheel = {true}
                            direction = {"horizontal"}
                            spaceBetween = {10}
                            centeredSlides = {true}
                            pagination={{ clickable: true }}
                            >
                            <SwiperSlide className = "slide">
                                <div className="slide_container"> 
                                    <LazyLoadImage effect = "blur" height = "350" width = "100%" src="/Images/Team/Krishna.jpg" alt="" />
                                    <h3>Krishna Trivedi</h3>
                                    
                                </div>
                            </SwiperSlide>
                        </Swiper>
                </div>
            </div> */}
      <h2>Marketing</h2>
      <div className="team_container">
        <div className="team_wrapper">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            breakpoints={{
              1680: {
                slidesPerView: 3,
              },
              1000: {
                slidesPerView: 3,
              },
              650: {
                slidesPerView: 2,
              },
              200: {
                slidesPerView: 1,
              },
            }}
            autoplay={{
              delay: 4000,
              disableOnInteraction: true,
            }}
            grabCursor={true}
            // mousewheel = {true}
            direction={"horizontal"}
            spaceBetween={40}
            pagination={{ clickable: true }}
          >
            <SwiperSlide className="slide">
              <div className="slide_container">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Krish Chandarana - Marketing Team Exec.png"
                  alt=""
                />
                {/* <div className="person_name_container">
                                    
                                </div> */}
                <h3>Krish Chandarana</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="slide_container_IR">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Nandini Shah - Marketing Team.jpg"
                  alt=""
                />
                <h3>Nandini Shah</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="slide_container_IR">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Rajvi Nayak - Marketing.jpg"
                  alt=""
                />
                <h3>Rajvi Nayak</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="slide_container">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Kesha_Upadhyay_-_Graphic_Design_Exec.jpg"
                  alt=""
                />
                {/* <div className="person_name_container">
                                   
                                </div> */}
                <h3>Kesha Upadhyay</h3>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <h2>Institution Representatives</h2>
      <div className="team_container">
        <div className="team_wrapper">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            breakpoints={{
              1680: {
                slidesPerView: 4,
              },
              1000: {
                slidesPerView: 4,
              },
              650: {
                slidesPerView: 2,
              },
              200: {
                slidesPerView: 1,
              },
            }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
            }}
            grabCursor={true}
            // mousewheel = {true}
            direction={"horizontal"}
            spaceBetween={30}
            pagination={{ clickable: true }}
          >
            <SwiperSlide className="slide">
              <div className="slide_container_IR">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Homa Varmora - Institutional Rep Wildfird Laurier.jpg"
                  alt=""
                />
                <h3>Homa Varmora</h3>
                <h4>Wilfrid Laurier University</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="slide_container_IR">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Jeel Patel - McMaster Institute Rep.jpg"
                  alt=""
                />
                <h3>Jeel Patel</h3>
                <h4>McMaster University</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="slide_container_IR">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Yasvi Patel - institution rep university of waterloo.jpg"
                  alt=""
                />
                <h3>Yasvi Patel</h3>
                <h4>University Of Waterloo</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="slide_container_IR">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Shreya Pandya - Institution Rep Uoft.jpg"
                  alt=""
                />
                <h3>Shreya Pandya</h3>
                <h4>University of Toronto (Scarborough)</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="slide_container_IR">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Shivani Patel - institution rep for the University of Toronto Mississauga_.jpg"
                  alt=""
                />
                <h3>Shivani Patel</h3>
                <h4>University of Toronto (Mississauga)</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="slide_container_IR">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Rudraksh Dave - Institution Rep of Fanshawe College.jpg"
                  alt=""
                />
                <h3>Rudraksh Dave</h3>
                <h4>Fanshawe College</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="slide_container_IR">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Tvisha Patel - Institution Rep Schulich School of Business Yorku.jpg"
                  alt=""
                />
                <h3>Tvisha Patel</h3>
                <h4>Schulich School of Business York University</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="slide_container_IR">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Sahaj Patel - Humber Institutional Representative_.jpg"
                  alt=""
                />
                <h3>Sahaj Patel</h3>
                <h4>Humber College</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="slide_container_IR">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Stuti Patel - institution rep for Brock University.jpg"
                  alt=""
                />
                <h3>Stuti Patel</h3>
                <h4>Brock University</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="slide_container_IR">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Meet Amin - Instituion Rep Conestoga, Doon.jpg"
                  alt=""
                />
                <h3>Meet Amin</h3>
                <h4>Conestoga College</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="slide_container_IR">
                <LazyLoadImage
                  effect="blur"
                  height="350"
                  width="100%"
                  src="/Images/Team/Tej Patel - Institutional rep for Seneca College_.jpg"
                  alt=""
                />
                <h3>Tej Patel</h3>
                <h4>Seneca College</h4>
              </div>
            </SwiperSlide>


          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Team);