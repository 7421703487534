import React, { useEffect, useState } from 'react';
import "./SignUp.css";
import { Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { auth, provider } from '../../firebase';
import { createUserWithEmailAndPassword, signInWithPopup } from '@firebase/auth';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../Reducer';
import { sendEmailVerification, updateProfile } from '@firebase/auth';
import Modal from '../../Modal';
import BuyingModelInput from "../../Component/BuyingModelInput";
import inputErrorMessage from "../../Component/InputErrorMessage.json";

function SignUp() {
    // Need to make sign up page responsive.
    const history = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [{ }, dispatch] = useStateValue();
    const [show, setShow] = useState(false);
    // const [modalType, setModalType] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [modalMessage, setModalMessage] = useState("");


    const hide_viewPassword = () => {
        const passwordType = document.querySelector("#password");
        const eyeImage = document.querySelector("#eye_img");
        try {
            const type = passwordType.getAttribute('type') === 'password' ? 'text' : 'password';
            if (type === 'text') {
                eyeImage.setAttribute('src', "Images/Login/eye.png");
            }
            else {
                eyeImage.setAttribute('src', "Images/Login/close_eye.png");
            }
            passwordType.setAttribute('type', type);
        } catch (e) {
            console.log(e);
        }
    }

    const handleLogin = (e) => {
        e.preventDefault();
        let form = document.getElementById("emailPass_form")
        let firstName = document.getElementById("firstName")
        let lastName = document.getElementById("lastName")
        let email = document.getElementById("email")
        let password = document.getElementById("password")


        if (form.checkValidity() === false) {
            if (firstName.checkValidity() === false) {
                firstName.setAttribute("focused", "true");
            }
            if (password.checkValidity() === false) {
                password.setAttribute("focused", "true");
            }
            if (lastName.checkValidity() === false) {
                lastName.setAttribute("focused", "true");
            }
            if (email.checkValidity() === false) {
                email.setAttribute("focused", "true");
            }
            setModalTitle("Error");
            setModalMessage("Please fill out all the required fields before signing up.");
            setShow(true);
        } else {
            createUserWithEmailAndPassword(auth, email.value, password.value)
                .then((result) => {
                    updateProfile(result.user, { displayName: firstName.value + " " + lastName.value }).then(() => {
                        dispatch({
                            type: actionTypes.SET_USER,
                            user: result.user,
                        })
                    }).catch((error) => {
                        console.log(error);
                    });
                    sendEmailVerification(result.user);
                    // alert('Please verify your email address and log back into the system to get full access to the website.');
                    history('/');
                })
                .catch((error) => {
                    console.log(error);
                    if (error === "FirebaseError: Firebase: Error (auth/invalid-email).") {
                        console.log("Invalid email address.");
                    }
                    setModalTitle("Error");
                    setModalMessage("This email is already registered. Please try again with a different email address.");
                    setShow(true);
                });
        }
    }

    const handleSignUpWithGoogle = (e) => {
        e.preventDefault();
        signInWithPopup(auth, provider)
            .then((result) => {
                dispatch({
                    type: actionTypes.SET_USER,
                    user: result.user,
                })
                history("/");
            })
            .catch(
                error => alert(error.message)
            )
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "Sign Up - OGSA";
    }, [])

    return (
        <div className="sign_up">
            {show && <Modal setShowModal={setShow} modalTitle={modalTitle} modalMessage={modalMessage} className="modalInfo" />}
            <div className="sign_up_left">
                <div className="sign_up_left_wrapper" data-aos="zoom-out">
                    <h1>Create an Account</h1>
                    <form id="emailPass_form">
                        <div className="sign_up_left_wrapper_input">
                            <div className="sign_up_left_name_container">
                                <div className="sign_up_left_firstName">
                                    <p>First Name</p>
                                    {/* <input type="text" placeholder="" onChange={(e) => setFirstName(e.target.value)} /> */}
                                    <BuyingModelInput
                                        id="firstName"
                                        inputType="input"
                                        type="text"
                                        onChangeValue={setFirstName}
                                        pattern="(^[A-Za-z ]*)$"
                                        placeholder="Enter your first name"
                                        errorMessage={inputErrorMessage["name"]}
                                        required
                                    />
                                </div>
                                <div className="sign_up_left_lastName">
                                    <p>Last Name</p>
                                    {/* <input type="text" placeholder="" onChange={(e) => setLastName(e.target.value)} /> */}
                                    <BuyingModelInput
                                        id="lastName"
                                        inputType="input"
                                        type="text"
                                        placeholder="Enter your last name"
                                        onChangeValue={setLastName}
                                        pattern="(^[A-Za-z ]*)$"
                                        errorMessage={inputErrorMessage["name"]}
                                        required
                                    />
                                </div>

                            </div>
                            <p>Email</p>
                            {/* <input type="text" placeholder="" onChange={(e) => setEmail(e.target.value)} required /> */}
                            <BuyingModelInput
                                id="email"
                                inputType="input"
                                type="email"
                                placeholder="Enter your email"
                                onChangeValue={setEmail}
                                pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
                                errorMessage={inputErrorMessage["email"]}
                                required
                            />
                            <p>Password</p>
                            <span className="password_input">
                                {/* <input type="password" id="password" onChange={(e) => setPassword(e.target.value)} required="required" /> */}
                                <BuyingModelInput
                                    id="password"
                                    inputType="input"
                                    type="password"
                                    placeholder="Enter your password"
                                    onChangeValue={setPassword}
                                    pattern="^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$"
                                    errorMessage={inputErrorMessage["password"]}
                                    required
                                />
                                <img src="Images/Login/close_eye.png" onClick={() => hide_viewPassword()} id="eye_img" />
                            </span>
                            <div className="sign_up_options_container">
                                <a onClick={handleLogin} >
                                    <Button>Sign Up</Button>
                                </a>
                                <span><hr /><p>OR</p> <hr /></span>
                                <a onClick={handleSignUpWithGoogle}>
                                    <Button id="sign_in_google">
                                        <span>
                                            <img src="/Images/Login/google.png" width="40" height="40" />
                                        </span>
                                        Sign Up with Google
                                    </Button>
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="sign_up_right" data-aos="fade-left" data-aos-duration="700">
                <img src="/Images/Login/close.png" onClick={() => history('/')} />
                <div className="sign_up_right_wrapper">
                    <img src="/Images/logo.png" width="150" height="125" onClick={() => history('/')} />
                    <h1>One Of Us?</h1>
                    <p>If you already have an</p>
                    <p>account, just sign in!</p>
                    <Button onClick={() => history('/login')}>Sign In</Button>
                </div>
            </div>
        </div>
    )
}

export default SignUp;
