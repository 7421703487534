import React from 'react';
import "./Footer.css";

function Footer() {
    // <a href="https://www.freepik.com/vectors/buyer">Buyer vector created by vectorjuice - www.freepik.com</a>
    return (
        <div className="footer">
            <img src="/Images/logo.png" width="120px" />
            <div className="social_wrapper">
                <a href="https://m.facebook.com/profile.php?id=100071758803756" target="_blank">
                    <img src="/Images/Footer/facebook.png" width="35px" />
                </a>
                <a href="https://www.instagram.com/ontario.gsa/" target="_blank">
                    <img src="/Images/Footer/instagram.png" width="35px" />
                </a>
                <a href="mailto: ogsa2021@gmail.com" target="_blank">
                    <img src="/Images/Footer/email.png" width="35px" />
                </a>
            </div>
            <div className="copyright">
                <span>
                    Developed and managed by <a href="https://www.vaibhav.ca" target="_blank">Vaibhav Patel</a>
                </span>
            </div>
        </div>
    )
}

export default Footer;
