import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { auth, provider } from "../../firebase";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail,
} from "@firebase/auth";
import { actionTypes } from "../../Reducer";
import { useStateValue } from "../../StateProvider";
import Modal from "../../Modal";
import BuyingModelInput from "../../Component/BuyingModelInput";
import inputErrorMessage from "../../Component/InputErrorMessage.json";

function Login() {
  const history = useNavigate();
  const [{}, dispatch] = useStateValue();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [forgotPassFlag, setForgotPassFlag] = useState(false);
  const [resetEmail, setResetEmail] = useState("");

  const hide_viewPassword = () => {
    const passwordType = document.querySelector("#password");
    const eyeImage = document.querySelector("#eye_img");
    try {
      const type =
        passwordType.getAttribute("type") === "password" ? "text" : "password";
      if (type === "text") {
        eyeImage.setAttribute("src", "Images/Login/eye.png");
      } else {
        eyeImage.setAttribute("src", "Images/Login/close_eye.png");
      }
      passwordType.setAttribute("type", type);
    } catch (e) {
      console.log(e);
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      if (document.getElementById("email").checkValidity() === false) {
        document.getElementById("email").setAttribute("focused", "true");
      }
      if (document.getElementById("password").checkValidity() === false) {
        document.getElementById("password").setAttribute("focused", "true");
      }
      setModalTitle("Error");
      setModalMessage(
        "Make sure to enter your email and password before attempting to log in."
      );
      setShow(true);
    } else {
      signInWithEmailAndPassword(auth, email, password)
        .then((result) => {
          dispatch({
            type: actionTypes.SET_USER,
            user: result.user,
          });
          history("/");
        })
        .catch((error) => {
          let email = document.getElementById("email");
          let password = document.getElementById("password");

          if (email.checkValidity() === false) {
            email.setAttribute("focused", "true");
          }

          if (password.checkValidity() === false) {
            password.setAttribute("focused", "true");
          }

          setModalTitle("Incorrect Login");
          setModalMessage(
            "Seems like you have entered incorrect email address or password. Please try again."
          );
          setShow(true);
        });
    }
  };

  const handleSignInWithGoogle = (e) => {
    e.preventDefault();
    signInWithPopup(auth, provider)
      .then((result) => {
        dispatch({
          type: actionTypes.SET_USER,
          user: result.user,
        });
        history("/");
      })
      .catch((error) => {
        setModalTitle("Incorrect Login");
        // console.log(error);
        setModalMessage(
          "Seems like there was some problem logging in using the google services. Please try again."
        );
        setShow(true);
      });
  };

  const passwordReset = (e) => {
    e.preventDefault();
    if (resetEmail !== "") {
      document.getElementById("reset_email").setAttribute("focused", "false");
      sendPasswordResetEmail(auth, resetEmail)
        .then((result) => {
          setModalTitle("Password Reset");
          setModalMessage(
            "An email has been sent to your email address with instructions on how to reset your password."
          );
          setShow(true);
        })
        .catch((error) => {
          setModalTitle("Error");
          setModalMessage(
            "Seems like there was some problem resetting your password. Please try again."
          );
          setShow(true);
        });
    } else {
      console.log("Email is empty");
      document.getElementById("reset_email").setAttribute("focused", "true");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Sign In - OGSA";
  }, []);

  return (
    <div className="login">
      {show && (
        <Modal
          setShowModal={setShow}
          modalTitle={modalTitle}
          modalMessage={modalMessage}
          className="modalInfoLogin"
        />
      )}
      <div className="login_left" data-aos="fade-right">
        <div className="login_left_wrapper">
          <img
            src="/Images/logo.png"
            width="150"
            height="125"
            onClick={() => history("/")}
          />
          <h1>Create an Account</h1>
          <p>Sign Up to Discover Amazing</p>
          <p>Opportunities and Events!</p>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history("/sign_up")}
          >
            Sign Up
          </Button>
        </div>
      </div>
      {forgotPassFlag === true ? (
        <div className="login_right" data-aos="zoom-out">
          <img src="/Images/Login/close.png" onClick={() => history("/")} />
          <div className="login_right_container">
            <div className="forgotEmail_right_wrapper">
              <h1>Forgot Password?</h1>
              <p>
                Enter the email address you used when you joined and we’ll send
                you instructions to reset your password.
              </p>
              <p>
                For security reasons, we do NOT store your password. So rest
                assured that we will never send your password via email.
              </p>
              <p>Email Address</p>
              <BuyingModelInput
                id="reset_email"
                inputType="input"
                type="text"
                onChangeValue={setResetEmail}
                pattern=""
                placeholder="Enter your email"
                errorMessage={inputErrorMessage["email"]}
                required
              />
              <div className="login_options_container">
                <a onClick={passwordReset}>
                  <Button variant="contained" color="primary" type="submit">
                    Send Reset Link
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="login_right" data-aos="zoom-out">
          <img src="/Images/Login/close.png" onClick={() => history("/")} />
          <div className="login_right_container">
            <div className="login_right_wrapper">
              <h1>Welcome Back</h1>
              <p>Please enter your details.</p>
              <form>
                <div className="login_right_wrapper_input">
                  <p>Email Address</p>
                  {/* <input
                  type="text"
                  placeholder=""
                  onChange={(e) => setEmail(e.target.value)}
                /> */}
                  <BuyingModelInput
                    id="email"
                    inputType="input"
                    type="text"
                    onChangeValue={setEmail}
                    pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
                    placeholder="Enter your email address"
                    errorMessage={inputErrorMessage["email"]}
                    required
                  />
                  <p>Password</p>
                  <span className="password_input">
                    {/* <input
                    type="password"
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                  /> */}
                    <BuyingModelInput
                      id="password"
                      inputType="input"
                      type="password"
                      placeholder="Enter your password"
                      onChangeValue={setPassword}
                      pattern="^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$"
                      errorMessage={inputErrorMessage["password"]}
                      required
                    />
                    <img
                      src="Images/Login/close_eye.png"
                      onClick={() => hide_viewPassword()}
                      id="eye_img"
                    />
                  </span>
                  <a
                    href="#"
                    onClick={() => setForgotPassFlag(true)}
                    className="forgot_password"
                    id="text-right"
                  >
                    Forgot Password?
                  </a>
                  <div className="login_options_container">
                    <a onClick={handleLogin}>
                      <Button variant="contained" color="primary" type="submit">
                        Sign in
                      </Button>
                    </a>
                    <span>
                      <hr /> <p>or</p> <hr />
                    </span>
                    <a onClick={handleSignInWithGoogle}>
                      <Button
                        id="sign_in_google"
                        variant="contained"
                        color="primary"
                      >
                        <span>
                          <img
                            src="/Images/Login/google.png"
                            width="40"
                            height="40"
                          />
                        </span>
                        Sign in with Google
                      </Button>
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
