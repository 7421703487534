import React, { useEffect, useState } from 'react';
import { Button } from "@material-ui/core";
import { useStateValue } from '../../StateProvider';
import ProgressBar from "@ramonak/react-progress-bar";
import { useNavigate } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import NoCurrentEvent from "../../Component/noCurrentEvent.svg";

import {
  getFirestore,
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  doc,
  deleteDoc
} from "firebase/firestore";
function PastDashboard({ ITEM_HEIGHT, options, search }) {
  const db = getFirestore();
  const [{ user }, dispatch] = useStateValue();
  const history = useNavigate();
  const [prevEvents, setPrevEvents] = useState([]);
  const [allPrevEvents, setAllPrevEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleOpenEvent = (id) => {
    // console.log("Event Id: ", id);
    history(`/dashboard/overview/${id}`);
  };

  const pEvent = query(
    collection(db, "events"),
    where("eventStatus", "==", "Previous")
  );

  useEffect(() => {
    const unsubscribe = onSnapshot(pEvent, (snapshot) => {
      setAllPrevEvents(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (search !== "" && allPrevEvents) {
      const filteredEvents = allPrevEvents.filter(event => {
        return event.data.eventName.toLowerCase().includes(search.toLowerCase());
      }
      );
      setPrevEvents(filteredEvents);
    } else {
      setPrevEvents(allPrevEvents);
    }

  }, [search, allPrevEvents]);

  const handleClick = (event, selectedEventId) => {
    setSelectedEventId(selectedEventId);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option, eventId) => {
    setSelectedEventId("")
    if (option === "Open") {
      history(`/dashboard/overview/${eventId}`);
    } else if (option === "Edit") {
      history(`/edit/event/${eventId}`);
    } else if (option === "Check-In") {
      history(`/check-in/event/${eventId}`);
    } else if (option === "Delete") {
      const tempRef = doc(db, "events", eventId)
      deleteDoc(tempRef);
    } else if (option === "View as guest") {
      history(`/events/${eventId}`);
    }
    setAnchorEl(null);
  };

  return (
    <>
      {prevEvents.length === 0 ? (
        <div className="noIssue_container" data-aos="fade-in" data-aos-duration="1000">
          <img src={NoCurrentEvent} alt="No Current Event" style={{ width: '350px' }} />
          <h3 id="mid_text">{search === "" ? "No Past Events!" : "No events matched your search."}</h3>
        </div>
      ) : (
        <>
          <div className="dashboard_container">
            {prevEvents.map((event, index) => {
              var duration = 200 + 150 * index;
              return (
                <div className="dashboardCard_container" key={event?.id} data-aos="fade-left" data-aos-duration={duration.toString()} data-aos-easing="ease">
                  <div className="dashboardFirst_container">
                    {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                    <img
                      src={event?.data?.eventImgUrl}
                      alt="Event Image"
                    />
                    <div
                      className="dashboardCard_Overlay"
                      onClick={(e) => {
                        handleOpenEvent(event?.id);
                      }}
                    ></div>
                    <IconButton
                      aria-label="more"
                      id={`menu_icon`}
                      aria-controls={open ? 'long-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={(e) => { handleClick(e, event?.id) }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id={`long-menu`}
                      MenuListProps={{
                        'aria-labelledby': 'long-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: '15ch',
                        },
                      }}
                    >
                      <div className="menu_wrapper" key={selectedEventId}>
                        {(options).map((option, index) => (
                          <div className="menu_items" key={`${selectedEventId}-${index}`}>
                            {option !== "Delete" ? (
                              <MenuItem key={option} onClick={() => { handleClose(option, selectedEventId) }}>
                                {option}
                              </MenuItem>
                            ) : (
                              <>
                                <Divider sx={{ my: 0.9 }} />
                                <MenuItem key={option} onClick={() => { handleClose(option, selectedEventId) }}>
                                  {option}
                                </MenuItem>
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                    </Menu>
                  </div>
                  <div
                    className="dashboardSecond_container"
                    onClick={(e) => {
                      handleOpenEvent(event?.id);
                    }}
                  >
                    <h3>{event?.data?.eventName}</h3>
                    <ProgressBar
                      isLabelVisible={false}
                      completed={
                        event?.data?.totalTicket -
                        (event?.data?.RegularTicketsAvailable +
                          event?.data?.EarlyBirdTicketsAvailable)
                      }
                      maxCompleted={event?.data?.totalTicket}
                      bgColor="#09A96F"
                      baseBgColor="#E3414B"
                      borderRadius="5px"
                      height="8px"
                      margin="10px 0px"
                    // animateOnRender={true}
                    />
                    <div className="dashboardCard_footer">
                      <div className="dashboardCard_footer_left">
                        <h3>
                          {event?.data?.totalTicket -
                            (event?.data?.RegularTicketsAvailable +
                              event?.data?.EarlyBirdTicketsAvailable)}
                        </h3>
                        <p>Sold</p>
                      </div>
                      <div className="dashboardCard_footer_right">
                        <h3>
                          {event?.data?.RegularTicketsAvailable +
                            event?.data?.EarlyBirdTicketsAvailable}
                        </h3>
                        <p>Available</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  )
}

export default React.memo(PastDashboard)