import React from "react";
import "./Modal.css";
import { useState } from "react";
import { Button } from "@material-ui/core";
import { auth } from "./firebase";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "./StateProvider";

function Modal({ setShowModal, modalTitle, modalMessage }) {
  const history = useNavigate();
  const [{ user }, dispatch] = useStateValue();
  const handleLogout = () => {
    if (user) {
      auth.signOut();
      history("/login");
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    if (modalTitle !== "Please Login!") {
      setShowModal(false);
    } else {
      history("/login");
    }
  };

  return (
    <div className="modal_background">
      <div
        className="modal_container"
        data-aos="zoom-in"
        data-aos-duration="500"
      >
        {/* <div className="modal_header">
                        <img src="/Images/Login/close.png" onClick={() => closeModal(false)} />
                </div> */}
        <div className="modal_title">
          <h1>{modalTitle}</h1>
        </div>
        <div className="modal_body">
          <p>{modalMessage}</p>
        </div>
        {modalTitle === "Verify your email" || modalTitle === "Next Step" ? (
          <div className="modal_footer">
            <Button onClick={handleLogout}>Logout</Button>
          </div>
        ) : (
          <div className="modal_footer">
            <Button onClick={handleClose}>Continue</Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Modal;
