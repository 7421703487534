import React, { useEffect, useState } from "react";
import "./Order.css";
import {
  getFirestore,
  collection,
  onSnapshot,
  getDocs,
  query,
  orderBy,
  limit,
} from "firebase/firestore";
import { useStateValue } from "../../StateProvider";
import { Button } from "@material-ui/core";
import ScanEntryModel from "../Dashboard/ScanEntryModel";
import NoOrder from "../../Component/order.svg";
// import order from "../../Component/"

const db = getFirestore();

/**
  *Box for the detail on the order

  *The information to be displayed is:
  *  - Order/Payment ID
  *  - Order Date
  *  - Order Total
  *  - Order Items
  *  - View receipt_url
  *  - Scan for entry
  * 
  * Can we use map.filter to search...
*/
function Order() {
  const [{ user }, dispatch] = useStateValue();
  const [order, setOrder] = useState([]);
  const [allOrder, setAllOrder] = useState([]);
  const [search, setSearch] = useState("");
  const [tempOrder, setTempOrder] = useState([]);
  const [orderRef, setOrderRef] = useState();
  const [openModel, setOpenModel] = useState(false);
  const [scanId, setScanId] = useState("");

  var maxAttendees = 5;

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Orders - OGSA";
  }, [])

  useEffect(() => {
    if (user) {
      const orderedEvent = query(
        collection(db, "users", user?.uid, "Event Tickets"),
        orderBy("lastBought", "desc"), limit(5)
      );
      // console.log("Order Event: ", orderedEvent);
      setOrderRef(orderedEvent);
    }
  }, [user]);

  useEffect(() => {
    if (orderRef) {
      const unsubscribe = onSnapshot(orderRef, (snapshot) => {
        // Going through each event
        snapshot.forEach((document) => {
          if (document.data()?.id) {
            var tempCollection = query(
              collection(
                db,
                "users",
                user?.uid,
                "Event Tickets",
                document.data()?.id,
                "Purchased Tickets"
              ),
              orderBy("boughtTime", "desc")
            );
            // Going through all the tickets for each event
            let tempRef = getDocs(tempCollection).then((snapshot) => {
              snapshot.forEach((doc) => {
                // tempOrder.push(doc.data());
                setTempOrder((prev) => [...prev, doc.data()]);
              });
            });
          }
        });
      });
    }
  }, [orderRef]);

  useEffect(() => {
    setAllOrder(tempOrder);
  }, [tempOrder]);

  useEffect(() => {
    // console.log(allOrder);
    if (search !== "" && allOrder) {
      const filteredEvents = allOrder.filter(event => {
        return event?.eventName.toLowerCase().includes(search.toLowerCase());
      }
      );
      setOrder(filteredEvents);
    } else {
      setOrder(allOrder);
    }

  }, [search, allOrder]);

  const viewReceipt = (orderRef) => {
    window.open(orderRef);
  };

  const openScanEntryModel = (scanId) => {
    setScanId(scanId);
    setOpenModel(true);
  };

  return (
    <div className="orders">
      {openModel && <ScanEntryModel modelOpen={setOpenModel} scanId={scanId} />}
      <div className="order_header">
        <h2>Your Orders</h2>
        <div className="order_searchWrapper">
          <span>
            <img
              className="search_logo"
              src="Images/Dashboard/search.png"
              alt="search"
            />
            <input
              type="search"
              placeholder="Search for an order..."
              className="order_searchContainer"
              onChange={(e) => setSearch(e?.target?.value)}
            ></input>
          </span>
          {/* <Button>Search</Button> */}
        </div>
      </div>

      {order?.length > 0 ? (
        <>
          {order.map((curr, length) => (
            <div className="order_container" key={length + 1} data-aos-duration="600" data-aos={`${length % 2 === 0 ? "fade-left" : "fade-right"}`}>
              <div className="orderContainer_head" key={length + 2}>
                <div>
                  <div className="boughtTimeInfo_container">
                    <h5>Ordered Placed</h5>
                    <h5>{getDateToTime(curr?.boughtTime?.toDate()?.toString())}</h5>
                  </div>
                  <div className="boughtTimeInfo_container">
                    <h5>Total</h5>
                    <h5>{`$${curr?.totalTicketAmount.toFixed(2)}`}</h5>
                  </div>
                </div>
                <div>
                  <div className="boughtTimeInfo_container">
                    <Button
                      id="view_Receipt"
                      onClick={(e) => {
                        viewReceipt(curr?.receipt_url);
                      }}
                    >
                      Receipt
                    </Button>
                  </div>
                  <div className="boughtTimeInfo_container">
                    <Button id="scanEntryButton" onClick={() => openScanEntryModel(curr?.paymentID)}>
                      QR Code
                    </Button>
                  </div>
                </div>
              </div>
              <div className="order_wrapper" key={length + 3}>
                <div className="order_container_left">
                  <div className="order_wrapper_left">
                    <img src={curr?.eventImgUrl} alt=""></img>
                    <div className="order_card_overlay"></div>
                  </div>
                </div>
                <div className="order_container_right">
                  <div className="order_wrapper_right">
                    <h2>{curr?.eventName}</h2>
                    <div className="ticket_bought_wrapper">
                      {curr?.regularTicketsBought > 0 && (
                        <div id="ticket_row">
                          <h4>Individual Tickets x {curr?.regularTicketsBought}</h4>
                        </div>
                      )}
                      {curr?.earlyBirdTicketsBought > 0 && (
                        <div id="ticket_row">
                          <h4>Early Bird Tickets x {curr?.earlyBirdTicketsBought}</h4>
                        </div>
                      )}
                    </div>
                    <h4>
                      Attendees:
                      {displayAttendees(curr?.regularUserInfo, curr?.earlyUserInfo, maxAttendees)}
                      {/* {Object.keys(curr?.regularUserInfo)?.length > 0 &&
                        Array.from(
                          {
                            length:
                              Object.keys(curr?.regularUserInfo)?.length >
                              maxAttendees
                                ? maxAttendees
                                : Object.keys(curr?.regularUserInfo)?.length,
                          },
                          (_, i) => (
                            <p key={i}>
                              - {curr?.regularUserInfo[i]?.name}{" "}
                              {curr?.regularUserInfo[i]?.last_name}
                            </p>
                          )
                        )}
                      {Object.keys(curr?.regularUserInfo)?.length >
                        maxAttendees && (
                        <p>
                          {Object.keys(curr?.regularUserInfo)?.length -
                            maxAttendees}{" "}
                          more...
                        </p>
                      )} */}
                      {/* {Object.keys(curr?.earlyUserInfo)?.length > 0 &&
                        Array.from(
                          {
                            length:
                              Object.keys(curr?.earlyUserInfo)?.length >
                              maxAttendees
                                ? maxAttendees
                                : Object.keys(curr?.earlyUserInfo)?.length,
                          },
                          (_, i) => (
                            <p key={i}>
                              - {curr?.earlyUserInfo[i]?.name}{" "}
                              {curr?.earlyUserInfo[i]?.last_name}
                            </p>
                          )
                        )}
                      {Object.keys(curr?.earlyUserInfo)?.length >
                        maxAttendees && (
                        <p>
                          {Object.keys(curr?.earlyUserInfo)?.length -
                            maxAttendees}{" "}
                          more...
                        </p>
                      )} */}
                    </h4>
                  </div>

                  {/* <Button
                    id="view_Receipt"
                    onClick={(e) => {
                      viewReceipt(curr?.receipt_url);
                    }}
                  >
                    View Receipt
                  </Button> */}
                  {/* <Button onClick={() => openScanEntryModel(curr?.paymentID)}>
                    Scan for Entry
                  </Button> */}
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          {search === "" ? (
            <div className="no_orders" data-aos="fade-in" data-aos-duration="800">
              {/* <img src="/Images/Order/noOrder.jpg" alt="No Order" /> */}
              <img src={NoOrder} alt="No Order" id="no_order_svg" />
              <h3 id="mid_text">No Orders Yet</h3>
            </div>
          ) : (
            <div className="no_orders" data-aos="fade-in" data-aos-duration="800">
              {/* <img src="/Images/Order/noOrder.jpg" alt="No Order" /> */}
              <img src={NoOrder} alt="No Order" id="no_order_svg" />
              <h3 id="mid_text">No orders matched your search.</h3>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default React.memo(Order);

function getDateToTime(time) {
  let returnDate = "";
  if (time) {
    const fullDate = time.split(" ").slice(0, 4).join(" ");
    const hour = parseInt(
      time.split(" ").slice(4, 5).join(" ").split(":").slice(0, 1).join(" ")
    );
    const minutes = time
      .split(" ")
      .slice(4, 5)
      .join(" ")
      .split(":")
      .slice(1, 2);

    if (hour < 12) {
      if (hour === 0) {
        returnDate = `${fullDate} ${12}:${minutes} AM`;
      } else if (hour < 10) {
        returnDate = `${fullDate} 0${hour}:${minutes} AM`;
      } else {
        returnDate = `${fullDate} ${hour}:${minutes} AM`;
      }
    } else {
      if (hour === 12) {
        returnDate = `${fullDate} ${12}:${minutes} PM`;
      } else if (hour < 22) {
        returnDate = `${fullDate} 0${Math.abs(12 - hour)}:${minutes} PM`;
      } else {
        returnDate = `${fullDate} ${Math.abs(12 - hour)}:${minutes} PM`;
      }
    }
  }

  return returnDate;
}


function displayAttendees(regularUserInfo, earlyUserInfo, maxAttendees) {
  // const userInfo = {...regularUserInfo,...earlyUserInfo}
  // const userInfo = [regularUserInfo, earlyUserInfo].reduce(function (r, o) {
  //     Object.keys(o).forEach(function (k) { r[k] = o[k]; });
  //     return r;
  // }, {});

  const userInfo = [];

  Object.keys(regularUserInfo).forEach(function (val) {
    userInfo.push(regularUserInfo[val]);
  })

  Object.keys(earlyUserInfo).forEach(function (val) {
    userInfo.push(earlyUserInfo[val]);
  })

  return (
    <>
      {Object.keys(userInfo)?.length > 0 &&
        Array.from(
          {
            length:
              Object.keys(userInfo)?.length >
                maxAttendees
                ? maxAttendees
                : Object.keys(userInfo)?.length,
          },
          (_, i) => (
            <p key={i}>
              - {userInfo[i]?.name}{" "}
              {userInfo[i]?.last_name}
            </p>
          )
        )}
      {Object.keys(userInfo)?.length >
        maxAttendees && (
          <p>
            {Object.keys(userInfo)?.length -
              maxAttendees}{" "}
            more...
          </p>
        )}
    </>
  )
}
